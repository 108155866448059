import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';

import configureStore from '../src/configureStore';
import './css/main.css';
import './index.css';
import './App.css';
import 'react-image-lightbox/style.css';

import registerServiceWorker from './registerServiceWorker';

const store = configureStore();
window.location.replace('https://publishing.bookcicle.com');

ReactDOM.render(
  <Provider store={ store }>
    <App />
  </Provider>
  , document.getElementById('root'));
registerServiceWorker();
