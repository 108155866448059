import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid/Grid';
class SoundWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
  }

  state = {
    show: true
  };

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.src !== prevProps.src) {
      this.handleSourceUpdate(this.props.src);
    }
  }

  handleBrokenLink = () => {
    this.setState({ show: false });
  };

  handleSourceUpdate = (src) => {
    this.setState({ currentSrc: src }, () => {
      this.audioRef.current.pause();
      this.audioRef.current.load();
    });
  };

  render() {
    const { src, controls, loop, isVisible, autoPlay } = this.props;
    const { show } = this.state;

    if (isVisible && autoPlay) {
      this.audioRef.current.play();
    }
    else if (!isVisible && autoPlay && this.audioRef && this.audioRef.current) {
      this.audioRef.current.pause();
    }

    const isValid = src.startsWith('https://media.bookcicle.com') || src.startsWith('https://images.bookcicle.com');
    return <div>
      {isValid && <div style={ { display: show ? 'inline' : 'none' } }>
        <Grid style={ { margin: controls ? '20px 0' : 0 } }>
          <Grid item xs={ 12 } >
            <Grid container justify="center" alignItems="center">
              <audio controls={ controls } ref={ this.audioRef } controlsList="nodownload" loop={ loop }>
                <source src={ src } type="audio/mpeg" onError={ this.handleBrokenLink } />
              </audio>
            </Grid>
          </Grid>
        </Grid>
      </div>}
    </div>;
  }
}
SoundWidget.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  controls: PropTypes.bool.isRequired,
  loop: PropTypes.bool.isRequired,
};
export default (SoundWidget);