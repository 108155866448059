import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import TitleBar from '../../../../components/documentation/TitleBar';
import ImageWidget from '../../../../components/widgets/media/ImageWidget';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  textField: {
    maxWidth: 400
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  content: {
    height: 250,
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'image'
  },
  {
    title: 'src',
    desc: 'URL of the source image. Bookcicle will not display the image if an error occurs in loading it.',
    values: 'Must be a Bookcicle hosted image - available in the bookcicle publishing dashboard.'
  }
];

export class ImageDocs extends React.PureComponent {

  state = {
    widget: {
      widgetType: 'image',
      src: 'https://images.bookcicle.com/images/aaron-burden-300808-unsplash.jpg'
    }
  };

  onChangeJSON = (json) => {
    this.setState({ widget: JSON.parse(json) });
  };

  handleChange = name => event => {
    const value = event.target.value;
    this.setState({
      widget: { ...this.state.widget, [ name ]: value }
    });
  };

  render() {
    const { classes } = this.props;
    const { widget } = this.state;

    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Image Widget" icon={ WIDGETS_ICONS[ 2 ] } color={ DOCS[ 1 ].avatarColor }/>

        <DocsDesc header="Image New-Line Widget" desc="Include images into your work."/>
        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ widget } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <List>
                <ListItem>
                  <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel
                      htmlFor="src"
                    >
                      Source
                    </InputLabel>
                    <Select
                      native
                      style={ { margin: 8 } }
                      value={ widget.src }
                      onChange={ this.handleChange('src') }
                      input={
                        <OutlinedInput
                          labelWidth={ 50 }
                          name="Source"
                          id="src"
                        />
                      }
                    >
                      <option>https://images.bookcicle.com/images/aaron-burden-300808-unsplash.jpg</option>
                      <option>https://images.bookcicle.com/images/ben-cliff-230074-unsplash.jpg</option>
                      <option>https://images.bookcicle.com/images/chris-lawton-262660-unsplash.jpg</option>
                      <option>https://images.bookcicle.com/images/mel-elias-246175-unsplash.jpg</option>
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <Typography>Here we are telling a story with our normal manuscript text....</Typography>
              <ImageWidget src={ widget.src }/>
            </Paper>
          </WidgetsDemoGrid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
ImageDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageDocs);