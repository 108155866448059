import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrownOpen } from '@fortawesome/free-regular-svg-icons';

const styles = theme => ({
  actions: {
    display: 'flex',
  },
  avatar: {
    backgroundColor: red[ 500 ],
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  media: {
    paddingTop: '10%',
    height: '250px',
    objectFit: 'cover',
  },
  iconSmall: {
    fontSize: 20,
  },
  iconHeight: {
    height: '50px',
    marginLeft: 'auto',
  }
});

class SoEmptyCard extends React.Component {
  state = {
    expanded: false,
    openModal: false,
  };

  render() {
    const width = window.innerWidth;
    const { classes } = this.props;

    const smallView = width <= 500 ? { minWidth: width - 80 + 'px' } : {};
    return (
      <div className="card">
        <Card className="grid-card" style={ smallView }>
          <CardHeader
            style={ { maxWidth: '130px' } }
          />
          <CardMedia
            className={ classes.media }
            title="So Empty"
            src="cover"
            height="200"
            style={ { backgroundColor: '#80D8FF' } }
          > <FontAwesomeIcon icon={ faFrownOpen } size={ '10x' }/> </CardMedia>
          <CardContent>
            <Typography variant="h6" component="h4">
              You don't own any books yet...
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
}
export default withStyles(styles)(SoEmptyCard);