import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';

import { theme } from '../../../../theme';

import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import DividerWidget from '../../../../components/widgets/paperWidgets/DividerWidget';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import { DOCS } from '../metadata';
import { WIDGETS_ICONS } from './metadata';
import  TitleBar from '../../../../components/documentation/TitleBar';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: '#90CAF9',
  },
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  content: {
    height: 250,
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'divider'
  },
  {
    title: 'type',
    desc: 'The divider type.',
    values: 'line, star, asterisk'
  },
  {
    title: 'variant',
    desc: 'Which divider variant, only applicable to line type.',
    values: 'fullLength, middle, inset'
  },
  {
    title: 'margin',
    desc: 'Amount of space to pad the divider on top and bottom from other content.',
    values: 'Integers: [0, 50]'
  },
  {
    title: 'padding',
    desc: 'Amount of space to pad the divider and make it appear larger.',
    values: 'Integers: [0, 50]'
  },
];

export class DividerDocs extends React.PureComponent {

  state = {
    labelWidth: 50,
    divider: {
      widgetType: 'divider',
      type: 'line',
      variant: 'middle',
      margin: 10,
      padding: 10,
      size: 'default'
    }
  };

  onChangeJSON = (json) => {
    this.setState({ divider: JSON.parse(json) });
  };

  handleChange = name => event => {
    let value = event.target.value;
    if (name === 'margin' || name === 'padding') {
      value = parseInt(value);
    }
    this.setState({
      divider: { ...this.state.divider, [ name ]: value }
    });
  };

  render() {
    const { classes } = this.props;
    const { divider } = this.state;
    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>
        <TitleBar title="Divider Widget" icon={ WIDGETS_ICONS[ 0 ] } color={ DOCS[ 1 ].avatarColor } />
        <DocsDesc header="Divider New-Line Widget" desc="Create a dividing line in any of your content, or use with other widgets that accept bookcicle widgets."/>
        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ divider } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid item xs={ 6 }>
            <Paper className={ classes.paperContent }>
              <DividerDocForm classes={ classes } handleChange={ this.handleChange } widget={ divider } />
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              I am example text.
              <DividerWidget variant={ divider.variant } margin={ divider.margin } padding={ divider.padding } type={ divider.type } size={ divider.size } />
              There, I have been divided!
            </Paper>
          </WidgetsDemoGrid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
DividerDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DividerDocs);

export function DividerDocForm(props) {
  const { classes, widget, handleChange } = props;
  return(<List>
    <ListItem>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="margin"
        >
          margin
        </InputLabel>
        <Select
          native
          className="margin-10"
          value={ widget.margin }
          onChange={ handleChange('margin') }
          input={
            <OutlinedInput
              labelWidth={ 50 }
              name="margin"
              id="margin"
            />
          }
        >
          <option value={ 0 }>0</option>
          <option value={ 5 }>5</option>
          <option value={ 10 }>10</option>
          <option value={ 20 }>20</option>
          <option value={ 30 }>30</option>
          <option value={ 50 }>50</option>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="padding"
        >
          padding
        </InputLabel>
        <Select
          native
          className="margin-10"
          value={ widget.padding }
          onChange={ handleChange('padding') }
          input={
            <OutlinedInput
              labelWidth={ 50 }
              name="padding"
              id="padding"
            />
          }
        >
          <option value={ 0 }>0</option>
          <option value={ 5 }>5</option>
          <option value={ 10 }>10</option>
          <option value={ 20 }>20</option>
          <option value={ 30 }>30</option>
          <option value={ 50 }>50</option>
        </Select>
      </FormControl>
    </ListItem>
    <ListItem>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="variant"
        >
          variant
        </InputLabel>
        <Select
          native
          className="margin-10"
          value={ widget.variant }
          onChange={ handleChange('variant') }
          input={
            <OutlinedInput
              labelWidth={ 50 }
              name="variant"
              id="variant"
            />
          }
        >
          <option >fullLength</option>
          <option >middle</option>
          <option >inset</option>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="size"
        >
          size
        </InputLabel>
        <Select
          native
          className="margin-10"
          value={ widget.size }
          onChange={ handleChange('size') }
          input={
            <OutlinedInput
              labelWidth={ 30 }
              name="size"
              id="size"
            />
          }
        >
          <option>small</option>
          <option>default</option>
          <option>large</option>
        </Select>
      </FormControl>
    </ListItem>
    <ListItem>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="type"
        >
          type
        </InputLabel>
        <Select
          native
          className="margin-10"
          value={ widget.type }
          onChange={ handleChange('type') }
          input={
            <OutlinedInput
              labelWidth={ 30 }
              name="type"
              id="type"
            />
          }
        >
          <option>star</option>
          <option>line</option>
          <option>asterisk</option>
          <option>empty</option>
        </Select>
      </FormControl>

    </ListItem>
  </List>);
}
DividerDocForm.propTypes = {
  classes: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};
