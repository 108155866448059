import React from 'react';
import QueryString from 'query-string';
import { validateEmail } from '../reducers/application';
import connect from 'react-redux/src/connect/connect';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Warning from '@material-ui/icons/Warning';
import Check from '@material-ui/icons/Check';
import Loader from '../components/Loader';

const mapStateToProps = (state) => ({
  ...state.application
});

const mapDispatchToProps = (dispatch) => {
  return {
    doValidate: (id, token) => dispatch(validateEmail(id, token))
  };
};

const styles = () => ({
  paperContent: {
    margin: 20,
    padding: 20,
    overflowX: 'auto',
  },
});

class ValidateEmail extends React.PureComponent {
  state = {
    error: false,
  };

  componentDidMount() {
    const parsed = QueryString.parse(window.location.search);
    if (parsed && parsed.userId && parsed.token) {
      this.setState({ error: false });
      this.props.doValidate(parsed.userId, parsed.token);
    }
    else {
      this.setState({ error: true });
    }
  }

  render() {
    const { error } = this.state;
    const { validating, validateError, validateSuccess, classes } = this.props;
    return <div>
      <Grid>
        <Grid item xs={ 12 } >
          <Grid container justify="center" alignItems="center">
            <Paper className={ classes.paperContent }>
              <Typography variant="h6">Email Validation</Typography>
              <List>
                {error && <ListItem>
                  <ListItemIcon><Warning/></ListItemIcon>
                  <ListItemText primary="Oops" secondary="Invalid activation code."/>
                </ListItem>}
                {!validating && validateError && <ListItem>
                  <ListItemIcon><Warning/></ListItemIcon>
                  <ListItemText primary="Oops" secondary="An error occurred validating your account. Please contact support."/>
                </ListItem>}
                {!validating && validateSuccess && <ListItem>
                  <ListItemIcon><Check/></ListItemIcon>
                  <ListItemText primary="Success!" secondary="Your account is now active. Please login above to get going!"/>
                </ListItem>}
                {validating && <ListItem>
                  <Loader/>
                </ListItem>}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>;
  }
}export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ValidateEmail));