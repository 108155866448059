import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper/Paper';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Typography from '@material-ui/core/Typography';
import CopyTextComponent from '../CopyTextComponent';
import EditIcon from '@material-ui/icons/Edit';

import ExpPanel from '../../components/ExpPanel';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';

import Editor from 'react-simple-code-editor';
import Prism from 'prismjs';
import '../../css/prism.css';

// eslint-disable-next-line
import loadLanguages from 'prismjs/components/prism-json.min';
import DocsGridWrapper from '../documentation/DocsGridWrapper';

const useStyles = makeStyles({
  paperContent: {
    padding: 20
  },
  button: {
    float: 'right',
    marginTop: 20
  },
});

function BookcicleJson(props){
  const [ edit, setEdit ] = React.useState(false);
  const classes = useStyles();
  const { hideCopy, code } = props;

  const handleToggleEdit = () => {setEdit(!edit);};

  const handleSave = () => {
    handleToggleEdit();
    Prism.highlightAll();
  };

  const content = <Paper className={ classes.paperContent } elevation={ 0 }>

    {!hideCopy && <div>

      <Grid container>
        <Grid item xs={ 12 } sm={ 6 }>
          <List>
            <ListItem>
              <ListItemText primary={
                <Typography variant="h6">
                  <CopyTextComponent text={ `<bc>${ JSON.stringify(code).replace(/":/g, '": ').replace(',', ', ') }</bc>` }/> Bookcicle JSON
                </Typography> }/>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          {!edit && <Button onClick={ handleToggleEdit } color="primary" className={ classes.button }> <EditIcon/> Edit </Button>}
          {edit && <Button onClick={ handleSave } color="primary" className={ classes.button }> Save </Button>}
        </Grid>
      </Grid>

      {!edit && <pre><code style={ { textAlign: 'left', color: 'black', wordWrap: 'break-word', overflow: 'hidden', whiteSpace: 'pre-wrap' } }>
        &lt;bc&gt;<br/>
        {JSON.stringify(code, undefined, 2)}
        <br/>
        &lt;/bc&gt;
      </code></pre>}
      {edit && <pre><code><Editor
        value={ JSON.stringify(code, null, 2) }
        onValueChange={ json => props.onChangeJSON(json) }
        highlight={ json => Prism.highlight(json, Prism.languages.json, 'json') }
        padding={ 10 }
      /></code></pre>}
    </div>
    }
    {hideCopy &&
      <div>
        <pre><code style={ { textAlign: 'left', color: 'black', wordWrap: 'break-word', overflow: 'hidden', whiteSpace: 'pre-wrap' } }>
          {JSON.stringify(code, undefined, 2)}
        </code></pre>
      </div>
    }
  </Paper>;
  return (<div>
    {!hideCopy && <DocsGridWrapper
      elevation={ 0 }
      customStyle="panel"
      body={ <ExpPanel header="Bookcicle JSON" content={ content } secondaryHeading="View, Copy, and Paste Bookcicle JSON"/> }
    />}
    {hideCopy && <div>{content}</div>}
  </div>
  );
}
BookcicleJson.propTypes = {
  code: PropTypes.object.isRequired,
};
export default (BookcicleJson);