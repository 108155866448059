import React from 'react';

function Privacy(props) {
  const host = window.location.hostname;
  return (<div className={ props.none ? '' : 'reader' }  style={ { marginTop: '20px', textAlign: 'left' } }>
    <h2>Privacy Policy - Bookcicle, LLC.</h2>
    <h3>Introduction</h3>
    <p>
      Your privacy is very important to Bookcicle, LLC (“we”, “our” or the “Company”). Accordingly, we have developed
      this Privacy Policy (this “Policy”) to make clear how we collect, use, communicate and disclose information from
      users who access our website at {host}, (the “Site”). Additionally, this Policy describes the
      measures we take to protect the security of our users’ information and how users of the Site and our customers
      can contact us about our privacy practices.
    </p>
    <p>
      Processing your Personal Information (as defined herein) is necessary to provide you with our services,
      as well as to protect our legitimate interests and to comply with the legal and financial regulatory
      obligations to which we are subject.
    </p>
    <h3>Agreement</h3>
    <p>
      BY USING THE SITE, YOU ARE ACCEPTING THE PRACTICES DESCRIBED IN THIS POLICY. IF YOU DO NOT AGREE TO THE TERMS OF
      THIS POLICY, THEN YOU ARE NOT AUTHORIZED TO USE THE SITE.  BOOKCICLE RESERVES THE RIGHT TO MODIFY OR AMEND THE
      TERMS OF THIS POLICY FROM TIME TO TIME IN OUR SOLE DISCRETION.  YOUR CONTINUED USE OF THE SITE FOLLOWING THE
      POSTING OF CHANGES TO THIS POLICY WILL CONSTITUTE YOUR ACCEPTANCE OF THOSE CHANGES.
    </p>
    <h3>Information We Collect</h3>
    <p>
      We collect two types of data and information as follows:
    </p>
    <ol>
      <li><b>Non-personal Information - </b>
        We may collect certain un-identified and non-identifiable information (“Non-personal Information”).
        Non-personal Information which is being collected may include your aggregated usage information and
        technical information transmitted by your device, including certain software and hardware information
        (e.g., the type of browser and operating system your device uses, language preferences, access time, etc.)
        in order to enhance the functionality of the Site. We may also collect information regarding your activity
        on the Site (e.g., pages viewed, online browsing, clicks, actions, etc.).
      </li>
      <li><b>Identifiable Information - </b>
        We may obtain information that is individually identifiable, i.e. information that identifies an individual
        or which may, with reasonable effort, identify an individual (“Identifiable Information”). Such Identifiable
        Information includes, but is not limited to:
        <ul>
          <li>Device Information: we collect Identifiable Personal Information from your device(s) including IP address, unique identifiers (e.g., MAC address and UID) and other information which relates to your activity through the Site.</li>
          <li>Sign-in Information: When you sign into the Site, you will be automatically registered. Through this process, we collect and store information such as your name, email, and ID from your identity provider (e.g., Google).</li>
          <li>Social Media Information: We may collect information provided to us by social networks if you log in to this website using your social network credentials.</li>
          <li>Payment Information: We utilize Stripe, Inc. (“Stripe”) for the collection and processing of payment
            information for transactions involving the Site; in order to learn what information Stripe collects,
            please see Stripe’s privacy policy at: https://stripe.com/us/privacy. Please note that Bookcicle is not
            responsible for the policies and practices of Stripe or for the actions or omissions of its agents and
            employees.</li>
        </ul>
      </li>
    </ol>
    <p>
      We may collect this information under various circumstances, including, but not limited to, when: (i) you
      voluntarily provide us your personal details in order to sign into the Site; (ii) you use or access the Site in
      connection with your use of our services; and (iii) it is provided to us by third party providers, services and
      public registers (e.g., traffic analytics vendors).
    </p>
    <p>
      When you visit the Site or interact with our emails or online advertisements, we may collect information about
      your usage or device through automated means or technologies. The technologies we may use for this automatic
      data collection include, but are not limited to:
    </p>

    <ol>
      <li><b>Cookies (or browser cookies) - </b> a cookie is a small text file placed on the hard drive of your computer or other Internet-connected device that uniquely identifies your browser to store information or settings in your browser. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to use all of the features of the Site.</li>
      <li><b>Web Server Logs - </b> in conjunction with obtaining information through cookies, our web servers may log details such as your operating system type, browser type, domain, and other system settings, as well as the language your system uses and the country and time zone in which your device is located. The web server logs also may record information such as the address of the web page that linked you to the Site and the IP address of the device you use to connect to the Internet.</li>
      <li><b>Web Beacons - </b> pages of the Site and our emails to you may contain small electronic files known as web beacons (also referred to as clear gifs. pixel tags and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened an email and for other related website statistics (e.g., recording the popularity of certain website content and verifying system and server integrity).</li>
    </ol>

    <h3>Use of Information</h3>
    <p>
      We do not rent, sell or share your information with third parties except as described in this Policy.
    </p>
    <p>
      We may use your information for the following purposes:
    </p>
    <ol>
      <li><b>Communication - </b> sending you notices regarding our services, providing you with technical information and responding to any customer service issue(s) you may have.</li>
      <li><b>Advertising - </b> serving you advertisements when you use the Site (see “Advertisements” below).</li>
      <li><b>Marketing - </b> marketing our Site and products (see “Marketing” below).</li>
      <li><b>Analysis - </b> conducting statistical and analytical studies for the purpose of improving the Site.</li>
    </ol>
    <p>
      In addition to those purposes listed above, we may share your Personal Information with our trusted third party
      providers, who may be located in different jurisdictions across the world, for any of the following purposes:
    </p>
    <ol>
      <li>Hosting and operating the Site.</li>
      <li>Providing you with our services, including providing a personalized display of the Site.</li>
      <li>Storing and processing information on our behalf.</li>
      <li>Serving you with advertisements and assisting us in evaluating the success of our advertising campaigns and helping us retarget any of our users.</li>
      <li>Providing you with marketing offers and promotional materials related to the Site and our services.</li>
      <li>Performing research, technical diagnostics or analytics.</li>
    </ol>
    <p>
      We may also share your Personal Information with:
    </p>
    <ol>
      <li>Our contractors, service providers and other third parties we use to support our business and to fulfill services requested by you, including, without limitation, credit card processing companies, marketing services providers and consultants, public relations firms, and printers.</li>
      <li>A buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which Personal Information held by the Company about the Site’s users is among the assets transferred. Under such circumstances we would, to the extent possible, require the acquiring party to follow the practices described in this Policy, as it may be amended from time to time. Nevertheless, we cannot ensure that an acquiring company or the merged company will have the same privacy practices or treat your information in the same manner as described in this Policy.</li>
      <li>Other third parties with your express consent.</li>
    </ol>
    <p>
      We may also disclose information if we have a good faith belief that disclosure of such information is helpful or reasonably necessary to: (i) comply with any applicable law, regulation, legal process or governmental request; (ii) enforce our policies and agreements with you, including investigations of potential violations thereof; (iii) investigate, detect, prevent, or take action regarding illegal activities or other wrongdoing, suspected fraud or security issues; (iv) to establish or exercise our rights to defend against legal claims; (v) prevent harm to the rights, property or safety of us, our users, yourself or any third party; or (vi) collaborate with law enforcement agencies in the event that we find it necessary in order to enforce intellectual property or other legal rights.
    </p>
    <h3>User Rights</h3>
    <p>
      You may:
    </p>
    <ol>
      <li>Request to receive confirmation as to whether or not your Personal Information is being processed and access your stored Personal Information, together with supplementary information.</li>
      <li>Request to receive a copy of Personal Information you directly volunteer to us in a structured, commonly used and machine-readable format.</li>
      <li>Request rectification of your Personal Information that is in our control.</li>
      <li>Object to the processing of your Personal Information by us.</li>
      <li>Request to restrict processing of your Personal Information by us.</li>
      <li>Request to opt out of the use of your Personal Information by us.</li>
      <li>Lodge a complaint with a supervisory authority.</li>
    </ol>
    <p>
      However, please note that these rights are not absolute and may be subject to our own legitimate interests and regulatory requirements. If you wish to exercise any of the aforementioned rights, or to receive more information, then please contact our Data Protection Officer (“DPO”) using the information provided below:
    </p>
    <pre>
      <code>
        Eulalie Paris COO, DPO<br />
        support@bookcicle.com
      </code>
    </pre>
    <h3>Retention</h3>
    <p>
      We will retain your personal information for as long as necessary to provide our services, comply with our legal obligations, resolve disputes, and enforce our policies. Retention periods will be determined by taking into account the type of information that is collected and the purpose for which it is collected, bearing in mind the requirements applicable to the situation and the need to destroy outdated, unused information at the earliest reasonable time. Pursuant to applicable laws and regulations, we will keep records containing client personal data, account opening documents, communications and anything else as required by such laws and regulations.
    </p>
    <p>
      We may rectify, replenish or remove incomplete or inaccurate information at any time and at our own discretion.
    </p>
    <h3>
      Third Party Collection of Information
    </h3>
    <p>
      This Policy only addresses the use and disclosure of information we collect from you.  To the extent that you disclose your information to other parties or websites throughout the internet, different rules may apply to their use or disclosure of the information you disclose to them.  Accordingly, we encourage you to read the terms and conditions and privacy policy of each third party that you choose to disclose information to.
    </p>
    <p>
      This Policy does not apply to the practices of companies that we do not own or control, or to individuals whom we do not employ or manage, including any of the third parties to which we may disclose information as set forth in this Policy.
    </p>
    <h3>Data Security</h3>
    <p>We take reasonable steps and employ industry standard procedures and policies to help protect and secure your Personal Information from accidental loss and unauthorized access, use, alteration and disclosure. Although we take reasonable steps to safeguard your Personal Information, please remember that no data storage or data transmission over the internet, or by other means, can be guaranteed to be one hundred percent (100%) secure. Thus, we cannot ensure or warrant the security of any information you transmit to us. Additionally, we cannot be responsible for the acts of those who gain unauthorized access to or abuse the Site, and we make no warranty, express, implied or otherwise, that we will prevent such access. Therefore, you understand, acknowledge, and agree that you transmit your Personal Information to the Site at your own risk.</p>
    <h3>Marketing</h3>
    <p>
      We may use your Personal Information, such as your name, email address, telephone number, and other information ourselves or by using our third-party subcontractors for providing you with promotional materials concerning our services which we believe may interest you.
    </p>
    <p>
      Out of respect to your right to privacy, we provide you with the means to decline receiving further marketing offers from us within such marketing materials. If you unsubscribe, then we will remove your email address or telephone number from our marketing distribution lists.
    </p>
    <p>
      Please note that even if you have unsubscribed from receiving marketing emails from us, we may send you other types of important e-mail communications without offering you the opportunity to opt out of receiving them. These may include customer service announcements or administrative notices.
    </p>
    <h3>Children Under the Age of 13 </h3>
    <p>
      We understand the importance of protecting children’s privacy, especially in an online environment. The Site is not designed for or directed at children under the age of 13. Under no circumstances shall we knowingly allow use of our services by children under the age of 13 without prior consent or authorization by a parent or legal guardian. We do not knowingly collect Personal Information from children under the age of 13. If a parent or guardian becomes aware that his or her child under the age of 13 has provided us with Personal Information without their consent, he or she should contact us at:
    </p>
    <pre><code>support@bookcicle.com</code></pre>
    <h3>Governing Law</h3>
    <p>
      This Policy shall be governed and construed in accordance with the laws of the State of New Hampshire, without giving effect to its conflict of laws provisions. You agree that any dispute concerning the subject matter of these Terms shall be resolved only by litigation in applicable New Hampshire state courts or federal courts in the District of New Hampshire of proper jurisdiction and venue.  You agree to submit to such exclusive jurisdiction and venue for all purposes hereunder.
    </p>
    <p>
      The Site is published, controlled and operated in the United States. We attempt to protect the Personal Information of all users of the Site and we attempt to comply with local data protection and consumer rights laws to the extent they may apply to our services, but the Site is located and targeted to United States citizens and our policies are directed at compliance with those laws. If you are uncertain whether this Policy conflicts with the applicable local privacy laws where you are located, you should not submit your Personal Information to us.
    </p>
    <h3>
      Notice to Non-US Users
    </h3>
    <p>
      If you are located outside the United States of America, then you should be aware that your Personal Information will be transferred to the United States of America, the laws of which may be deemed by your country to have inadequate data protection. If you are located in a country outside the United States of America and voluntarily submit Personal Information to us on the Site, then you thereby consent to the general use of such information as provided in this Policy and to the transfer of that information to, and the storage of that information in, the United States of America.
    </p>
    <h3>
      Updates or Amendments to this Policy
    </h3>
    <p>
      We reserve the right to periodically amend or revise this Policy; material changes will be effective immediately upon the display of the revised Policy. The last revision will be reflected by the date given in the “Last Modified” section below. Your continued use of the Site, following the notification of such amendments on the Site, constitutes your acknowledgment and consent of such amendments to the Policy and your agreement to be bound by the terms of such amendments.
    </p>
    <h3>Contact Information</h3>
    <p>
      If you have any general questions about the Site or the information we collect about you and how we use it, then you can contact us at:
    </p>
    <pre>
      <code>support@bookcicle.com</code>
    </pre>
    <h3>Last Modified</h3>
    <pre><code>03/21/2019</code></pre>
  </div>
  );

}

export default Privacy;