import firebase from 'firebase/app';
import 'firebase/auth';
import history from '../routes/history';
import BookcicleApi from '../bookcicleApi';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '../middleware/bookcicleStorage';
import { decodeJwt } from '../utils';

const SHOW_LOGIN = 'application/SHOW_LOGIN';
const HIDE_LOGIN = 'application/HIDE_LOGIN';

const CHANGE_LOGIN_VERSION = 'application/CHANGE_LOGIN_VERSION';
const LOGIN = 'application/LOGIN';
const LOGIN_FAILURE = 'application/LOGIN_FAILURE';
const LOGIN_SUCCESS = 'application/LOGIN_SUCCESS';
const CLEAR_LOGIN = 'application/CLEAR_LOGIN';
const CLEAR_LOGIN_ERROR = 'application/CLEAR_LOGIN_ERROR';

const LOGOUT = 'application/LOGOUT';
const LOGOUT_SUCCESS = 'application/LOGOUT_SUCCESS';
const FIREBASE_KEY = process.env.REACT_APP_FIREBASE_KEY;
const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
const TOGGLE_APP_BAR_STATE = 'application/TOGGLE_APP_BAR_STATE';

const VALIDATE = 'application/VALIDATE';
const VALIDATE_SUCCESS = 'application/VALIDATE_SUCCESS';
const VALIDATE_FAILURE = 'application/VALIDATE_FAILURE';

const RESET_PASSWORD = 'application/RESET_PASSWORD';
const RESET_PASSWORD_SUCCESS = 'application/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = 'application/RESET_PASSWORD_FAILURE';

const NEW_PASSWORD = 'application/NEW_PASSWORD';
const NEW_PASSWORD_SUCCESS = 'application/NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_FAILURE = 'application/NEW_PASSWORD_FAILURE';

const config = {
  apiKey: FIREBASE_KEY,
  authDomain: 'bookcicle.firebaseapp.com',
  databaseURL: 'https://bookcicle.firebaseio.com',
  projectId: 'bookcicle',
  storageBucket: 'bookcicle.appspot.com',
  messagingSenderId: '770573812425'
};

firebase.initializeApp(config);

let authenticationJSON = getLocalStorage('auth');
const now = Date.now();

if (authenticationJSON && now >= JSON.parse(authenticationJSON).expires) {
  logoutSideEffects();
  try {
    firebase.auth().signOut();
    history.push('/loggedOut');
  }
  catch (ignore) {
    //  Ignore
  }
  authenticationJSON = null;
}
const isAuthenticated = (authenticationJSON && JSON.parse(authenticationJSON) !== null);

const INITIAL_STATE = {
  auth: authenticationJSON ? JSON.parse(authenticationJSON) : null,
  showAppBar: true,
  isAuthenticated: isAuthenticated,
  loggingIn: false,
  loginError: null,
  showingLogin: false,
  signingOut: false,
  version: null,
  validating: false,
  validateError: null,
  validateSuccess: false,
  resettingPassword: false,
  resetPasswordError: null,
  resetPasswordSuccess: false,
  updatingPassword: false,
  updatePasswordError: null,
  updatePasswordSuccess: false
};

function logoutSideEffects() {
  removeLocalStorage('auth');
}

export default function application(state = INITIAL_STATE, action) {
  switch (action.type) {
  case SHOW_LOGIN:
    return { ...state, showingLogin: true, version: action.version };
  case HIDE_LOGIN:
    return { ...state, showingLogin: false };

  case LOGIN:
    return { ...state, loggingIn: true, loginError: null };
  case LOGIN_FAILURE:
    return { ...state, loggingIn: false, loginError: action.error };
  case LOGIN_SUCCESS:{
    if (action.payload.newUser || action.payload.notValidated) {
      if(action.payload.notValidated){
        return { ...state, loggingIn: false, auth: action.payload, isAuthenticated: false };
      }
      else{
        return { ...state, loggingIn: false, auth: action.payload, isAuthenticated: true };
      }
    }
    else {
      return { ...state, loggingIn: false, showingLogin: false, auth: action.payload, isAuthenticated: true };
    }
  }
  case CHANGE_LOGIN_VERSION:
    return { ...state, version: action.version };
  case CLEAR_LOGIN_ERROR:
    return { ...state, loggingIn: false, loginError: null };
  case CLEAR_LOGIN:
    return { ...state, loggingIn: false, showingLogin: false, loginError: null, resettingPassword: false, resetPasswordError: null, updatePasswordError: null, updatingPassword: false };

  case VALIDATE:
    return { ...state, validating: true, validateError: null };
  case VALIDATE_SUCCESS:
    return { ...state, validating: false, validateError: null, validateSuccess: true };
  case VALIDATE_FAILURE:
    return { ...state, validating: false, validateError: action.error };

  case LOGOUT:
    return { ...state, auth: null, isAuthenticated: false, signingOut: true };
  case LOGOUT_SUCCESS:
    return { ...state, auth: null, signingOut: false, showingLogin: false, isAuthenticated: false };

  case TOGGLE_APP_BAR_STATE:
    return { ...state, showAppBar: !state.showAppBar };

  case RESET_PASSWORD:
    return { ...state, resetPasswordError: null, resettingPassword: true, resetPasswordSuccess: false };
  case RESET_PASSWORD_FAILURE:
    return { ...state, resetPasswordError: action.error, resettingPassword: false,  resetPasswordSuccess: false };
  case RESET_PASSWORD_SUCCESS:
    return { ...state, resetPasswordError: null, resettingPassword: false, resetPasswordSuccess: true };

  case NEW_PASSWORD:
    return { ...state, updatePasswordError: null, updatingPassword: true, updatePasswordSuccess: false };
  case NEW_PASSWORD_FAILURE:
    return { ...state, updatePasswordError: action.error, updatingPassword: false, updatePasswordSuccess: false };
  case NEW_PASSWORD_SUCCESS:
    return { ...state, updatePasswordError: null, updatingPassword: false, updatePasswordSuccess: true };

  default:
    return { ...state };
  }
}

export const updatePassword = (email, token, password) => (dispatch) => {
  dispatch({ type: NEW_PASSWORD });
  new BookcicleApi({}).post(
    {
      useLambda: true,
      contentType: 'application/json',
      path: `${ LOGIN_URL }login`,
      data: {
        provider: 'bookcicle',
        action: 'update-password',
        email,
        token,
        password
      },
    }, (error, result) => {
      if (error) {
        if (result && result.body && result.body.message) {
          dispatch({ type: NEW_PASSWORD_FAILURE, error: result.body.message });
          return;
        }
        dispatch({ type: NEW_PASSWORD_FAILURE, error: `${ error }` });
        return;
      }
      dispatch({ type: NEW_PASSWORD_SUCCESS });
    }
  );
};

export const resetPassword = (email) => (dispatch) => {
  dispatch({ type: RESET_PASSWORD });
  new BookcicleApi({}).post(
    {
      useLambda: true,
      contentType: 'application/json',
      path: `${ LOGIN_URL }login`,
      data: {
        provider: 'bookcicle',
        action: 'reset-password',
        email: email,
      },
    }, (error, result) => {
      if (error) {
        if (result && result.body && result.body.message) {
          dispatch({ type: RESET_PASSWORD_FAILURE, error: result.body.message });
          return;
        }
        dispatch({ type: RESET_PASSWORD_FAILURE, error: `${ error }` });
        return;
      }
      dispatch({ type: RESET_PASSWORD_SUCCESS });
    }
  );
};

export const showLoginModal = (version) => ({ type: SHOW_LOGIN, version });

export const hideLoginModal = () => ({ type: HIDE_LOGIN });

export const loginFailure = (response) => (dispatch) => {
  if (response) dispatch({ type: LOGIN_FAILURE, error: response.details });
};

export const clearLogin = () => (dispatch) => {
  dispatch({ type: CLEAR_LOGIN });
};

export const clearLoginError = () => (dispatch) => {
  dispatch({ type: CLEAR_LOGIN_ERROR });
};

const firstTimeUse = (apiKey) => {
  new BookcicleApi({ apiKey: apiKey }).get({
    path: 'books'
  },
  (error) => {
    if (error) {
      console.error(error);
    }
  });
};

const _handleFacebookLogin = (version, response, dispatch, next) => {
  if(response){
    if(response.accessToken && response.email && response.id && response.name){
      const obj = {
        'displayName': response.name,
        'uid': response.id,
      };
      new BookcicleApi({ accessToken: response.accessToken }).post(
        {
          useLambda: true,
          contentType: 'application/json',
          path: `${ LOGIN_URL }login`,
          data: {
            action: version,
            provider: 'facebook',
            userId: response.id,
            email: response.email,
            name: response.name
          },
        }, (error, result) => {
          if (error) {
            if (result && result.body && result.body && result.body.message) {
              dispatch({ type: LOGIN_FAILURE, error: result.body.message });
              return;
            }
            dispatch({ type: LOGIN_FAILURE, error: `${ error }` });
            return;
          }
          obj.email = result.email;
          const authentication = {
            'accessToken': response.accessToken,
            'expires': new Date().addDays(60),
            'bookcicleAuth': result.bookcicleAuth,
            'userId': result.userId,
            'username': result.username,
            'apiKey': result.apiKey,
            'newUser': result.newUser,
            'userDetails': obj,
            'notValidated': result.notValidated
          };
          setLocalStorage('auth', authentication);
          dispatch({ type: LOGIN_SUCCESS, payload: authentication });
          if(result.newUser) {
            firstTimeUse(result.apiKey);
          }
          else if (!result.notValidated) {
            history.push({ pathname: next ? next : '/books' });
          }
        }
      );
    }
  }
};

const _handleGoogleLogin = (version, response, dispatch, next) => {
  if (response) {
    if (response.tokenId && response.tokenId !== null) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          const credential = firebase.auth.GoogleAuthProvider.credential(response.tokenId);
          firebase.auth().signInWithCredential(credential).then(() => {
            const obj = {
              'displayName': firebase.auth().currentUser.displayName,
              'email': firebase.auth().currentUser.email,
              'uid': firebase.auth().currentUser.uid,
            };
            firebase.auth().currentUser.getIdToken().then(function (accessToken) {
              new BookcicleApi({ accessToken: accessToken }).post(
                {
                  useLambda: true,
                  contentType: 'application/json',
                  path: `${ LOGIN_URL }login`,
                  data: {
                    action: version,
                  },
                }, (error, result) => {
                  if (error) {
                    if (result && result.body && result.body.message) {
                      dispatch({ type: LOGIN_FAILURE, error: result.body.message });
                      return;
                    }
                    dispatch({ type: LOGIN_FAILURE, error: `${ error }` });
                    return;
                  }
                  const authentication = {
                    'accessToken': accessToken,
                    'expires': new Date().addDays(60),
                    'bookcicleAuth': result.bookcicleAuth,
                    'userId': result.userId,
                    'username': result.username,
                    'apiKey': result.apiKey,
                    'newUser': result.newUser,
                    'userDetails': obj
                  };
                  setLocalStorage('auth', authentication);
                  dispatch({ type: LOGIN_SUCCESS, payload: authentication });
                  if(result.newUser) {
                    firstTimeUse(result.apiKey);
                  }
                  else{
                    history.push({ pathname: next ? next : '/books' });
                  }
                }
              );
            });
          });
        }).catch(function (error) {
          dispatch({ type: LOGIN_FAILURE, error: error.message });
        });
    }
    else {
      dispatch({ type: LOGIN_FAILURE, error: 'Invalid Google Token.' });
    }
  }
};

const _handleEmailLogin = (version, response, dispatch, next) => {
  const decodedJwt = decodeJwt(response);
  new BookcicleApi({ accessToken: response }).post(
    {
      useLambda: true,
      contentType: 'application/json',
      path: `${ LOGIN_URL }login`,
      data: {
        provider: 'bookcicle',
        action: version,
        email: decodedJwt.email,
        name: decodedJwt.name,
        password: decodedJwt.password
      },
    }, (error, result) => {
      if (error) {
        if (result && result.body && result.body.message) {
          dispatch({ type: LOGIN_FAILURE, error: result.body.message });
          return;
        }
        dispatch({ type: LOGIN_FAILURE, error: `${ error }` });
        return;
      }

      const authentication = {
        'accessToken': response,
        'expires': new Date().addDays(60),
        'bookcicleAuth': result.bookcicleAuth,
        'userId': result.userId,
        'username': result.username,
        'apiKey': result.apiKey,
        'newUser': result.newUser,
        'userDetails': {
          'displayName': result.name,
          'email': result.email,
          'uid': result.userId,
        },
        'notValidated': result.notValidated
      };
      setLocalStorage('auth', authentication);
      dispatch({ type: LOGIN_SUCCESS, payload: authentication });
      if(result.newUser) {
        firstTimeUse(result.apiKey);
      }
      else{
        history.push({ pathname: next ? next : '/books' });
      }
    }
  );
};

export const login = (version, response, next, provider) => (dispatch) => {
  dispatch({ type: LOGIN });
  switch (provider) {
  case 'bookcicle':
    _handleEmailLogin(version, response, dispatch, next);
    break;
  case 'google':
    _handleGoogleLogin(version, response, dispatch, next);
    break;
  case 'facebook':
    _handleFacebookLogin(version, response, dispatch, next);
    break;
  default:
    break;
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT });
  try {
    firebase.auth().signOut().then(function () {
      logoutSideEffects();
      dispatch({ type: LOGOUT_SUCCESS });
    }, function () {
      logoutSideEffects();
      dispatch({ type: LOGOUT_SUCCESS });
    });
  }
  catch (ignore) {
    console.error(ignore);
    logoutSideEffects();
    dispatch({ type: LOGOUT_SUCCESS });
  }
};

export const changeLoginVersion = (version) => (dispatch) => {
  dispatch({ type: CHANGE_LOGIN_VERSION, version });
};

export const toggleAppBarState = () => (dispatch) => {
  dispatch({ type: TOGGLE_APP_BAR_STATE });
};

export const validateEmail = (id, token) => (dispatch) => {
  dispatch({ type: VALIDATE });
  new BookcicleApi({}).put(
    {
      useLambda: true,
      contentType: 'application/json',
      path: `${ LOGIN_URL }validate`,
      queryData: {
        userId:  id,
        token: token
      }
    }, (error, result) => {
      if (error) {
        if (result && result.body && result.body && result.body.message) {
          dispatch({ type: VALIDATE_FAILURE, error: result.body.message });
          return;
        }
        dispatch({ type: VALIDATE_FAILURE, error: `${ error }` });
        return;
      }
      dispatch({ type: VALIDATE_SUCCESS });
    }
  );
};

export function getAuth(state) {
  return state.application.auth;
}

export function getAppBarState(state) {
  return state.application.showAppBar;
}

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    //user
  }
  else {
    logoutSideEffects();
  }
}, function (error) {
  console.error(error);
});