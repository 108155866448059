import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import GridList from '@material-ui/core/GridList';
import Paper from '@material-ui/core/Paper';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ShopIcon from '@material-ui/icons/Shop';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import SoEmptyCard from '../../components/marketing/SoEmptyCard';
import MarketingCard from '../../components/marketing/MarketingCard';
import BookCard from './BookCard';
import Loader from '../../components/Loader';
import BookcicleErrorAlert from '../../components/BookcicleErrorAlert';
import { theme } from '../../theme';

import ReactGA from 'react-ga';
import connect from 'react-redux/src/connect/connect';
import { listBooks, listPurchasedBooks } from '../../reducers/book';
import { getAuth } from '../../reducers/application';

const mapStateToProps = (state) => ({
  ...state.book,
  ...state.application,
  auth: getAuth(state)
});
const mapDispatchToProps = (dispatch) => {
  return {
    fetchBooks: (auth) => dispatch(listBooks(auth)),
    fetchPurchasedBooks: (auth) => dispatch(listPurchasedBooks(auth)) };
};

function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={ dir } style={ { padding: 8 * 3 } }>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  tabRoot: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0,
  },
  tab: {
    border: 'none !important',
    borderStyle: 'none !important',
    outline: 'none !important',
    boxShadow: 'none'
  }
});

class Books extends React.Component {

  state = {
    width: 0,
    value: 1,
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    this.setState({ width: window.innerWidth });
    this.props.fetchBooks(this.props.auth);
    this.props.fetchPurchasedBooks(this.props.auth);
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.purchasedBooks !== prevProps.purchasedBooks && this.props.purchasedBooks.length > 0) {
      this.setState({ value: 1 });
    }
  }

  render() {
    const { error, loading, books, purchasedBooksError, loadingPurchasedBooks, purchasedBooks } = this.props;
    const { width, value } = this.state;
    const { classes } = this.props;
    return <div className="card-container">
      <MuiThemeProvider theme={ theme }>
        <Paper className={ classes.tabRoot } elevation={ 0 }>
          <Tabs
            value={ value }
            onChange={ this.handleChange }
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Book Store" icon={ <ShopIcon/> } style={ { margin: '10px' } } classes={ { root: classes.tab } }
              onClick={ () => {
                ReactGA.event({
                  category: 'Books',
                  action: 'show store',
                  label: 'books-list',
                });
              } }/>
            <Tab label="Library" icon={ <LibraryBooksIcon/> } style={ { margin: '10px' } } classes={ { root: classes.tab } }
              onClick={ () => {
                ReactGA.event({
                  category: 'Books',
                  action: 'show library',
                  label: 'books-list',
                });
              } }/>
          </Tabs>
        </Paper>
        <SwipeableViews
          axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
          index={ value }
          onChangeIndex={ this.handleChangeIndex }
        >
          <TabContainer dir={ theme.direction }>
            {loading && <Loader/>}
            {error && <BookcicleErrorAlert error={ error } variant="error" />}
            <div className={ classes.root }>
              <GridList cellHeight={ 180 }>
                <MarketingCard />
                {books && books.map((tile, index) =>
                  <BookCard
                    key={ index }
                    width={ width }
                    bookDetails={ tile }
                    isGrid={ true }
                    isDrawer={ false }
                    tab={ 0 }
                  />
                )}
                {!books && <div></div>}
              </GridList>
            </div>
          </TabContainer>
          <TabContainer dir={ theme.direction }>
            {loadingPurchasedBooks && <Loader/>}
            {!loadingPurchasedBooks && purchasedBooksError && <BookcicleErrorAlert error={ purchasedBooksError } variant="error" />}
            <div className={ classes.root }>
              <GridList cellHeight={ 180 }>
                {purchasedBooks && purchasedBooks.map((tile, index) =>
                  <BookCard
                    key={ index }
                    bookDetails={ tile }
                    width={ width }
                    isGrid={ true }
                    isDrawer={ false }
                    tab={ 1 }
                  />
                )}
                {(!purchasedBooks || purchasedBooks.length === 0) && !loadingPurchasedBooks && <SoEmptyCard />}
              </GridList>
            </div>
          </TabContainer>
        </SwipeableViews>
      </MuiThemeProvider>
    </div>;
  }

}

export default withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Books));
