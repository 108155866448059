import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import TitleBar from '../../../../components/documentation/TitleBar';
import SoundWidgetWrapper from '../../../../components/widgets/media/SoundWidgetWrapper';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  textField: {
    maxWidth: 400
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  content: {
    height: 250,
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'sound'
  },
  {
    title: 'src',
    desc: 'URL of the source mp3 sound track. Bookcicle will not display the mp3 if an error occurs in loading it.',
    values: 'Must be a Bookcicle hosted mp3 - available in the bookcicle publishing dashboard.'
  },
  {
    title: 'autoPlay',
    desc: 'Attempt to automatically play the sound when visible, not supported on all browsers.',
    values: 'true | false'
  },
  {
    title: 'controls',
    desc: 'Show the audio controls',
    values: 'true | false'
  },
  {
    title: 'loop',
    desc: 'Loop this track when visible',
    values: 'true | false'
  },

];

export class SoundDocs extends React.PureComponent {

  state = {
    widget: {
      widgetType: 'sound',
      src: 'https://images.bookcicle.com/sounds/SS00615++++Italian+Church+Bells+mid+distance.mp3',
      autoPlay: true,
      controls: true,
      loop: false,
    }
  };

  onChangeJSON = (json) => {
    this.setState({ widget: JSON.parse(json) });
  };

  handleChange = name => event => {
    let value = event.target.value;
    if (name === 'controls' || name === 'autoPlay' || name === 'loop') {
      value = value === 'true';
    }
    this.setState({
      widget: { ...this.state.widget, [ name ]: value }
    });
  };

  render() {
    const { classes } = this.props;
    const { widget } = this.state;

    return <DocsWrapper>

      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Sound" icon={ WIDGETS_ICONS[ 6 ] } color={ DOCS[ 1 ].avatarColor }/>

        <DocsDesc header="Sound New-Line Widget" desc="Include sounds into your work."/>
        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ widget } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <List>
                <ListItem>
                  <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel
                      htmlFor="src"
                    >
                      Source
                    </InputLabel>
                    <Select
                      native
                      style={ { margin: 8 } }
                      value={ widget.src }
                      onChange={ this.handleChange('src') }
                      input={
                        <OutlinedInput
                          labelWidth={ 50 }
                          name="Source"
                          id="src"
                        />
                      }
                    >
                      <option>https://images.bookcicle.com/sounds/SeparationSourceSegment.mp3</option>
                      <option>https://images.bookcicle.com/sounds/ScanParticle35.mp3</option>
                      <option>https://images.bookcicle.com/sounds/thelibrarybymtc_SMfree_GROANYMONSTER_222.mp3</option>
                      <option>https://images.bookcicle.com/sounds/SS00615++++Italian+Church+Bells+mid+distance.mp3</option>
                      <option>https://images.bookcicle.com/sounds/EFX+SD+Dynamic+Train+Bell+02.mp3</option>
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem>
                  <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel
                      htmlFor="autoPlay"
                    >
                      Auto Play
                    </InputLabel>
                    <Select
                      native
                      style={ { margin: 8 } }
                      value={ widget.autoPlay }
                      onChange={ this.handleChange('autoPlay') }
                      input={
                        <OutlinedInput
                          labelWidth={ 60 }
                          name="Auto Play"
                          id="autoPlay"
                        />
                      }
                    >
                      <option value={ true }>true</option>
                      <option value={ false }>false</option>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel
                      htmlFor="controls"
                    >
                      Controls
                    </InputLabel>
                    <Select
                      native
                      style={ { margin: 8 } }
                      value={ widget.controls }
                      onChange={ this.handleChange('controls') }
                      input={
                        <OutlinedInput
                          labelWidth={ 60 }
                          name="Controls"
                          id="controls"
                        />
                      }
                    >
                      <option value={ true }>true</option>
                      <option value={ false }>false</option>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel
                      htmlFor="loop"
                    >
                      Loop
                    </InputLabel>
                    <Select
                      native
                      style={ { margin: 8 } }
                      value={ widget.loop }
                      onChange={ this.handleChange('loop') }
                      input={
                        <OutlinedInput
                          labelWidth={ 60 }
                          name="Loop"
                          id="loop"
                        />
                      }
                    >
                      <option value={ true }>true</option>
                      <option value={ false }>false</option>
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <Typography>Here we are telling a story with our normal manuscript text....</Typography>
              <SoundWidgetWrapper
                src={ widget.src }
                autoPlay={ widget.autoPlay }
                controls={ widget.controls }
                loop={ widget.loop }
                offset={ 0 }
              />
            </Paper>
          </WidgetsDemoGrid>
        </Grid>

      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
SoundDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SoundDocs);