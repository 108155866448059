import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faSyncAlt, faEnvelopeSquare, faEnvelope, faKey, faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import connect from 'react-redux/src/connect/connect';

import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import '../../node_modules/firebaseui/dist/firebaseui.css';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';

import withStyles from '@material-ui/core/styles/withStyles';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import CheckAll from 'mdi-material-ui/CheckAll';
import CheckBold from 'mdi-material-ui/AccountCheck';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import LinearDeterminate from '../components/LinearDeterminate';
import QueryString from 'query-string';
import { createJwt, isFacebookApp, validateEmail, validatePassword } from '../utils';
import BookcicleErrorAlert from '../components/BookcicleErrorAlert';
import {
  getAuth,
  login,
  loginFailure,
  clearLogin,
  changeLoginVersion,
  clearLoginError,
  logout,
  resetPassword
} from '../reducers/application';
import DialogActions from '@material-ui/core/DialogActions';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Loader from '../components/Loader';

const mapStateToProps = (state) => ({
  ...state.application,
  auth: getAuth(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    doLogin: (version, res, next, provider) => dispatch(login(version, res, next, provider)),
    doLoginFailure: (err) => dispatch(loginFailure(err)),
    doClear: () => dispatch(clearLogin()),
    changeTab: (version) => dispatch(changeLoginVersion(version)),
    doClearError: () => dispatch(clearLoginError()),
    doLogout: () => dispatch(logout()),
    doPasswordReset: (email) => dispatch(resetPassword(email)),
  };
};

const clientId = process.env.REACT_APP_BOOKCICLE_CLIENT_ID;

const styles = () => ({
  message: {
    padding: 10,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: 'black'
    }
  },
  textField: {
    width: '100%',
    margin: 0,
  },
  button: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContainer: {
    cursor: 'pointer',
    width: '95%',
    margin: '0 auto',
    '@media (max-width:500px)': {
      width: '100%',
      margin: 0,
      padding: 0
    }
  },
  whiteColor: {
    color: 'white'
  }
});

class Login extends React.PureComponent {
  state = {
    show: false,
    show2: false,
    show3: false,
    next: null,
    facebook: false,
    google: false,
    facebookEmail: null,
    facebookRes: null,
    showFacebookForm: false,
    isFacebookBrowser: false,
    email: null,
    password: null,
    showEmailForm: false,
    name: null,
  };

  componentDidMount() {
    this._mounted = true;
    if (isFacebookApp()) {
      this.setState({ isFacebookBrowser: true });
    }
    const parsed = QueryString.parse(window.location.search);
    if (parsed && parsed.path && parsed.alert) {
      this.setState({
        next: parsed.path,
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.auth !== prevProps.auth) {
      window.setTimeout(()=>{
        if (this._mounted) {
          this.setState({ show2: true });
        }
      }, 10000);
      window.setTimeout(()=>{
        if (this._mounted) {
          this.setState({ show3: true });
        }
      }, 20000);
      window.setTimeout(()=>{
        if (this._mounted) {
          this.setState({ show: true });
        }
      }, 30000);
    }
  }

  handleGoogleLoginSuccess = (res, provider) => {
    // eslint-disable-next-line
    if (this._mounted) {
      this.setState({ [ provider ]: true });
    }
    const version = !this.props.version || this.props.version === 'login' ? 'login' : 'sign-up';
    this.props.doLogin(version, res, this.state.next, provider);
  };

  handleGoogleFailure = (err) => {
    this.props.doLoginFailure(err);
  };

  handleFacebookLoginSuccess = (res, provider) => {
    if (this._mounted) {
      this.setState({ [ provider ]: true });
    }
    const version = !this.props.version || this.props.version === 'login' ? 'login' : 'sign-up';
    if (version === 'sign-up') {
      if (this._mounted) {
        this.setState({ [ provider ]: true, facebookRes: res, facebookEmail: res.email, showFacebookForm: true });
      }
    }
    else{
      this.props.doLogin(version, res, this.state.next, 'facebook');
    }
  };

  handleFacebookSubmit = () => {
    if (this._mounted) {
      this.setState({ showFacebookForm: false });
    }
    const version = !this.props.version || this.props.version === 'login' ? 'login' : 'sign-up';
    const res = this.state.facebookRes;
    res.email = this.state.facebookEmail;
    this.props.doLogin(version, res, this.state.next, 'facebook');
  };

  handleEmailSubmit = () => {
    if  (this._mounted) {
      this.setState({ showEmailForm: false });
    }
    const version = !this.props.version || this.props.version === 'login' ? 'login' : 'sign-up';
    const  { name, email,  password } = this.state;
    if (validateEmail(email) && validatePassword(password)) {
      this.props.doLogin(version, createJwt(email, password, name), this.state.next, 'bookcicle');
    }
  };

  handleCloseBtn = () =>{
    this.props.doClear();
    if (this._mounted) {
      this.setState({ facebook: false, google: false, showFacebookForm: false, facebookEmail: null, facebookRes: null });
    }
    this.props.onClose();
  };

  toggleEmailForm = () => {
    this.setState({ showEmailForm: !this.showEmailForm });
  };

  render() {
    const { open, loginError, classes, auth, loggingIn, version, resettingPassword, resetPasswordError, resetPasswordSuccess } = this.props;
    const { show, show2, show3, showFacebookForm, facebookEmail, showEmailForm, google, email, password, name } = this.state;
    return <Dialog onClose={ ()=>{} } maxWidth={ auth && auth.newUser ? 'sm' : 'xs' } fullWidth={ true } aria-labelledby="simple-dialog-title" open={ open } >
      <div>
        {!showFacebookForm && !loggingIn && !auth  && !resetPasswordSuccess && <Tabs
          value={ version && version === 'sign-up' ? 1 : 0 }
          onChange={ (e, x) => {
            this.props.doClearError();
            if (x === 0) {
              this.props.changeTab('login');
            }
            else{
              this.props.changeTab('sign-up');
            }
          } }
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Login" />
          <Tab label="Sign Up" />
        </Tabs>}
        <List>
          {loginError && <ListItem>
            <BookcicleErrorAlert error={ loginError } variant="error" />
          </ListItem>}
          {resetPasswordError && <ListItem>
            <BookcicleErrorAlert error={ resetPasswordError } variant="error" />
          </ListItem>}
          {!loggingIn && auth && auth.newUser && google && <ListItem>
            <ListItemIcon><CheckIcon/></ListItemIcon>
            <ListItemText primary="You're Awesome!" secondary="Welcome to Bookcicle! Hang on a moment as we set up your new account."/>
          </ListItem>
          }
          {!loggingIn && auth && auth.newUser && show2 && google && <ListItem>
            <ListItemIcon><CheckAll/></ListItemIcon>
            <ListItemText secondary="Thanks for stopping in. We are so happy that you did.
             Be sure to join our mailing lists to stay up to date as we get new authors, services and features on board for you."/>
          </ListItem>
          }
          {!loggingIn && auth && auth.newUser && show3 && google && <ListItem>
            <ListItemIcon><CheckBold/></ListItemIcon>
            <ListItemText secondary="Just about done. Getting things ready . . ."/>
          </ListItem>
          }
          {!loggingIn && auth && auth.newUser && show && google && <ListItem>
            <div style={ { margin: '0 auto' } }>
              <Button onClick={ this.handleCloseBtn }>Close</Button>
            </div>
          </ListItem>
          }
          {!loggingIn && auth && auth.newUser && !show && google && <ListItem>
            <LinearDeterminate/>
          </ListItem>
          }
          {!loggingIn && auth && auth.newUser && auth.notValidated && <ListItem>
            <ListItemIcon><CheckIcon/></ListItemIcon>
            <ListItemText primary="You're Awesome!" secondary="Welcome to Bookcicle! You should get an email in the next few minutes with a link to validate and activate your account."/>
          </ListItem>
          }
          {!loggingIn && auth && !auth.newUser && auth.notValidated && <ListItem>
            <ListItemIcon><CheckIcon/></ListItemIcon>
            <ListItemText primary="You're account is not active!" secondary="You should have received an email with a link to validate and activate your account, we will send another for you, keep an eye out."/>
          </ListItem>
          }
          {(!auth || !auth.newUser) && <div>
            {!showFacebookForm && !showEmailForm && !this.state.isFacebookBrowser && <ListItem>
              <div className={ classes.buttonContainer }>
                <GoogleLogin
                  style={ { width: '100%', backgroundColor: 'transparent', padding: 0 } }
                  clientId={ clientId }
                  onSuccess={ (res) => this.handleGoogleLoginSuccess(res, 'google') }
                  onFailure={ this.handleGoogleFailure }
                  disabled={ this.props.loggingIn }
                  render={ renderProps => (
                    <List className="google-btn" dense onClick={ () => {this.props.doClearError(); return renderProps.onClick();} } >
                      {(!loggingIn || (loggingIn && !this.state.google)) && <ListItem>
                        <ListItemIcon><FontAwesomeIcon style={ { margin: 5, color: 'white' } } icon={ faGoogle } size="sm" /></ListItemIcon>
                        <ListItemText classes={ { primary: classes.whiteColor } } primary={ !version || version === 'login' ? 'Login with Google' : 'Sign Up with Google' } />
                      </ListItem>}
                      {loggingIn && this.state.google &&
                      <ListItem>
                        <ListItemIcon><FontAwesomeIcon icon={ faSyncAlt } spin size="sm" /></ListItemIcon>
                        <ListItemText primary="Logging in" />
                      </ListItem>
                      }
                    </List>
                  ) }
                />
              </div>
            </ListItem>}
            {!showFacebookForm && !showEmailForm && <ListItem>
              <div className={ classes.buttonContainer }>
                <FacebookLogin
                  appId="337536586967273"
                  fields="name,email,picture"
                  scope="email"
                  isMobile={ false }
                  autoLoad={ false }
                  callback={ (res) => this.handleFacebookLoginSuccess(res, 'facebook') }
                  render={ renderProps => (
                    <List className="facebook-btn" dense onClick={ () => {this.props.doClearError(); return renderProps.onClick();} }>
                      {(!loggingIn || (loggingIn && !this.state.facebook)) && <ListItem>
                        <ListItemIcon><FontAwesomeIcon style={ { margin: 5, color: 'white' } } icon={ faFacebook } size="sm" /></ListItemIcon>
                        <ListItemText classes={ { primary: classes.whiteColor } } primary={ !version || version === 'login' ? 'Login with Facebook' : 'Sign Up with Facebook' }/>
                      </ListItem>}
                      {loggingIn && this.state.facebook &&
                      <ListItem>
                        <ListItemIcon><FontAwesomeIcon icon={ faSyncAlt } spin size="sm" /></ListItemIcon>
                        <ListItemText primary="Logging in" />
                      </ListItem>
                      }
                    </List>
                  ) }
                />
              </div>
            </ListItem>}
            {showEmailForm && version && version === 'sign-up' && <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={ faUser } /></ListItemIcon>
              <ListItemText primary={ <TextField
                error={ !name ||  (name && name.length <  3) || (name && !name.includes(' ')) }
                id="outlined-name-1"
                label="Name"
                className={ classes.textField }
                value={ name || '' }
                onChange={ (event) => {this.setState({ name: event.target.value });} }
                margin="normal"
                variant="outlined"
                inputProps={ { tabIndex: '0' } }
              /> } />
            </ListItem>}
            {showEmailForm && !resetPasswordSuccess && <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={ faEnvelope } /></ListItemIcon>
              <ListItemText primary={ <TextField
                error={ !validateEmail(email) }
                id="outlined-email-1"
                label="Email"
                className={ classes.textField }
                value={ email || '' }
                onChange={ (event) => {this.setState({ email: event.target.value });} }
                margin="normal"
                variant="outlined"
                inputProps={ { tabIndex: '1' } }
              /> } />
            </ListItem>}
            {showEmailForm && !resettingPassword && !resetPasswordError && resetPasswordSuccess && <ListItem>
              <ListItemIcon><CheckIcon/></ListItemIcon>
              <ListItemText primary="Got it!" secondary="You should get an email in the next few minutes with a link to create a new password."/>
            </ListItem>}
            {showEmailForm && !resettingPassword && !resetPasswordSuccess && <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={ faKey } /></ListItemIcon>
              <ListItemText primary={ <TextField
                error={ !validatePassword(password) }
                id="outlined-password-1"
                label="Password"
                className={ classes.textField }
                value={ password || '' }
                onChange={ (event) => {this.setState({ password: event.target.value });} }
                margin="normal"
                variant="outlined"
                type="password"
                inputProps={ { tabIndex: '2' } }
              /> } secondary={ !validatePassword(password) && <span>At least 8 characters, with at least one lowercase, upper case, number and special character (!@#$%^&*=+).</span> }/>
            </ListItem>}
            {!showFacebookForm && !showEmailForm && <ListItem><div className={ classes.buttonContainer } style={ { backgroundColor: 'grey' } }><List className="email-btn" dense onClick={ () => { this.props.doClearError(); this.toggleEmailForm();} }>
              {(!loggingIn || (loggingIn && !this.state.email)) && <ListItem>
                <ListItemIcon><FontAwesomeIcon style={ { margin: 5, color: 'white' } } icon={ faEnvelopeSquare } size="sm" /></ListItemIcon>
                <ListItemText classes={ { primary: classes.whiteColor } } primary={ !version || version === 'login' ? 'Login with Email' : 'Sign Up with Email' }/>
              </ListItem>}
              {loggingIn && this.state.email &&
              <ListItem>
                <ListItemIcon><FontAwesomeIcon icon={ faSyncAlt } spin size="sm" /></ListItemIcon>
                <ListItemText primary="Logging in" />
              </ListItem>
              }
            </List></div></ListItem>}
            {showFacebookForm && <ListItem>
              <TextField
                error={ !validateEmail(facebookEmail) }
                id="outlined-email"
                label="Email"
                className={ classes.textField }
                value={ facebookEmail }
                onChange={ (event) => {this.setState({ facebookEmail: event.target.value });} }
                margin="normal"
                variant="outlined"
              />
            </ListItem>}
            {showFacebookForm && <ListItem>
              <div style={ { margin: '0 auto' } }>
                <Button disabled={ !validateEmail(facebookEmail) } variant="contained" color="primary" onClick={ this.handleFacebookSubmit }>Confirm Email</Button>
              </div>
            </ListItem>}
            {showEmailForm && (!version || version === 'login') && !resetPasswordSuccess && <ListItem>
              <ListItemIcon><FontAwesomeIcon icon={ faPowerOff } /></ListItemIcon>
              <ListItemText secondary={ <span>Forgot your password?</span> } />
              <ListItemSecondaryAction><Button tabIndex={ -1 } color={ 'primary' } disabled={ (!validateEmail(email) || resettingPassword) } onClick={ () => this.props.doPasswordReset(email) }>{!resettingPassword ? 'Reset it!' : <Loader/>}</Button></ListItemSecondaryAction>
            </ListItem>}
            {showEmailForm && version === 'sign-up' && <ListItem>
              <div style={ { margin: '0 auto' } }>
                <Button disabled={ (!validateEmail(email) || !validatePassword(password) || (name && name.length < 3)) } variant="contained" color="primary" onClick={ this.handleEmailSubmit }>Sign Up</Button>
              </div>
            </ListItem>}
          </div>}
          {!showEmailForm && version && version === 'sign-up' && (!auth || !auth.newUser) &&
          <ListItem>
            <ListItemIcon><InfoIcon/></ListItemIcon>
            <ListItemText secondary="You will be sharing your name, email, and profile picture to Bookcicle from your authentication provider."/>
          </ListItem>}
          {auth && auth.notValidated &&
          <ListItem>
            <div style={ { margin: '0 auto' } }>
              <Button onClick={ () => {
                this.props.doLogout();
                this.handleCloseBtn();
              } } >Close</Button>
            </div>
          </ListItem>}
          {resetPasswordSuccess &&
          <ListItem>
            <div style={ { margin: '0 auto' } }>
              <Button onClick={ () => {
                this.props.doClear();
                this.handleCloseBtn();
              } } >Close</Button>
            </div>
          </ListItem>}
        </List>
      </div>
      {!resetPasswordSuccess &&<DialogActions>
        {!auth && !loggingIn &&
          <div>
            <Button tabIndex={ -1 } onClick={ this.handleCloseBtn } >Cancel</Button>
          </div>}
        {showEmailForm && (!version || version === 'login') &&
          <div>
            <Button disabled={ (!validateEmail(email) || !validatePassword(password)) } variant="contained" color="primary" onClick={ this.handleEmailSubmit }>Login</Button>
          </div>}
      </DialogActions>}
    </Dialog>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Login));