import React from 'react';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ContactIcon from '@material-ui/icons/ContactPhone';
import Connect from '../components/Connect';
import withStyles from '@material-ui/core/styles/withStyles';
import { theme } from '../theme';
import green from '@material-ui/core/colors/green';

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
  },
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: green[ 500 ],
  },
  paper: {
    minHeight: 300,
    maxWidth: 500,
  },
  contact: {
    minWidth: 275,
    maxWidth: 500,
    minHeight: 300,
  }
});

function Contact(props) {
  const { classes } = props;
  return(<div>
    <header className="major">
    </header>
    <header className="major">
      <Grid container className={ classes.root } spacing={ 16 }>
        <Grid item xs={ 12 }>
          <Grid container className={ classes.demo } justify="center" spacing={ 24 }>
            <Grid item >
              <Paper className={ classes.paper }>
                <List style={ { paddingBottom: 0 } }>
                  <ListItem>
                    <Avatar style={ { backgroundColor: '#1DE9B6' } }>
                      <ContactIcon key={ 'contact' }/>
                    </Avatar>
                    <ListItemText primary={ <Typography variant="h6" component="span">Connect</Typography> }/>
                  </ListItem>
                  <Divider/>
                  <Connect key="contact" showSecondary={ true }/>
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </header>
  </div>);
}
export default withStyles(styles)(Contact);