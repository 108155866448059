import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import DocsGridWrapper from '../../../../components/documentation/DocsGridWrapper';
import { theme } from '../../../../theme';
import { WIDGETS_COLOR, MESSENGER_DEMO, TEXT_DEMO, REPORT_DEMO, WIDGETS, SOUND_DEMO } from './metadata';

import Messenger from '../../../../components/widgets/messenger/Messenger';
import PostItNote from '../../../../components/widgets/paperWidgets/PostItNote';
import TextWidget from '../../../../components/widgets/paperWidgets/TextWidget';
import ImageWidget from '../../../../components/widgets/media/ImageWidget';
import GifWidget from '../../../../components/widgets/media/GIFWidget';
import VideoWidget from '../../../../components/widgets/media/VideoWidget';
import SoundWidget from '../../../../components/widgets/media/SoundWidget';
import PaperWidget from '../../../../components/widgets/paperWidgets/PaperWidget';
import WidgetsDemo from '../../../../components/widgets/WidgetsDemo';
import TitleBar from '../../../../components/documentation/TitleBar';
import ExpPanel from '../../../../components/ExpPanel';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    height: 400,
    width: 250,
    overflow: 'hidden'
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  content: {
    height: 250,
  }
});

export class Widgets extends React.Component {

  handleWidgetTypeContent = (type) => {
    switch (type.toLowerCase()) {
    case 'divider':
      return  <div> I am example text.
        <Divider variant="middle" style={ { paddingTop: 2, marginTop: 20, marginBottom: 20 } }/>
        There, I have been divided by a divider line!!</div>;
    case 'gif':
      return <GifWidget src="https://giphy.com/embed/iHu2wbkr4XDbO"/>;
    case 'image':
      return <ImageWidget src="https://images.bookcicle.com/images/chris-lawton-262660-unsplash.jpg"/>;
    case 'messenger':
      return <Messenger messenger={ MESSENGER_DEMO } smallView={ true } />;
    case 'note':
      return <PostItNote size="small" variant="yellow" message="I'm a note. I can be many colors. :)"/>;
    case 'report':
      return <PaperWidget
        title={ REPORT_DEMO.title }
        subtitle={ REPORT_DEMO.subtitle }
        footerSubtitle={ REPORT_DEMO.footerSubtitle }
        footerTitle={ REPORT_DEMO.footerTitle }
        alignHeader="left"
        widgets={ REPORT_DEMO.widgets }/>;
    case 'sound':
      return <SoundWidget src={ SOUND_DEMO.src } controls={ SOUND_DEMO.controls } autoPlay={ SOUND_DEMO.autoPlay } loop={ false } />;
    case 'text':
      return <div>
        Bookcicle Quid est frigus?
        <TextWidget
          text={ TEXT_DEMO.text }
          isBold={ TEXT_DEMO.isBold }
          isItalic={ TEXT_DEMO.isItalic }
          size={ TEXT_DEMO.size }
          fontFamily={ TEXT_DEMO.fontFamily }
          color={ TEXT_DEMO.color }
          justify={ TEXT_DEMO.justify }
          align={ TEXT_DEMO.align }
        />
        Do tibi partem platfrom omnis censes? Assentimus super.
      </div>;
    case 'video':
      return <VideoWidget src="https://player.vimeo.com/video/122375452?title=0&byline=0&portrait=0"/>;
    case 'converter':
      return <div style={ { marginTop: 30 } }>Let readers dictate what unit of measurement they are comfortable with. You can show "seven pounds" to imperial readers, or "three kilograms" to metric readers.</div>;
    default:
      return <Typography component="span"></Typography>;
    }
  };

  render() {
    const { classes } = this.props;
    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Widgets" icon={ DOCS[ 1 ].icon } color={ DOCS[ 1 ].avatarColor } />

        <DocsGridWrapper
          header={ <Typography variant="subtitle1" style={ { display: 'inline' } }>
            <b>Bookcicle Widgets </b>
          </Typography> }
          body={ <Typography component="p" style={ { display:'inline' } }>
            help enrich the reader experience by delivering specialized content, as it's meant to be seen, no matter the device.
            Allowing you to incorporate representations of modern tools and apps.
            Or embed video, pictures, gifs, and sound to fully engross your readers.
          </Typography> }
        />

        <DocsGridWrapper
          elevation={ 0 }
          customStyle="panel"
          body={ <ExpPanel header="Widgets demo" secondaryHeading="With a mock manuscript" content={ <WidgetsDemo/> } /> }
        />

        <DocsGridWrapper
          elevation={ 0 }
          customStyle="panel"
          body={ <ExpPanel header="How to?" secondaryHeading="Using Bookcicle Widgets" content={ <div style={ { width: '60%', margin: '0 auto' } }>
            <Typography variant="body1">
              When writing your stories it's easy to include Bookcicle widgets, just create, copy and paste!

              <br/><br/>There are two general types of widgets. Widgets that need to be placed on newlines and widgets
              that can be pasted in-line. In-line widgets is a widget that you can put virtually anywhere in your manuscript,
              such as the converter widget. All new-line widgets must be placed on a new paragraph line and must end with a
              new paragraph line, they are essentially their own paragraph.

              <br/><br/>Don't worry, we make it really easy, you can learn about, create, and copy a block of Bookcicle JSON from
              any of our widget pages and paste it right into your manuscript where you want that widget to appear to readers on Bookcicle.com.
            </Typography>
          </div> } /> }
        />

        <Grid container className={ classes.root } spacing={ 16 }>
          <Grid item xs={ 12 }>
            <Grid container className={ classes.demo } justify="center" spacing={ 24 }>
              {WIDGETS.map((widget, index) => (
                <Grid key={ index } item>
                  <Paper className={ classes.paper } style={ { backgroundColor: widget.isActive ? '#FFFFFF' : '#EEEEEE' } }>
                    <List style={ { paddingBottom: 0 } }>
                      {widget.isActive && <Link to={ `/create/widgets/${ widget.type.toLowerCase() }` } >
                        {WidgetCard(widget, classes, this.handleWidgetTypeContent)}
                      </Link>}
                      {!widget.isActive && WidgetCard(widget, classes, this.handleWidgetTypeContent)}
                    </List>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
Widgets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Widgets);

function WidgetCard(widget, classes, handleWidgetTypeContent) {
  return (<div>
    <ListItem>
      <Avatar style={ { backgroundColor: WIDGETS_COLOR } }>
        {widget.icon}
      </Avatar>
      <ListItemText primary={ <Typography variant="h6" component="span">{widget.type}</Typography> }/>
    </ListItem>
    <Divider/>
    <div className={ classes.content }>
      {handleWidgetTypeContent(widget.type)}
    </div>
  </div>);
}