import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DocsGridWrapper from './DocsGridWrapper';

export function DocsDesc(props) {
  const { header, desc } = props;
  return (
    <DocsGridWrapper
      header={ <Typography variant="subtitle1" style={ { display: 'inline' } }>
        <b>{header} </b>
      </Typography> }
      body={ <Typography component="p" style={ { display:'inline' } }>
        {desc}
      </Typography> }
    />
  );
}
DocsDesc.propTypes = {
  header: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
};

export default DocsDesc;