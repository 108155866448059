import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faHandHoldingUsd, faPlus } from '@fortawesome/free-solid-svg-icons';

export function PublishingSectionOne() {
  return(
    <section className="spotlight">
      <div className="image"><img src="https://images.bookcicle.com/images/pic01_plus.png" alt="loading..."/></div>
      <div className="content">
        <h3>More Money Back</h3>
        <p>Bookcicle knows that authors are the most important contributor to creating
          a thriving book platform.
          We were built by authors for authors and want to provide you with top of
          industry opportunity in both royalties and ad sharing.</p>
        <ul className="icons major">
          <li><FontAwesomeIcon icon={ faCrown } size="3x"/><span
            style={ { fontSize: '2.5em' } }> 80%</span></li>
          <li><FontAwesomeIcon icon={ faPlus } size="2x"/></li>
          <li><FontAwesomeIcon icon={ faHandHoldingUsd } size="3x"/><span
            style={ { fontSize: '2.5em' } }> 30%</span></li>
        </ul>
      </div>
    </section>
  );
}

export function PublishingSectionTwo() {
  return (
    <section className="spotlight">
      <div className="image"><img src="https://images.bookcicle.com/images/people.png" alt="loading..."/></div>
      <div className="content">
        <h3>Reach More People</h3>
        <p>
          We believe that the more people your product reaches, the more success you will have.
          Our platform is always about enabling you to deliver more, faster.
        </p>
      </div>
    </section>
  );
}

export function PublishingSectionThree() {
  return (
    <section className="spotlight">
      <div className="image"><img src="https://images.bookcicle.com/images/modern-devices.png" alt="loading..."/></div>
      <div className="content">
        <h3>Web Reader for all Devices</h3>
        <p>
          Our carefully crafted application is designed to bring a more natural web and mobile
          reading experience with a simple and modern interface. Additionally we created a set of
          tools called Bookcicle widgets that authors can use to enhance story telling.
        </p>
      </div>
    </section>
  );
}