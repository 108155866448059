import React from 'react';
import PropTypes from 'prop-types';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl/FormControl';
import Select from '@material-ui/core/Select/Select';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';

import StickerEmoji from 'mdi-material-ui/StickerEmoji';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import Messenger from '../../../../components/widgets/messenger/Messenger';
import EmojiPicker from 'emoji-picker-react';
import TitleBar from '../../../../components/documentation/TitleBar';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  paper: {
    height: 400,
    width: 250,
  },
  content: {
    height: 250,
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  textField: {
    maxWidth: 400
  },
  textFieldAdd: {
    width: 150,
    margin: 8
  }
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'messenger'
  },
  {
    title: 'displayName',
    desc: 'The name to display in the app bar.',
    values: 'max-length: 25 characters'
  },
  {
    title: 'messages',
    desc: 'An array of message objects.',
    values: '[ message object, ... ] '
  },
  {
    title: 'message object',
    desc: '',
    values: ''
  },
  {
    title: 'from',
    desc: 'Who the message is from.',
    values: 'max-length: 25 characters',
    indent: true
  },
  {
    title: 'message',
    desc: 'The message to display.',
    values: 'max-length: 100 characters',
    indent: true
  },
  {
    title: 'timestamp',
    desc: 'The timestamp to display.',
    values: 'max-length: 25 characters',
    indent: true
  },
  {
    title: 'isOwner',
    desc: 'Is this message from the phone owner?',
    values: 'true | false',
    indent: true
  }
];

export class MessengerDocs extends React.PureComponent {

  state = {
    addFrom: '',
    addTimestamp: '',
    addIsOwner: false,
    addMessage: '',
    labelWidth: 50,
    showEmoji: false,
    messenger: {
      widgetType: 'messenger',
      displayName: 'Andy',
      messages: [
        {
          isOwner: false,
          from: 'Andy',
          message: 'Hi, Does this look like a real messenger?',
          timestamp: 'Today@10:42pm'
        },
        {
          isOwner: true,
          from: 'Tricia',
          message: '❤❤ Sure does!',
          timestamp: 'Just now'
        }
      ]
    }
  };

  onChangeJSON = (json) => {
    this.setState({ messenger: JSON.parse(json) });
  };

  handleChange = name => event => {
    this.setState({
      messenger: { ...this.state.messenger, [ name ]: event.target.value }
    });
  };

  handleChangeAdd = name => event => {
    this.setState({
      [ name ]: name === 'addIsOwner' ? event.target.value === 'true' : event.target.value
    });
  };

  handlePop = () => {
    const messages = this.state.messenger.messages;
    messages.pop();
    this.setState({
      messenger: {
        ...this.state.messenger
      }
    });
  };

  handlePush = () => {
    const messages = this.state.messenger.messages;
    const message = {
      isOwner: this.state.addIsOwner,
      from: this.state.addFrom,
      timestamp: this.state.addTimestamp,
      message: this.state.addMessage
    };
    messages.push(message);
    this.setState({
      messenger: {
        ...this.state.messenger
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { messenger, addFrom, addIsOwner, addMessage, addTimestamp, showEmoji } = this.state;
    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Messenger" icon={ WIDGETS_ICONS[ 3 ] } color={ DOCS[ 1 ].avatarColor }/>

        <DocsDesc header="Messenger New-Line Widget" desc="Use the messenger widget to show text-message and messenger style dialog between two or more characters."/>
        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ messenger } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <List>
                <ListItem>
                  <TextField
                    id="display-name"
                    label="Display Name"
                    fullWidth
                    style={ { margin: 8 } }
                    className={ classes.textField }
                    value={ messenger.displayName }
                    onChange={ this.handleChange('displayName') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <Typography variant="h6">Add Message</Typography>
                <Divider variant="middle" />
                <List>
                  <ListItem>
                    <FormControl variant="outlined" className={ classes.formControl }>
                      <InputLabel
                        htmlFor="isOwner"
                      >
                        Is Owner
                      </InputLabel>
                      <Select
                        native
                        className="margin-10"
                        value={ addIsOwner }
                        onChange={ this.handleChangeAdd('addIsOwner') }
                        input={
                          <OutlinedInput
                            labelWidth={ this.state.labelWidth }
                            name="Is Owner"
                            id="isOwner"
                          />
                        }
                      >
                        <option value={ true }>true</option>
                        <option value={ false }>false</option>
                      </Select>
                    </FormControl>
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="from"
                      label="from"
                      className={ classes.textFieldAdd }
                      value={ addFrom }
                      onChange={ this.handleChangeAdd('addFrom') }
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={ {
                        shrink: true,
                      } }
                    />
                    <TextField
                      id="timestamp"
                      label="timestamp"
                      value={ addTimestamp }
                      className={ classes.textFieldAdd }
                      onChange={ this.handleChangeAdd('addTimestamp') }
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={ {
                        shrink: true,
                      } }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="message"
                      label="message"
                      value={ addMessage }
                      style={ { margin: 8 } }
                      className={ classes.textField }
                      fullWidth
                      onChange={ this.handleChangeAdd('addMessage') }
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={ {
                        shrink: true,
                      } }
                    />
                    <IconButton
                      style={ { height: 48 } }
                      title="emoji selector"
                      onClick={ () => {this.setState({ showEmoji: true });} }
                    >
                      <StickerEmoji title="Emoji Selector"/>
                    </IconButton>
                    <Dialog
                      open={ showEmoji }
                      onClose={ () => {this.setState({ showEmoji: false });} }
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <EmojiPicker onEmojiClick={ (value) => {
                        const message = addMessage.concat(String.fromCodePoint(parseInt(value, 16)));
                        this.setState({ 'addMessage': message });
                      } }/>
                    </Dialog>
                  </ListItem>
                  <Button
                    style={ { padding: '0 10px' } }
                    variant="contained" color="primary" className={ classes.button } onClick={ this.handlePush }>
                    <AddIcon title="add"/> Add Message
                  </Button>
                </List>
                <Typography variant="h6" style={ { marginTop: 20 } }>Messages</Typography>
                <Divider variant="middle" />
                {messenger.messages && messenger.messages.map((msg, index) => {
                  return <ListItem key={ index }>
                    <ListItemText primary={
                      <List>
                        <ListItem>
                          <ListItemText primary={ `${ msg.isOwner }` } secondary={ 'isOwner' }/>
                          <ListItemText primary={ msg.message } secondary={ 'message' }/>
                          <ListItemText primary={ msg.from } secondary={ 'from' }/>
                          <ListItemText primary={ msg.timestamp } secondary={ 'timestamp' }/>
                        </ListItem>
                        <Divider variant="middle" />
                      </List>
                    }/>
                    {messenger.messages.length - 1 === index && <ListItemSecondaryAction>
                      <IconButton
                        style={ { height: 48 } }
                        title="delete message"
                        onClick={ this.handlePop }
                      >
                        <DeleteIcon title="Delete"/>
                      </IconButton>
                    </ListItemSecondaryAction> }
                  </ListItem>;
                })}
              </List>
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <Messenger messenger={ messenger } />
            </Paper>
          </WidgetsDemoGrid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
MessengerDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessengerDocs);