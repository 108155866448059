import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Button from '@material-ui/core/Button/Button';
import CheckIcon from '@material-ui/icons/Check';
import { theme } from '../theme';

const styles = () => ({
  button: {
    padding: '0 10px'
  }
});

export class CopyTextComponent extends React.Component {
  state = {
    copied: false,
  };

  handleCopyAction = (text) => {
    navigator.clipboard.writeText(text);
    this.setState(
      { copied: true },
      () => {
        window.setTimeout(() => {
          this.setState({ copied: false });
        }, 500);
      });
  };

  render() {
    const { text, classes } = this.props;
    const { copied } = this.state;
    return <MuiThemeProvider theme={ theme }><CopyToClipboard text={ text } onCopy={ this.handleCopyAction }>
      <Button variant="contained" color="primary" className={ classes.button }>
        {copied && <CheckIcon/>} Copy
      </Button>
    </CopyToClipboard>
    </MuiThemeProvider>;
  }
}

CopyTextComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired
};
export default withStyles(styles)(CopyTextComponent);