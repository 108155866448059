import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PhoneIcon from '@material-ui/icons/Phone';
import MoreIcon from '@material-ui/icons/MoreVert';
import { theme } from './theme';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

function MockAppBar(props) {
  const { classes, smallView } = props;
  return (
    <MuiThemeProvider theme={ theme }>
      <div className={ classes.root }>
        <AppBar position="static">
          <Toolbar>
            <ArrowBackIcon/>
            <Typography variant={ smallView ? 'h6' : 'h4' } color="inherit" className={ classes.flex } style={ { overflow: 'hidden' } }>
              {props.barTitle}
            </Typography>
            <PhoneIcon/>
            <MoreIcon/>
          </Toolbar>
        </AppBar>
      </div>
    </MuiThemeProvider>
  );
}

MockAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  barTitle: PropTypes.string.isRequired,
  smallView: PropTypes.bool.isRequired
};

export default withStyles(styles)(MockAppBar);