import React from 'react';
import QueryString from 'query-string';
import { updatePassword, validateEmail } from '../reducers/application';
import connect from 'react-redux/src/connect/connect';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Warning from '@material-ui/icons/Warning';
import Loader from '../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import TextField from '@material-ui/core/TextField';
import { validatePassword } from '../utils';
import Button from '@material-ui/core/Button';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { red } from '@material-ui/core/colors';

const mapStateToProps = (state) => ({
  ...state.application
});

const mapDispatchToProps = (dispatch) => {
  return {
    doValidate: (email, token, password) => dispatch(updatePassword(email, token, password))
  };
};

const styles = () => ({
  paperContent: {
    maxWidth:  500,
    margin: 20,
    padding: 20,
    overflowX: 'auto',
  },
});

class ResetEmail extends React.PureComponent {
  state = {
    error: false,
    email: null,
    token: null,
    password: null,
  };

  componentDidMount() {
    const parsed = QueryString.parse(window.location.search);
    if (parsed && parsed.userId && parsed.token) {
      this.setState({ error: false, email: window.atob(parsed.userId), token: parsed.token });
    }
    else {
      this.setState({ error: true });
    }
  }

  render() {
    const { error, email, password, token } = this.state;
    const { updatingPassword, updatePasswordError, updatePasswordSuccess, classes } = this.props;
    return <div>
      <Grid>
        <Grid item xs={ 12 } >
          <Grid container justify="center" alignItems="center">
            {!updatePasswordSuccess && <Paper className={ classes.paperContent }>
              <Typography variant="h6">Update Password</Typography>
              <List>
                {error && <ListItem>
                  <ListItemIcon><Warning color={ red }/></ListItemIcon>
                  <ListItemText primary="Oops" secondary="Invalid activation code."/>
                </ListItem>}
                {updatePasswordError && <ListItem>
                  <ListItemIcon><Warning/></ListItemIcon>
                  <ListItemText primary="Oops" secondary={ updatePasswordError }/>
                </ListItem>}
                <ListItem>
                  <ListItemIcon><FontAwesomeIcon icon={ faEnvelope } /></ListItemIcon>
                  <ListItemText primary={ <TextField
                    disabled={ updatePasswordSuccess }
                    error={ !validateEmail(email) }
                    id="outlined-email-1"
                    label="Email"
                    className={ classes.textField }
                    value={ email || '' }
                    onChange={ (event) => {this.setState({ email: event.target.value });} }
                    margin="normal"
                    variant="outlined"
                    inputProps={ { tabIndex: '1' } }
                  /> } />
                </ListItem>
                <ListItem>
                  <ListItemIcon><FontAwesomeIcon icon={ faKey } /></ListItemIcon>
                  <ListItemText primary={ <TextField
                    disabled={ updatePasswordSuccess }
                    error={ !validatePassword(password) }
                    id="outlined-password-1"
                    label="Password"
                    className={ classes.textField }
                    value={ password || '' }
                    onChange={ (event) => {this.setState({ password: event.target.value });} }
                    margin="normal"
                    variant="outlined"
                    type="password"
                    inputProps={ { tabIndex: '2' } }
                  /> } secondary={ !validatePassword(password) && <span>At least 8 characters, with at least one lowercase, upper case, number and special character (!@#$%^&*=+).</span> }/>
                </ListItem>
                <ListItem>
                </ListItem>
                <ListItem>
                  <ListItemSecondaryAction>
                    <Button disabled={ (!validateEmail(email) || !validatePassword(password) || !token || updatingPassword) } variant="contained" color="primary" onClick={ () => this.props.doValidate(email, token, password) }>{updatingPassword ? <Loader/> : 'Update'}</Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Paper>}
            {updatePasswordSuccess && <Paper className={ classes.paperContent }>
              <Typography variant="h6">Password Update Success!!</Typography>
              <List>
                <ListItem>
                  <ListItemText primary={ 'Your password has been successfully updated. Try to login now.' } />
                </ListItem>
              </List>
            </Paper>
            }
          </Grid>
        </Grid>
      </Grid>
    </div>;
  }
}export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ResetEmail));