import React from 'react';
import SimpleReader from '../../routes/member/reader/SimpleReader';

import ExampleJson from './docs/example.json';
import { EXAMPLE_HTML } from './docs/example';

import ReactHtmlParser from 'react-html-parser';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

export class WidgetsDemo extends React.PureComponent {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    return(
      <div key="widgets_demo">
        <Tabs
          value={ this.state.value }
          onChange={ this.handleChange }
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Reader" />
          <Tab label="Raw" />
        </Tabs>
        {this.state.value === 0 && <SimpleReader chapter={ ExampleJson } readerFontSize={ 'large' } />}
        {this.state.value === 1 && <div style={ { display: 'flex', justifyContent: 'center' } }><div style={ { margin: 20, textAlign: 'left', maxWidth: 1000 } }>
          <div style={ { width: '50%', margin: '0 auto' } }><a href="https://images.bookcicle.com/documents/example_manu.docx"><Paper style={ { maxWidth: 700, padding: 5, marginBottom: 20, marginTop: 20, textAlign: 'center' } }>Download Example .docx</Paper></a></div>
          <Typography variant="h5">CAPUT Title horribilis </Typography>
          {ReactHtmlParser(EXAMPLE_HTML)}</div></div>}
      </div>
    );
  }
}
export default WidgetsDemo;