import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { Check, CheckAll } from 'mdi-material-ui';

import Toa from '../routes/TOA';
import BookcicleApi from '../bookcicleApi';
import { getAuth } from '../reducers/application';
import { describeUser } from '../reducers/user';

const mapStateToProps = (state) => ({
  ...state.application,
  auth: getAuth(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (auth, fresh) => dispatch(describeUser(auth, fresh)),
  };
};

const styles = () => ({
  paper: {
    padding: 5,
    margin: '20px 0',
    maxHeight: 400,
    overflow: 'auto',
  },
});

export class AcceptTerms extends React.PureComponent {
  state = {
    error: null,
    loading: false,
    accepted: false,
  };

  handleAccept = () => {
    if (this.state.loading || this.state.accepted) {
      return;
    }
    this.setState({
      loading: true,
      error: null,
    }, () => {
      new BookcicleApi({ accessToken: this.props.auth.bookcicleAuth, apiKey: this.props.auth.apiKey }).post({
        path: `user/${ this.props.auth.userId }`,
        queryData: {
          update: 'terms',
          timestamp: Date.now()
        }
      },
      (error) => {
        if (error) {
          console.error(error);
          this.setState({
            error: (typeof error === 'string' || error instanceof String) ? error: error.toString(),
            loading: false
          });
        }
        else {
          this.setState({
            loading: false,
            accepted: true,
            error: null
          }, () => {
            this.props.fetchUser(this.props.auth, true);
            window.setTimeout(() => {
              this.props.handleClose();
              this.props.updateAccepted();
            }, 500);
          });
        }
      });
    });
  };

  render() {
    const { open, handleClose, classes, maxWidth, user, show } = this.props;
    const { loading, accepted } = this.state;
    return <div>
      <Dialog
        fullWidth={ true }
        maxWidth={ maxWidth }
        open={ open }
        onClose={ handleClose }
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Paper className={ classes.paper } elevation={ 0 }>
            <Toa none={ true } />
            <div style={ { display: 'flex', justifyContent: 'center' } }>
              <Button variant="outlined" size="small" color="primary" onClick={ handleClose } > Close </Button>
            </div>
          </Paper>
        </DialogContent>
        {!show && user && !user.info.acceptedTerms && <DialogActions>
          <Button onClick={ this.handleAccept } color="primary" >
            {loading && <Check/>} {accepted && <CheckAll/>} I Accept
          </Button>
        </DialogActions>}
      </Dialog>
    </div>;
  }

} export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AcceptTerms));
