import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import Home from '../routes/Home';
import Documentation from './member/documentation/Documentation';
import Books from './member/Books';
import Book from './member/Book';
import Account from './member/Account';
import Publish from './Publish';
import LoggedOut from './LoggedOut';
import Contact from './Contact';
import ValidateEmail from './ValidateEmail';
import ResetEmail from './ResetEmail';

import TOA from './TOA';
import Privacy from './Privacy';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route { ...rest } render={ (props) => {
    if (isAuthenticated === true) {
      return <Component { ...props } />;
    }
    else {
      return <Redirect to={ {
        pathname: '/',
        search: `?alert=forbidden&alertMsg=Please login to access that&alertType=error&path=${ window.location.pathname }`,
      } }/>;
    }
  } }/>
);

export default class BookcicleRoutes extends React.Component {
  render() {
    return <Switch>
      <Route exact path="/" component={ Home } />
      <Route exact path="/toa" component={ TOA } />
      <Route exact path="/privacy" component={ Privacy }/>
      <Route exact path="/loggedOut" component={ LoggedOut } />
      <Route exact path="/contact" component={ Contact }/>
      <Route exact path="/validate" component={ ValidateEmail } />
      <Route exact path="/reset" component={ ResetEmail } />
      <Route path="/create" component={ Documentation } />

      <PrivateRoute path="/publish" component={ Publish } isAuthenticated={ this.props.isAuthenticated }/>
      <PrivateRoute path="/books" component={ Books } isAuthenticated={ this.props.isAuthenticated }/>
      <PrivateRoute path="/book/:id" component={ Book } isAuthenticated={ this.props.isAuthenticated }/>
      <PrivateRoute path="/account" component={ Account } isAuthenticated={ this.props.isAuthenticated }/>
    </Switch>;
  }
}