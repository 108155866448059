const env = process.env.NODE_ENV;
export function getLocalStorage(key) {
  if(env !== 'test' && localStorage.getItem(key) !== null){
    try {
      return window.atob(localStorage.getItem(key));
    }
    catch (e) {
      return localStorage.getItem(key);
    }
  }
  return null;
}

export function setLocalStorage(key, value) {
  if(env !== 'test'){
    localStorage.setItem(key, window.btoa(JSON.stringify(value)));
  }
}

export function removeLocalStorage(key){
  if(env !== 'test') {
    localStorage.removeItem(key);
  }
}