import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import ReactHtmlParser from 'react-html-parser';

import DocsGridWrapper from '../documentation/DocsGridWrapper';
import ExpPanel from '../../components/ExpPanel';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    width: '100%'
  },
  tableContainer: {
    position: 'relative',
    overflow: 'scroll'
  },
});

export function WidgetsParameters(props) {
  const { parameters } = props;
  const content =
    <div style={ { overflowX: 'scroll' } }>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Values/Limits</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parameters && parameters.map(param => {
            return <TableRow key={ param.title }>
              <TableCell component="th" scope="row" style={ { paddingLeft: param.indent ? 30 : 10 } }>
                {param.title}
              </TableCell>
              <TableCell>{ReactHtmlParser(param.desc)}</TableCell>
              <TableCell>{param.values}</TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
    </div>;

  return (<div style={ { overflow: 'hidden', width: '100%' } }>
    <DocsGridWrapper
      elevation={ 0 }
      customStyle="panel"
      body={ <ExpPanel header="Parameters" content={ content } secondaryHeading="Widget Inputs"/> }
    />
  </div>);
}
WidgetsParameters.propTypes = {
  classes: PropTypes.object.isRequired,
  parameters: PropTypes.array.isRequired
};

export default withStyles(styles)(WidgetsParameters);