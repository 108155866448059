import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Lightbox from 'react-image-lightbox';

const styles = () => ({
  image: {
    width: '100%',
    maxWidth: 700,
    margin: '20px 0'
  }
});

class ImageWidget extends React.PureComponent {
  state = {
    showImage: true,
    open: false,
    currentImage: 0
  };

  handleBrokenImage = () => {
    this.setState({ showImage: false });
  };

  render() {
    const { src, classes } = this.props;
    const { showImage } = this.state;
    const isValid = src.startsWith('https://media.bookcicle.com') || src.startsWith('https://images.bookcicle.com');
    const images = [ src ];
    return <div>
      {isValid && <div style={ { display: showImage ? 'inline' : 'none' } }>
        {this.state.open && <Lightbox
          mainSrc={ images[ 0 ] }
          onCloseRequest={ () => this.setState({ open: false }) }
        />}
        <div onClick={ () => this.setState({ open: true }) } style={ { cursor: 'pointer' } }>
          <img src={ src } alt={ 'Loading...' } className={ classes.image } onError={ this.handleBrokenImage }/>
        </div>
      </div>}
    </div>;
  }
}
ImageWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
};
export default withStyles(styles)(ImageWidget);