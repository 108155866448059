import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';

import { theme } from '../theme';
import ShareModalForm from './ShareModalForm';
import BookcicleApi, { ContentType as CONTENT_TYPE } from '../bookcicleApi';
import BookcicleErrorAlert from './BookcicleErrorAlert';
import Loader from './Loader';
import { getAuth } from '../reducers/application';
import connect from 'react-redux/es/connect/connect';
import Fab from '@material-ui/core/Fab';

const mapStateToProps = (state) => ({
  auth: getAuth(state)
});

const styles = () => ({
  paper: {
    margin: '20px auto',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[ 5 ],
    padding: theme.spacing.unit * 4,
  },
  demo: {
    overflowY: 'scroll',
    maxHeight: 300,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: '30px 20px',
  },
});

class SimpleModal extends React.Component {
  state = {
    error: false,
    emails: [],
    sending: false,
  };

  handleEmailAdd = email => {
    const emails = this.state.emails;
    emails.push(email);
    this.setState({ emails });
  };

  handleRemoveEmail = index => {
    const emails = this.state.emails;
    emails.splice(index, 1);
    this.setState({ emails });
  };

  sendRecommendations = () => {
    const emails = this.state.emails;
    const data = this.props.data;
    new BookcicleApi({ accessToken: this.props.auth.bookcicleAuth, apiKey: this.props.auth.apiKey }).post({
      accept: CONTENT_TYPE.JSON,
      path: `user/${ this.props.auth.userId }/share`,
      data: {
        emails,
        message: data.message,
        subject: data.subject,
        title: data.title
      }
    },
    (error) => {
      if (error) {
        console.error(error);
        this.setState({ error: (typeof error === 'string' || error instanceof String) ? error : error.toString, sending: false });
      }
      else {
        this.setState({
          error: null,
          sending: false,
          emails: [],
        }, () => {
          this.props.handleClose(true);
        });
      }
    });
  };

  render() {
    const { classes, modalTitle } = this.props;
    const { emails, error, sending } = this.state;
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={ this.props.open }
          onClose={ () => {
            this.props.handleClose(false);
          } }
        >
          <div className={ classes.paper }>
            {error && <BookcicleErrorAlert error={ error } variant="error" />}
            <Typography variant="h5" gutterBottom>
              {modalTitle} {sending && <Loader/>}
            </Typography>
            {emails && emails.length > 0 && <div className={ classes.demo }><List>
              {emails.map((email, index) => {
                return <ListItem key={ index } style={ { width: '90%' } }>
                  <ListItemText
                    style={ { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } }
                    primary={ email }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      style={ { height: '50px' } }
                      aria-label="Delete"
                      onClick={ () => {
                        this.handleRemoveEmail(index);
                      } }
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>;
              })}
            </List></div>
            }
            <Typography variant="h4" id="modal-title">
            </Typography>
            <Typography variant="subtitle1" id="simple-modal-description">
              <ShareModalForm
                handleAdd={ this.handleEmailAdd } />
            </Typography>
            <MuiThemeProvider theme={ theme }>
              <div style={ { textAlign: 'center' } }>
                <Fab
                  onClick={ () => {
                    this.setState({ sending: true }, () => {
                      this.sendRecommendations();
                    });
                  } }
                  disabled={ emails.length === 0 || sending }
                  color="primary"
                  aria-label="Add"
                  className={ classes.button }>
                  <SendIcon/>
                </Fab>
              </div>
            </MuiThemeProvider>
          </div>
        </Modal>
      </div>
    );
  }
}

SimpleModal.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  modalTitle: PropTypes.object.isRequired,
};
export default connect(
  mapStateToProps
)(withStyles(styles)(SimpleModal));