import React from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/core/styles/withStyles';
import { checkType } from '../utils';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class ExpPanel extends React.PureComponent {
  state = {
    expanded: null,
  };

  componentDidMount() {
    if(this.props.expanded) {
      this.setState({ expanded: 'panel1' });
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { expanded } = this.state;
    const { header, content, secondaryHeading, classes } = this.props;
    return <ExpansionPanel expanded={ expanded === 'panel1' } onChange={ this.handleChange('panel1') } key={ header } >
      <ExpansionPanelSummary expandIcon={ <ExpandMoreIcon /> }>
        {checkType(header) === 'String' && <Typography className={ classes.heading } >{header}</Typography>}
        {checkType(header) === 'Object' && <div style={ { margin: 10 } }>{header}</div>}
        {secondaryHeading && <Typography className={ classes.secondaryHeading } >{secondaryHeading}</Typography>}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div style={ { width: '99%', marginRight: '0 auto' } }>
          {content}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>;
  }
}
ExpPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  content: PropTypes.object.isRequired,
  secondaryHeading: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ])
};
export default withStyles(styles)(ExpPanel);