import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import Calendar from 'mdi-material-ui/Calendar';
import Earth from 'mdi-material-ui/Earth';
import Pencil from 'mdi-material-ui/Pencil';
import Alert from 'mdi-material-ui/Alert';
import Star from 'mdi-material-ui/Star';
import TagHeart from 'mdi-material-ui/TagHeart';
import BookOpen from 'mdi-material-ui/BookOpen';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import Web from 'mdi-material-ui/Web';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';

import Loader from '../../components/Loader';
import TitleBar from '../../components/documentation/TitleBar';
import Avatar from '@material-ui/core/Avatar';
import { getAuth } from '../../reducers/application';
import { describeChapters } from '../../reducers/book';
import connect from 'react-redux/src/connect/connect';
import ExpPanel from '../../components/ExpPanel';
import Typography from '@material-ui/core/Typography';

const mapStateToProps = (state) => ({
  ...state.book,
  auth: getAuth(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChapters: (auth, bookId) => dispatch(describeChapters(auth, bookId))
  };
};

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  chevron: {
    margin: '0 10px'
  },
  chapters: {
    maxHeight: 500,
    overflow: 'auto'
  }
});

class BookDetailsCard extends React.PureComponent {
    state = {
      anchorEl: null,
    };

    componentDidMount() {
      if (this.props.showChapters) {
        this.props.fetchChapters(this.props.auth, this.props.bookDetails.bookId);
      }
    }

    render() {
      const { classes, loading, loadingChapters, bookChapters, chaptersError } = this.props;
      const bookDetails = this.props.bookDetails;
      const { bookId, created } = bookDetails;
      const { rating, dedication, publishingCo, languages, awards, website, editors } = bookDetails.details;
      return (
        <div>
          <Card className={ classes.card }>
            <TitleBar color="#448AFF" title="Book Details" icon={ <InfoIcon/> }/>
            <CardContent>
              {loading && <Loader/>}
              {!loading &&
              <div>
                <div className={ classes.root }>
                  <List>
                    <ListItem>
                      <Tooltip title="Book Rating">
                        <ListItemIcon>
                          <Alert/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ rating }/>
                    </ListItem>

                    <ListItem>
                      <Tooltip title="Language">
                        <ListItemIcon>
                          <Earth/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ languages }/>
                    </ListItem>
                    <ListItem>
                      <Tooltip title="Date Published">
                        <ListItemIcon>
                          <Calendar/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ new Date(created * 1000).toLocaleDateString('en-US') }
                      />
                    </ListItem>
                    {awards &&
                    <ListItem>
                      <Tooltip title="Awards/Recognitions">
                        <ListItemIcon>
                          <Star/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ awards }
                      />
                    </ListItem>
                    }
                    {website &&
                    <ListItem>
                      <Tooltip title="Author Website">
                        <ListItemIcon>
                          <Web/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ website }
                      />
                    </ListItem>
                    }
                    {dedication &&
                    <ListItem>
                      <Tooltip title="Dedication">
                        <ListItemIcon>
                          <TagHeart/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ dedication }
                      />
                    </ListItem>
                    }
                    {editors &&
                      <ListItem>
                        <Tooltip title="Editors">
                          <ListItemIcon>
                            <Pencil/>
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                          inset
                          primary={ editors }
                        />
                      </ListItem>
                    }
                    <ListItem>
                      <Tooltip title="Bookcicle ID">
                        <ListItemIcon>
                          <BookOpen/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ bookId }
                      />
                    </ListItem>
                    <ListItem>
                      <Tooltip title="Publishing">
                        <ListItemIcon>
                          <OfficeBuilding/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        inset
                        primary={ publishingCo }
                      />
                    </ListItem>
                  </List>
                  {this.props.showChapters && <ExpPanel header={ <List>
                    <ListItem>
                      <ListItemIcon>
                        <Avatar style={ { backgroundColor: '#e040fb' } }><BookmarkIcon/></Avatar>
                      </ListItemIcon>
                      <ListItemText primary="View Chapters"/>
                    </ListItem>
                  </List> } content={ <div>
                    {loadingChapters && <List>
                      <ListItem><Loader/></ListItem>
                    </List>}
                    {!loadingChapters && !chaptersError && bookChapters && <List className={ classes.chapters }>
                      {bookChapters.map((x, index) => <ListItem key={ index }>
                        <ListItemIcon><Typography>{index + 1}</Typography></ListItemIcon>
                        <ListItemText primary={ x.title } secondary={ x.subtitle }/>
                      </ListItem>)}
                    </List>}
                  </div> } />}
                </div>
              </div>
              }
            </CardContent>
          </Card>
        </div>
      );
    }
}

BookDetailsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  bookDetails: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(BookDetailsCard));