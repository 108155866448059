import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import connect from 'react-redux/es/connect/connect';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Home from '@material-ui/icons/Home';

import Avatar from '@material-ui/core/Avatar';
import { isMobile } from 'react-device-detect';

import DocsRoutes from './DocsRoutes';
import { WIDGETS } from './widgets/metadata';
import { PUB_ROUTES } from './publishing/metadata';
import { DOCS } from './metadata';
import { AppBarNavItems } from '../../../components/BookcicleAppBar';

import {
  getAppBarState,
  getAuth,
  toggleAppBarState,
  logout,
  showLoginModal,
} from '../../../reducers/application';

const mapStateToProps = (state) => ({
  ...state.application,
  auth: getAuth(state),
  showAppBar: getAppBarState(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    showLogin: (val) => dispatch(showLoginModal(val)),
    toggleAppBar: () => dispatch(toggleAppBarState()), logout: () => dispatch(logout()) };
};
const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${ drawerWidth }px)`,
    transition: theme.transitions.create([ 'width', 'margin' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    height: 50
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [ theme.breakpoints.up('sm') ]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  button: {
    height: 50
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
});

const MENU_LINKS = [
  'widgetsOpen',
  'adsOpen',
  'toolsOpen',
  'publishOpen'
];

class Documentation extends React.Component {
  state = {
    widgetsOpen: false,
    adsOpen: false,
    toolsOpen: false,
    drawerOpen: false,
    publishOpen: false,
    anchorEl: null
  };

  handleOpen = (name) => {
    MENU_LINKS.forEach(menu => {
      if (name !== menu) {
        this.setState({ [ menu ] : false });
      }
    });
    this.setState({ [ name ]: true });
  };

  handleClose = (name) => {
    this.setState({ [ name ]: false });
  };

  componentDidMount() {
    this.props.toggleAppBar();
  }

  componentWillUnmount() {
    this.props.toggleAppBar();
  }

  render() {
    const { classes, theme } = this.props;
    const { drawerOpen, widgetsOpen, adsOpen, toolsOpen, publishOpen } = this.state;
    const { signingOut } = this.props;

    return (
      <div className={ classes.root }>
        <CssBaseline />
        <MuiThemeProvider theme={ theme }>
          <AppBar
            color={ 'default' }
            position="fixed"
            className={ classNames(classes.appBar, {
              [ classes.appBarShift ]: drawerOpen,
            }) }
          >
            <Toolbar disableGutters={ !drawerOpen }>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={ () => this.handleOpen('drawerOpen') }
                className={ classNames(classes.menuButton, {
                  [ classes.hide ]: drawerOpen,
                }) }
              >
                <MenuIcon />
              </IconButton>
              <Link to="/"><Avatar alt="Bookcicle" src="https://images.bookcicle.com/images/bookcicle.png" style={ {
                display: 'inline-block',
                float: 'left'
              } }/></Link>
              <Typography variant="h5" color="inherit" className={ classes.flex }>
                <Link to="/" style={ { color: '#008cba', marginLeft: 3, fontFamily: 'Playfair Display' } }>{isMobile ? '' : 'Bookcicle'}</Link>
              </Typography>
              <div style={ { position: 'fixed', right: 24, display: drawerOpen ? 'none' : 'inline-block' } }>
                <AppBarNavItems
                  logout={ this.props.logout }
                  signingOut={ signingOut }
                  showLoginModal={ this.props.showLogin }
                  showAppBar={ true }
                  classes={ classes }
                />
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant={ isMobile ? 'temporary' : 'permanent' }
            className={ classNames(classes.drawer, {
              [ classes.drawerOpen ]: drawerOpen,
              [ classes.drawerClose ]: !drawerOpen,
            }) }
            classes={ {
              paper: classNames({
                [ classes.drawerOpen ]: drawerOpen,
                [ classes.drawerClose ]: !drawerOpen,
              }),
            } }
            open={ drawerOpen }
          >
            <div className={ classes.toolbar }>
              <IconButton onClick={ () => this.handleClose('drawerOpen') } style={ { height: 50 } }>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              <Link to="/create">
                <ListItem button key="home">
                  <ListItemIcon><Home/></ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
              </Link>
            </List>
            <Divider />

            {DOCS.filter(doc => doc.isActive === true).map((doc, index) => {
              let openWidget;
              let color;
              switch (doc.type) {
              case 'publish':
                color = '#9C27B0';
                openWidget = publishOpen;
                break;
              case 'widgets':
                color = '#2196F3';
                openWidget = widgetsOpen;
                break;
              case 'ads':
                color = DOCS[ 2 ].avatarColor;
                openWidget = adsOpen;
                break;
              case 'tools':
                color = DOCS[ 3 ].avatarColor;
                openWidget = toolsOpen;
                break;
              default:
                openWidget = widgetsOpen;
              }
              return <List key={ index }>
                <Link to={ `/create/${ doc.type }` }>
                  <ListItem button onClick={ () => {openWidget ? this.handleClose(`${ doc.type }Open`) : this.handleOpen(`${ doc.type }Open`);} }>
                    <ListItemIcon style={ { color: color } }>
                      {doc.icon}
                    </ListItemIcon>
                    <ListItemText inset primary={ doc.title } />
                  </ListItem>
                </Link>
                <CollapsedLinks open={ openWidget } type={ doc.type } classes={ classes }/>
              </List>;
            })}
          </Drawer>
          <main className={ classes.content }>
            <div className={ classes.toolbar } />
            <DocsRoutes />
          </main>
        </MuiThemeProvider>
      </div>
    );
  }
}

Documentation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Documentation));

function CollapsedLinks(props) {
  const { open, type, classes } = props;
  switch (type) {
  case 'publish':
    return <Collapse in={ open } timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {PUB_ROUTES.filter(widget => widget.isActive === true && widget.isLink === true).map((widget) => {
          return <Link to={ `/create/publish/${ widget.path }` } key={ widget.type }><ListItem button className={ classes.nested } >
            <ListItemIcon>
              {widget.icon}
            </ListItemIcon>
            <ListItemText inset primary={ widget.type }/>
          </ListItem></Link>;
        })
        }
      </List>
    </Collapse>;
  case 'widgets':
    return <Collapse in={ open } timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {WIDGETS.filter(widget => widget.isActive === true).map((widget) => {
          return <Link to={ `/create/widgets/${ widget.type.toLowerCase() }` } key={ widget.type }><ListItem button className={ classes.nested } >
            <ListItemIcon>
              {widget.icon}
            </ListItemIcon>
            <ListItemText inset primary={ widget.type }/>
          </ListItem></Link>;
        })
        }
      </List>
    </Collapse>;
  case 'ads':
    return <Collapse in={ open } timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItem button className={ classes.nested }>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          <ListItemText inset primary="Ad's" />
        </ListItem>
      </List>
    </Collapse>;
  case 'tools':
    return <Collapse in={ open } timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItem button className={ classes.nested }>
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
          <ListItemText inset primary="Tools" />
        </ListItem>
      </List>
    </Collapse>;
  default:
    return <div></div>;
  }
}