import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid/Grid';
import Divider from '@material-ui/core/Divider';
import Asterisk from 'mdi-material-ui/Asterisk';
import StarOutline from 'mdi-material-ui/StarOutline';

function DividerWidget(props) {
  const { type, variant, margin, padding, size } = props;
  if (type === 'line') return(<div style={ { width: '100%' } }>
    <Divider variant={ variant } style={ { paddingTop: padding, marginTop: margin, marginBottom: margin } }/>
  </div>);
  else if (type === 'empty') {
    return <div style={ { padding: padding, margin: margin, width: '100%' } }></div>;
  }
  else return (<Grid>
    <Grid item xs={ 12 }>
      <Grid container justify="center" alignItems="center">
        {type === 'asterisk' && <div style={ { marginTop: margin, marginBottom: margin } }>
          <Asterisk fontSize={ size }/> <Asterisk fontSize={ size }/> <Asterisk fontSize={ size }/>
        </div>}
        {type === 'star' && <div style={ { marginTop: margin, marginBottom: margin } }>
          <StarOutline fontSize={ size }/> <StarOutline fontSize={ size }/> <StarOutline fontSize={ size }/>
        </div>}
      </Grid>
    </Grid>
  </Grid>
  );
}
DividerWidget.propTypes = {
  type: PropTypes.oneOf([ 'line', 'asterisk', 'star', 'empty' ]).isRequired,
  variant: PropTypes.oneOf([ 'inset', 'fullLength', 'middle' ]).isRequired,
  margin: PropTypes.number.isRequired,
  padding: PropTypes.number.isRequired,
  size: PropTypes.oneOf([ 'small', 'default', 'large' ]),
};
export default (DividerWidget);