import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import { ListItemText } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { FormatQuoteOpen, FormatQuoteClose } from 'mdi-material-ui';

import Loader from '../../components/Loader';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  fab:{
    margin: 10
  },
  contentRoot: {
    padding: 0,
    textAlign: 'center'
  },
});

class BackCard extends React.Component {
  state = {
    bookStepErrors: [],
  };

  render() {
    const width = window.innerWidth;
    const { classes, loading, toggleShowBack, backCoverDescription, backCoverReview, backCoverReviewAuthor }  = this.props;
    const smallView = width <= 500 ? { minWidth: width - 24 + 'px' } : {};
    return (
      <div className="card">
        <Card  className="card" style={ smallView }>
          <Button
            onClick={ toggleShowBack }
            variant="outlined"
            color="primary"
            aria-label="Save"
            className={ classes.fab }>
            Show Front
          </Button>
          <CardContent>
            {loading && <Loader/>}
            {!loading &&
            <div>
              <div className={ classes.root }>
                <List>
                  {backCoverDescription.trim().split('\n\n').map((value, index) => {
                    return <ListItem key={ index }>
                      <ListItemText primary={ value } className={ classes.contentRoot }/>
                    </ListItem>;
                  })}
                  <ListItem>
                    <ListItemText className={ classes.contentRoot } primary={ <span><FormatQuoteOpen/>{backCoverReview}<FormatQuoteClose/></span> } secondary={ backCoverReviewAuthor }/>
                  </ListItem>
                </List>
              </div>
            </div>
            }
          </CardContent>
        </Card>
      </div>
    );
  }
}

BackCard.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  bookDetails: PropTypes.object.isRequired,
};

export default withStyles(styles)(BackCard);