import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = () => ({
  grid: {
    margin: '40px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  avatar: {
    margin: 10,
    color: '#fff',
  }
});

function TitleBar(props) {
  const { title, classes, icon } = props;
  return (
    <Grid>
      <Grid item xs={ 12 } className={ classes.grid }>
        <Grid container justify="center" alignItems="center">
          <Avatar className={ classes.avatar } style={ { backgroundColor: props.color } }>
            {icon}
          </Avatar>
          <Typography variant="h6"> {title} </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
TitleBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired

};
export default withStyles(styles)(TitleBar);