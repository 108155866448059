import React from 'react';
import { injectStripe } from 'react-stripe-elements';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button/Button';
import Loader from '../Loader';

import green from '@material-ui/core/colors/green';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import StripeCardSection from './StripeCardsSection';
import { theme } from '../../theme';
import BookcicleApi from '../../bookcicleApi';
import { getAuth } from '../../reducers/application';
import connect from 'react-redux/es/connect/connect';
import AcceptTerms from '../AcceptTerms';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';

import { purchaseBook, clearPurchaseBook, listPurchasedBooks } from '../../reducers/book';
import BookcicleErrorAlert from '../BookcicleErrorAlert';

const mapStateToProps = (state) => ({
  ...state.book,
  auth: getAuth(state)
});

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(clearPurchaseBook()),
    purchase: (auth, bookId, token, title, author, price) => dispatch(purchaseBook(auth, bookId, token, title, author, price)),
    fetchPurchasedBooks: (auth) => dispatch(listPurchasedBooks(auth))
  };
};

const styles = () => ({
  textField: {
    padding: '10px',
    marginLeft: '10px',
    marginRight: '10px',
    fontSize: '20px'
  },
  button: {
    lineHeight: '2em'
  },
  success: {
    backgroundColor: green[ 600 ],
  },
  content: {
    marginTop: 0,
    paddingTop: 0
  },
});

class CheckoutForm extends React.Component {
  state = {
    submitting: false,
    checked: false,
    error: false,
    errorMsg: '',
    nameError: false,
    name: '',
    open: false,
    postalCode: null,
    focused: false,
    empty: true,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  handleToggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  async submit() {
    if (!this.state.checked) {
      this.setState({
        error: true,
        errorMsg: 'You must agree to our terms.'
      });
      return;
    }
    else {
      this.setState({
        error: false,
        errorMsg: '',
      });
    }

    this.setState({
      submitting: true
    });
    // eslint-disable-next-line
    const { token } = await this.props.stripe.createToken({ address_zip: this.state.postalCode });
    if (!token) {
      this.setState({
        submitting: false,
        error: true,
        errorMsg: 'Unable to process your card. Please try again.'
      });
    }
    else {
      //TODO Migrate update of Terms into Stripe function to reduce this call.
      new BookcicleApi({ accessToken: this.props.auth.bookcicleAuth, apiKey: this.props.auth.apiKey }).post({
        path: `user/${ this.props.auth.userId }`,
        queryData: {
          update: 'terms',
          timestamp: Date.now()
        }
      },
      (error) => {
        if (error) {
          console.error(error);
        }
      });
      this.setState({ submitting: false }, () => {
        this.props.purchase(this.props.auth, this.props.bookId, token, this.props.title, this.props.author, this.props.price);
      });
    }
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.purchaseBookComplete !== prevProps.purchaseBookComplete) {
      this.props.clear();
      this.props.fetchPurchasedBooks(this.props.auth);
      this.props.handleClose();
    }
  }

  handleChange = name => event => {
    this.setState({ [ name ]: event.target.checked });
  };

  handleChangePostal = changeObj => {
    this.setState({
      error: changeObj.error,
      empty: changeObj.empty,
      postalCode: changeObj.target.value
    });
  };

  render() {

    const { price, handleClose, classes, loadingPurchaseBook, purchaseBookError } = this.props;
    const { submitting, checked, error, errorMsg, focused, empty, postalCode } = this.state;
    const displayPrice = price / 100;

    return (
      <div className="checkout">
        <DialogContent>
          <StripeCardSection />
          <FormControl fullWidth margin="normal">
            <InputLabel
              required={ true }
              focused={ focused }
              shrink={ focused || !empty }
              error={ !!error }>
              Postal Code
            </InputLabel>
            <Input
              value={ postalCode }
              required={ true }
              fullWidth
              onFocus={ this.handleFocus }
              onBlur={ this.handleBlur }
              onChange={ this.handleChangePostal }
            />
          </FormControl>
        </DialogContent>
        <DialogContent classes={ { root: classes.content } }>
          {purchaseBookError && !submitting && !loadingPurchaseBook && <BookcicleErrorAlert size="sm" variant="error" error={ purchaseBookError } />}
          {error && !submitting && <BookcicleErrorAlert size="sm" variant="error" error={ errorMsg } />}
          <FormControl
            className="pull-right"
            required={ true }
            error={ error }
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={ checked }
                  onChange={ this.handleChange('checked') }
                  value="checked"
                  color="primary"
                />
              }
              label={ <div>
                I accept the
                <span style={ { color: 'blue', cursor: 'pointer' } } onClick={ this.handleToggleOpen } > terms of service.</span>
              </div>
              }/>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => {
            this.props.clear();
            handleClose();
          } } color="primary" className={ classes.button }>
            Cancel
          </Button>
          <Button onClick={ this.submit } className={ classes.button } style={ { color: theme.palette.green.main } } disabled={ submitting || loadingPurchaseBook }>
            {submitting || loadingPurchaseBook ? <Loader/> : <span>BUY ${displayPrice}</span>}
          </Button>
        </DialogActions>
        <AcceptTerms open={ this.state.open } handleClose={ this.handleToggleOpen } maxWidth="sm" />
      </div>
    );
  }
}

CheckoutForm.propType = {
  classes: PropTypes.object,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  bookId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOpenSnack: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(injectStripe(CheckoutForm)));