import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import Paper from '@material-ui/core/Paper/Paper';
import ReactHtmlParser from 'react-html-parser';

import ExpPanel from '../../../../components/ExpPanel';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

export function RatingsLegend(props) {
  const { classes, parameters } = props;
  const content = <div style={ { margin: '0 auto' } }>
    <Paper className={ classes.root } elevation={ 1 }>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parameters && parameters.map(param => {
            return <TableRow key={ param.title }>
              <TableCell component="th" scope="row" style={ { paddingLeft: param.indent ? 30 : 10 } }>
                {param.title}
              </TableCell>
              <TableCell>{ReactHtmlParser(param.desc)}</TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
    </Paper>
  </div>;

  return (<div>
    <ExpPanel header="Ratings Legend" content={ content } />
  </div>);
}
RatingsLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  parameters: PropTypes.array.isRequired
};
export default withStyles(styles)(RatingsLegend);