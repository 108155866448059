import React from 'react';
import { isMobile } from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';
import PaperWidget from '../../components/widgets/paperWidgets/PaperWidget';
import DividerWidget from '../../components/widgets/paperWidgets/DividerWidget';
import TextWidget from '../../components/widgets/paperWidgets/TextWidget';
import VideoWidget from '../../components/widgets/media/VideoWidget';
import ImageWidget from '../../components/widgets/media/ImageWidget';
import GifWidget from '../../components/widgets/media/GIFWidget';
import SoundWidgetWrapper from '../../components/widgets/media/SoundWidgetWrapper';
import Messenger from '../../components/widgets/messenger/Messenger';
import Note from '../../components/widgets/paperWidgets/PostItNote';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';

import ReactGA from 'react-ga';

const handleAds = (widget, userId) => {
  const obj = {
    user: userId,
    product: widget.product,
    productType: widget.productType,
    transactionId: 'transactionId',
    filled: false,
  };
  //TODO CAll AD API to Attempt to fill ad.
  ReactGA.event({
    category: 'Book AD Delivered',
    action: 'ADDelivery',
    label: JSON.stringify(obj),
  });
  if(widget.style){
    switch (widget.style) {
    case 'italics':
      return `<i>${ widget.default }</i>`;
    default:
      return widget.default;
    }
  }
  return widget.default;
};

const handleConversion = (widget, msr) => {
  return msr ? widget[ msr ] : widget.imperial;
};

const handleMatches = (content, msr) => {
  const result = content.match(/<\s*bc[^>]*>(.*?)<\/bc>/g);
  result.forEach(x => {
    const json = x.replace(/<\s*bc[^>]*>/g, '').replace('</bc>', '');
    try{
      const widget = JSON.parse(json);
      if (widget.widgetType === 'ad') {
        content = content.replace(x, handleAds(widget));
      }
      else if (widget.widgetType === 'conversion') {
        content = content.replace(x, handleConversion(widget, msr));
      }
      else {
        content = content.replace(x, '');
      }
    }
    catch (e) {
      content = content.replace(x, '');
    }
  });
  return content;
};

const handleWidget = (widget, index, msr) => {
  switch (widget.widgetType) {
  case 'ad':
    return handleAds(widget);
  case 'messenger':
    return <ListItem key={ index } style={ { paddingTop: '2px', paddingBottom: '2px', display: 'block', margin: '20px 0' } }>
      <Messenger messenger={ widget }/>
    </ListItem>;
  case 'note':
    return <ListItem key={ index } style={ { paddingTop: '2px', paddingBottom: '2px', display: 'block' } }>
      <Note size={ widget.size } variant={ widget.variant } message={ widget.message } />
    </ListItem>;
  case 'report':
    return <ListItem>
      <PaperWidget
        title={ widget.title }
        subtitle={ widget.subtitle }
        alignHeader={ widget.alignHeader }
        widgets={ widget.widgets }
        footerTitle={ widget.footerTitle }
        footerSubtitle={ widget.footerSubtitle }
      />
    </ListItem>;
  case 'divider':
    return <ListItem style={ { justifyContent: 'center' } }>
      <DividerWidget
        variant={ widget.variant }
        margin={ widget.margin }
        padding={ widget.padding }
        size={ widget.size }
        type={ widget.type } />
    </ListItem>;
  case 'text':
    return <ListItem>
      <TextWidget
        text={ widget.text }
        isBold={ widget.isBold }
        isItalic={ widget.isItalic }
        size={ widget.size }
        fontFamily={ widget.fontFamily }
        color={ widget.color }
        align={ widget.align }
        justify={ widget.justify }
      />
    </ListItem>;
  case 'video':
    return <VideoWidget src={ widget.src }/>;
  case 'image':
    return <ImageWidget src={ widget.src }/>;
  case 'gif':
    return <GifWidget src={ widget.src }/>;
  case 'sound':
    return <SoundWidgetWrapper
      autoPlay={ widget.autoPlay }
      controls={ widget.controls }
      src={ widget.src }
      loop={ widget.loop }
      offset={ isMobile ? -30 : -200 }
    />;
  case 'conversion':
    return handleConversion(widget, msr);
  default:
    return <i></i>;
  }
};

export const autoHandleBreaks = () => {
  return <ListItem style={ { justifyContent: 'center' } }>
    <DividerWidget
      variant="middle"
      margin={ 20 }
      padding={ 10 }
      size="default"
      type="asterisk"  />
  </ListItem>;
};

export const autoHandleDividers = () => {
  return <ListItem style={ { justifyContent: 'center' } }>
    <DividerWidget
      variant="middle"
      margin={ 20 }
      padding={ 0 }
      size="default"
      type="line"  />
  </ListItem>;
};

export const handleWidgets = (content, index, userId, msr) => {
  if (content.startsWith('<bc')) {
    content = content.replace(/<\s*bc[^>]*>/g, '').replace('</bc>', '');
    let widget;
    try{
      widget = JSON.parse(content);
    }
    catch (e) {
      return <ListItem></ListItem>;
    }
    return handleWidget(widget, index, msr);
  }
  else if (content.includes('<bc') && content.includes('</bc>')) {
    content = handleMatches(content, msr);
  }
  else if(content.trim().match(/^(\*(|\s+)\*(|\s+)\*)$/g) || content.trim().match(/^(#(|\s+)#(|\s+)#)$/g) || content.trim().match(/^(-(|\s+)-(|\s+)-)$/g)) {
    return autoHandleBreaks();
  }
  else if(content.trim().replace(/ /g,'').match(/-{4,}/g)) {
    return autoHandleDividers();
  }
  if(content.includes('<li>')) {
    content = content.replace('<li>', '').replace('</li>', '');
  }
  else if(content.trim().startsWith('<blockquote>')) {
    return <ListItem key={ index } style={ { paddingTop: '5px', paddingBottom: '5px' } }>
      {ReactHtmlParser(content)}
    </ListItem>;
  }
  return <ListItem key={ index } style={ { paddingTop: '5px', paddingBottom: '5px' } }>
    <Typography>&emsp;&emsp;{ReactHtmlParser(content)}</Typography>
  </ListItem>;
};