import { createMuiTheme } from '@material-ui/core/styles/index';
import green from '@material-ui/core/colors/green';

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    typography: {
      fontSize: 12,
    },
    primary: {
      main: '#4696e5',
    },
    secondary: {
      main: '#f50057',
    },
    green: {
      main: '#00c853',
      light: green[ 'A200' ],
    },
    default: {
      main: '#ffffff',
      text: 'rgba(0, 0, 0, 0.54)'
    }
  }
});

export const defaultReader = createMuiTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    fontSize: 12,
    useNextVariants: true,
  },
});

export const smallReader = createMuiTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    fontSize: 10,
    useNextVariants: true,
  },
});

export const largeReader = createMuiTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    fontSize: 14,
    useNextVariants: true,
  },
});

export const xLargeReader = createMuiTheme({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    fontSize: 18,
    useNextVariants: true,
  },
});