import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ContractIcon from '@material-ui/icons/Assignment';
import EditorIcon from '@material-ui/icons/TextFields';
import SignalIcon from '@material-ui/icons/SignalCellularAlt';
import AlertDecagram from 'mdi-material-ui/AlertDecagram';
import AlertOctagon from 'mdi-material-ui/AlertOctagon';
import Alert from 'mdi-material-ui/Alert';
import AccountMultiple from 'mdi-material-ui/Account';
import AutoFix from 'mdi-material-ui/AutoFix';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

export const matureTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#f50057',
    }
  }
});

export const adultTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#ef6c00',
    }
  }
});

export const teenTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#ffeb3b',
    }
  }
});

export const allTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#4caf50',
    }
  }
});

export const RATINGS = [
  'Mature',
  'Adult',
  'Teen',
  'All'
];

export const RATINGS_METADATA = {
  mature: {
    description: <span>
      Not suitable for anyone under 18 years of age. <br/><br/>May include nudity, sexual violence, extreme graphic violence, long periods of intense explicit content.</span>,
    chip: <MuiThemeProvider theme={ matureTheme }>
      <Chip
        avatar={
          <Avatar>
            <AlertDecagram />
          </Avatar>
        }
        label="Mature"
        color="primary"
      />
    </MuiThemeProvider>
  },
  adult: {
    description: <span>Not suitable for anyone under 18 years of age. <br/><br/>May include nudity, sexual interactions, mild graphic violence, strong language.</span>,
    chip: <MuiThemeProvider theme={ adultTheme }>
      <Chip
        avatar={
          <Avatar>
            <AlertOctagon />
          </Avatar>
        }
        label="Adult"
        color="primary"
      />
    </MuiThemeProvider> },
  teen: {
    description: <span>Suitable for anyone over 13 years of age. <br/><br/> May include mild nudity, suggestive sexual interactions, minimal violence, limited use of profanity.</span>,
    chip: <MuiThemeProvider theme={ teenTheme }>
      <Chip
        avatar={
          <Avatar>
            <Alert />
          </Avatar>
        }
        label="Teen"
        color="primary"
      />
    </MuiThemeProvider> },
  all: {
    description: <span>Suitable for anyone. <br/><br/> Will <b>not</b> include any nudity or sexual content of any kind. <b>No</b> use of strong or mild language. <b>No</b> violence of any kind.</span>,
    chip: <MuiThemeProvider theme={ allTheme }>
      <Chip
        avatar={
          <Avatar>
            <AccountMultiple />
          </Avatar>
        }
        label="All"
        color="primary"
      />
    </MuiThemeProvider> },
};

export const PUB_ROUTES = [
  {
    type: 'Opportunity',
    icon: <MoneyIcon />,
    isActive: true,
    desc: 'Learn how publishing on Bookcicle offers you the best opportunity to earn money.',
    isLink: true,
    path: 'opportunity',
  },
  {
    type: 'Contract',
    icon: <ContractIcon />,
    isActive: true,
    desc: <span><b>No</b> lock-in from Bookcicle. Period. <br/> Publish with us, publish with others, distribute your work anyway and anywhere you want.</span>,
    isLink: false
  },
  {
    type: 'Ratings',
    icon: <AlertDecagram/>,
    isActive: true,
    desc: <List>
      <ListItem>
        <ListItemText primary={ RATINGS_METADATA.mature.chip }/>
      </ListItem>
      <ListItem>
        <ListItemText primary={ RATINGS_METADATA.adult.chip } />
      </ListItem>
      <ListItem>
        <ListItemText primary={ RATINGS_METADATA.teen.chip } />
      </ListItem>
      <ListItem>
        <ListItemText primary={ RATINGS_METADATA.all.chip } />
      </ListItem>
    </List>,
    isLink: true,
    path: 'ratings'
  },
  {
    type: 'Book Analytics',
    icon: <AutoFix />,
    isActive: true,
    desc: <span>Gain insights on your working manuscript with <b>Bookcicle Book Analytics.</b></span>,
    isLink: true,
    path: 'analytics/book'
  },
  {
    type: 'Manuscripts',
    icon: <EditorIcon />,
    isActive: true,
    desc: <span>Format, upload, update, review, export and publish your manuscript with the <b>Bookcicle Publishing Portal</b>.</span>,
    isLink: true,
    path: 'manuscripts'
  },
  {
    type: 'Reader Insights',
    icon: <SignalIcon />,
    isActive: false,
    desc: <span>Get details and analytics on your readers with <b>Bookcicle Reader Insight.</b></span>,
    isLink: false,
    path: 'analytics/readers'
  },
];
