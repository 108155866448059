import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { isMobile } from 'react-device-detect';
import Typography from '@material-ui/core/Typography';
import { checkType } from '../../utils';

const styles = () => ({
  paper: {
    maxWidth: 1000,
    padding: 30,
  },
  info: {
    maxWidth: 950,
    padding: 0,
  },
  grid: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  panel: {
    margin: 0
  }
});

function DocsGridWrapper(props) {
  const { header, body, caption, classes, customStyle, elevation } = props;
  return (<div>
    <Grid>
      <Grid item xs={ 12 } className={ classes.grid }>
        <Grid container justify="center" alignItems="center">
          {body && <Paper elevation={ elevation } className={ customStyle ? classes[ customStyle ] : classes.paper } style={ isMobile ? { width: window.innerWidth - 100 } : { width: '100%' } }>
            {header && checkType(header) === 'String' && <Typography variant="h6">{header}</Typography>}
            {header && checkType(header) === 'Object' && header}
            {body}
            {caption && <Typography variant="caption">{caption}</Typography>}
          </Paper>}
        </Grid>
      </Grid>
    </Grid>
  </div>);

}
DocsGridWrapper.defaultProps = {
  elevation: 1
};
DocsGridWrapper.propTypes = {
  header: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  body: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
  caption: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  classes: PropTypes.object,
  customStyle: PropTypes.oneOf([ 'info', 'panel', 'grid' ]),
  elevation: PropTypes.number,
};
export default withStyles(styles)(DocsGridWrapper);