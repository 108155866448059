import React from 'react';
import List from '@material-ui/core/List/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faSlack } from '@fortawesome/free-brands-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default class Connect extends React.PureComponent {
  state = {
    copied: false,
  };

  handleCopyAction = () => {
    this.setState({
      copied: true,
    },() => {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 800);
    });
  };

  render() {
    const { copied } = this.state;
    const { showSecondary } = this.props;
    const icon = !copied ? <FontAwesomeIcon style={ { color: '#80CBC4' } } icon={ faEnvelope } size="2x"/> : <FontAwesomeIcon style={ { color: '#80CBC4' } } icon={ faCheck } size="2x"/>;
    return   <List style={ { padding: 20 } } key={ 'connect_key' }>
      <ListItem button>
        <ListItemIcon>
          <FontAwesomeIcon style={ { color: '#00aced' } } icon={ faTwitter } size="2x"/>
        </ListItemIcon>
        {!showSecondary && <ListItemText inset primary="Twitter" />}
        {showSecondary && <ListItemText inset primary="Twitter" secondary="Give us a shout out, share about your experience."/>}
      </ListItem>
      <ListItem button onClick={ ()=>{window.open('https://www.facebook.com/Bookcicle-1916783898435856/', '_blank');} }>
        <ListItemIcon>
          <FontAwesomeIcon style={ { color: '#3b5998' } } icon={ faFacebook } size="2x"/>
        </ListItemIcon>
        {!showSecondary && <ListItemText inset primary="Facebook" />}
        {showSecondary && <ListItemText inset primary="Facebook" secondary="Give us a shout out, share about your experience." />}
      </ListItem>
      <ListItem button onClick={ ()=>{
        window.open('https://join.slack.com/t/bookcicle/shared_invite/enQtNTEwMjQxMzgzODQyLWZjMmY4Y2I5MTEzYWJjZTU0NDA3ZDY3YTIyYjExZjE5ZjZiNmM1OGY5MzdlZThmZDMxOTk5YWU0MDRiZmNhMjc','_blank');
      } }>
        <ListItemIcon>
          <FontAwesomeIcon icon={ faSlack } size="2x"/>
        </ListItemIcon>
        {!showSecondary && <ListItemText inset primary="Slack" />}
        {showSecondary && <ListItemText inset primary="Slack" secondary="Chat with members of the Bookcicle community, ask questions, or give feedback." />}
      </ListItem>
      <CopyToClipboard text="support@bookcicle.com" onCopy={ this.handleCopyAction }>
        <ListItem button>
          <ListItemIcon>
            {icon}
          </ListItemIcon>
          {!showSecondary && <ListItemText inset primary={ copied ? 'Copied' : 'Support Email' } />}
          {showSecondary && <ListItemText inset primary={ copied ? 'Copied' : 'Support Email' } secondary="Need help or have a problem? This will create a ticket in our system and is the official channel for support." />}
        </ListItem>
      </CopyToClipboard>
    </List>;
  }

}