import React from 'react';
import { Link } from 'react-router-dom';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { theme } from '../../../../theme';
import { DOCS } from '../metadata';
import { PUB_ROUTES } from './metadata';
import TitleBar from '../../../../components/documentation/TitleBar';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    height: 400,
    width: 295,
    overflow: 'hidden'
  },
  content: {
    height: 250,
    padding: 20,
  },
  avatar: {
    margin: 10,
    color: '#fff',
  },
});

export class Publish extends React.PureComponent {

  render() {
    const { classes } = this.props;
    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>
        <TitleBar title="Publishing" icon={ DOCS[ 0 ].icon } color={ DOCS[ 0 ].avatarColor } />
        <Paper className={ classes.root } elevation={ 1 }>

          <Typography variant="subtitle1" style={ { display: 'inline' } }>
            <b>Bookcicle Publishing </b>
          </Typography>

          <Typography component="p" style={ { display:'inline' } }>
            Create great stories and publish on a platform built for authors. Best royalties, more revenue streams,
            Bookcicle widget support, awesome tools, and more.
          </Typography>
        </Paper>

        <Grid container className={ classes.root } spacing={ 16 }>
          <Grid item xs={ 12 }>
            <Grid container className={ classes.demo } justify="center" spacing={ 24 }>
              {PUB_ROUTES.map((pub, index) => (
                <Grid key={ index } item>
                  <Paper className={ classes.paper } style={ { backgroundColor: pub.isActive ? '#FFFFFF' : '#EEEEEE' } }>
                    <List style={ { paddingBottom: 0 } }>
                      {pub.isActive && pub.isLink && <Link to={ `/create/publish/${ pub.path }` } >
                        {Card(pub, classes)}
                      </Link>}
                      {pub.isActive && !pub.isLink && Card(pub, classes)}
                      {!pub.isActive && Card(pub, classes)}
                    </List>
                    {!pub.isActive && <span>coming soon...</span>}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
export default withStyles(styles)(Publish);

function Card(widget, classes) {
  return (<div>
    <ListItem>
      <Avatar style={ { backgroundColor: DOCS[ 0 ].avatarColor } }>
        {widget.icon}
      </Avatar>
      <ListItemText primary={ <Typography variant="h6" component="span">{widget.type}</Typography> }/>
    </ListItem>
    <Divider/>
    <div className={ classes.content }>
      {widget.desc}
    </div>
  </div>);
}