import React from 'react';
import Button from '@material-ui/core/Button';

import { theme } from '../theme';
import { getAuth } from '../reducers/application';
import connect from 'react-redux/src/connect/connect';
import Check from 'mdi-material-ui/Check';
import CheckAll from 'mdi-material-ui/CheckAll';
import BookcicleErrorAlert from '../components/BookcicleErrorAlert';

import { updateUserList } from '../reducers/user';
import { PublishingSectionOne, PublishingSectionTwo, PublishingSectionThree } from '../components/marketing/PublishingSection';

const mapStateToProps = (state) => ({
  auth: getAuth(state),
  ...state.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateList: (auth, data) => dispatch(updateUserList(auth, data))
  };
};

export class Publish extends React.PureComponent {
  render() {
    const { updateUserListComplete, updateUserListError, updatingUserList } = this.props;
    return (<div>
      <section id="one" className="wrapper style2 special">
        <header className="major">
          <h2>Publishing on Bookcicle</h2>
        </header>
        <p>
          coming soon...
        </p>
      </section>

      <section id="two" className="wrapper">
        <div className="inner alt">
          <PublishingSectionOne />
          <PublishingSectionTwo />
          <PublishingSectionThree />
        </div>
      </section>

      <section id="three" className="wrapper style2 special">
        <header className="major">
          <h2>Ready to get started?</h2>
          <p>Join our publishers mailing list to receive notification when we are ready to accept more
            books!</p>
        </header>
        <ul className="actions special">
          {updateUserListError && <li><BookcicleErrorAlert error={ updateUserListError } variant="error" /></li>}
          <li>
            <Button
              disabled={ updatingUserList }
              onClick={ () => this.props.updateList(this.props.auth, { update: 'lists', list: 'publishing', value: true }) }
              size="small"
              style={ { lineHeight: '2em', backgroundColor: theme.palette.green.light } }>
              {updatingUserList && <Check/>}
              {!updatingUserList && updateUserListComplete && <CheckAll/>}
              Join Notification List
            </Button>
          </li>
        </ul>
      </section>
    </div>);
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Publish);