import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
const env = process.env.NODE_ENV;
if(env !== 'test'){
  ReactGA.initialize('UA-123751498-1');
}
else{
  ReactGA.initialize('foo', { testMode: true });
}

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

export default history;