import React from 'react';
import history from '../history';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import red from '@material-ui/core/colors/red';
import InfoIcon from '@material-ui/icons/Info';
import Divider from '@material-ui/core/Divider';

import BookOpenPageVariant from 'mdi-material-ui/BookOpenPageVariant';
import Magnify from 'mdi-material-ui/Magnify';
import Pistol from 'mdi-material-ui/Pistol';
import Flash from 'mdi-material-ui/Flash';
import Adjust from 'mdi-material-ui/Adjust';

import { theme } from '../../theme';

import Button from '@material-ui/core/Button';
import BasicChip from '../../components/BasicChip';
import StripeModal from '../../components/stripe/StripeModal';
import SnackbarAlerts from '../../components/SnackbarAlerts';
import Snackbar from '@material-ui/core/Snackbar';
import { RATINGS_METADATA } from './documentation/publishing/metadata';

const ENV = process.env.REACT_APP_ENV;

const styles = () => ({
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[ 500 ],
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  fab: {
    margin: 10,
    lineHeight: 0
  },
  media: {
    margin: '0 auto',
    height: 256,
    width: 180
  },
  iconHeight: {
    height: '50px'
  },
  contentRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawerContent: {
    display: 'block'
  },
});

class BookCard extends React.Component {
  state = {
    expanded: false,
    stripeModal: false,
    openSnack: false,
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  redirectForBookDetails = () => {
    history.push({
      pathname: '/book/' + this.props.bookDetails.bookId,
      state: { data: this.props }
    });
  };

  redirectForBookAction = () => {
    if (this.props.tab === 1) {
      //open reader
      history.push({
        pathname: '/book/' + this.props.bookDetails.bookId + '/reader',
        state: { data: this.props }
      });
    }
  };

  getAvatar = (genre, bookId) => {
    switch (genre.toLowerCase().trim()) {
    case 'thriller':
      return <Avatar key={ bookId + genre }><Flash/></Avatar>;
    case 'crime':
      return <Avatar key={ bookId + genre }><Pistol/></Avatar>;
    case 'mystery':
      return <Avatar key={ bookId + genre }><Magnify/></Avatar>;
    default:
      return <Avatar><Adjust/></Avatar>;
    }
  };

  handleOpenStripeModal = () => {
    this.setState({ stripeModal: true });
  };

  handleCloseStripeModal = () => {
    this.setState({ stripeModal: false });
  };

  handleOpenSnackBar = () => {
    this.setState({ openSnack: true });
  };

  handleCloseSnackBar = () => {
    this.setState({ openSnack: false });
  };

  render() {
    const width = window.innerWidth;
    const { classes, isGrid, tab, isDrawer, toggleShowBack } = this.props;

    const { openSnack } = this.state;
    const bookDetails = this.props.bookDetails;
    const { bookId, userId, author, title, price, created } = bookDetails;
    let { genre } = bookDetails;
    let { description } = bookDetails.details;
    const { rating } = bookDetails.details;
    let coverUrl;

    if (bookDetails.coverUrl) {
      coverUrl = encodeURI(bookDetails.coverUrl.replace('/prod/', '/'));
    }
    else {
      coverUrl = encodeURI(`https://images.bookcicle.com/${ ENV === 'prod'? '' : 'dev/' }book/${ userId }/${ bookId }/${ bookId }.png`);
    }

    if (!genre && this.props.bookDetails.genres) {
      genre = this.props.bookDetails.genres;
    }

    genre = genre.split(',');

    if (!description && this.props.bookDetails.desc) {
      description = this.props.bookDetails.desc;
    }

    const smallView = width <= 500 ? { minWidth: width - 80 + 'px' } : {};
    return (
      <div className="card">
        <Card className={ isGrid ? 'grid-card' : 'card' } style={ smallView }>
          {!isGrid && !isDrawer && <Button
            onClick={ toggleShowBack }
            variant="outlined"
            color="primary"
            aria-label="Save"
            className={ classes.fab }>
            Show Back
          </Button>}
          <CardHeader
            avatar={ RATINGS_METADATA[ rating.toLowerCase() ].chip }
            action={
              <Typography>{new Date(created * 1000).toLocaleDateString('en-US')}</Typography>
            }
          />
          <CardMedia
            component="img"
            height="200"
            className={ classes.media }
            image={ coverUrl }
            title={ title }
            alt={ title }
          />
          <CardContent>
            <div style={ { textAlign: 'left' } }>
              {isGrid && <Tooltip title={ title }><Typography variant="h6" style={ {
                maxWidth: 225,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis' } }>
                {title}
              </Typography></Tooltip>}
              {!isGrid && <Typography variant="h6">
                {title}
              </Typography>}
              {isGrid && <Tooltip title={ author }>
                <Typography variant="subtitle1" style={ {
                  maxWidth: 225,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis' } }>
                  By {author}
                </Typography>
              </Tooltip>}
              {!isGrid && <Typography variant="subtitle1">
                By {author}
              </Typography>}
            </div>
          </CardContent>
          {!isGrid && <div><CardContent>
            <CardContent style={ { padding: '0' } }>
              <Typography>
                {description}
              </Typography>
            </CardContent>
            <CardContent classes={ { root: isDrawer ? classes.drawerContent : classes.contentRoot } }>
              {genre.map((label, index) =>
                <BasicChip key={ index + bookId } label={ label } avatar={ this.getAvatar(label) }/>
              )}
            </CardContent>
          </CardContent></div>}
          <CardActions className={ classes.actions } disableActionSpacing>
            <MuiThemeProvider theme={ theme }>
              {isGrid && <Button
                size="small"
                className={ classes.iconHeight }
                aria-label="details"
                color="primary"
                onClick={ this.redirectForBookDetails }>
                <InfoIcon style={ { marginRight: 5 } }/> info
              </Button>}
              {isGrid && !isDrawer && tab === 1 && <Button
                style={ { color: theme.palette.green.main } }
                className={ classes.iconHeight }
                size="small"
                onClick={ this.redirectForBookAction }>
                <BookOpenPageVariant style={ { marginRight: 5 } }/> Open
              </Button>}
              {isGrid && !isDrawer && tab === 0 && <StripeModal
                color="green"
                open={ this.state.stripeModal }
                title={ title }
                author={ author }
                cover={ coverUrl }
                price={ price }
                bookId={ bookId }
                handleOpen={ this.handleOpenStripeModal }
                handleClose={ this.handleCloseStripeModal }
                handleOpenSnack={ this.handleOpenSnackBar }
              />}
              {!isGrid && !isDrawer && tab === 0 && <StripeModal
                open={ this.state.stripeModal }
                title={ title }
                author={ author }
                cover={ coverUrl }
                price={ price }
                bookId={ bookId }
                handleOpen={ this.handleOpenStripeModal }
                handleClose={ this.handleCloseStripeModal }
                handleOpenSnack={ this.handleOpenSnackBar }
              />}
            </MuiThemeProvider>
            {isGrid &&  <MuiThemeProvider theme={ theme }>
              <IconButton
                className={ classNames(classes.expand, classes.iconHeight, {
                  [ classes.expandOpen ]: this.state.expanded,
                }) }
                color="primary"
                onClick={ this.handleExpandClick }
                aria-expanded={ this.state.expanded }
                aria-label="Show more">
                <ExpandMoreIcon/>
              </IconButton>
            </MuiThemeProvider>}
          </CardActions>
          {isGrid && <Collapse in={ this.state.expanded } timeout="auto" unmountOnExit>
            <CardContent style={ { padding: 2 } }>
              <Typography>
                {description}
              </Typography>
            </CardContent>
            <CardContent>
              <Divider/>
            </CardContent>
            <CardContent classes={ { root: classes.drawerContent } }>
              {genre.map((label, index) =>
                <BasicChip key={ index + bookId } label={ label } avatar={ this.getAvatar(label) }/>
              )}
            </CardContent>
          </Collapse>}
        </Card>
        <Snackbar open={ openSnack } autoHideDuration={ 3000 } >
          <SnackbarAlerts
            message={ <span>Awesome! You can find {title} in your Library.</span> }
            variant={ 'success' }
            onClose={ this.handleCloseSnackBar }
          />
        </Snackbar>
      </div>
    );
  }
}

BookCard.propTypes = {
  bookDetails: PropTypes.object.isRequired,
  classes: PropTypes.object,
  isGrid: PropTypes.bool.isRequired,
  isDrawer: PropTypes.bool,
  tab: PropTypes.number
};
export default withStyles(styles)(BookCard);