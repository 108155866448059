import React from 'react';
import TrackVisibility from 'react-on-screen';
import SoundWidget from './SoundWidget';
import PropTypes from 'prop-types';

export function SoundWidgetWrapper(props) {
  return(
    <TrackVisibility offset={ props.offset }>
      {({ isVisible }) => <SoundWidget loop={ props.loop } src={ props.src } controls={ props.controls } autoPlay={ props.autoPlay } isVisible={ isVisible } />}
    </TrackVisibility>
  );
}
SoundWidgetWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool.isRequired,
  controls: PropTypes.bool.isRequired,
  loop: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
};
export default (SoundWidgetWrapper);