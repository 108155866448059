import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { theme } from '../../../../theme';
import TitleBar from '../../../../components/documentation/TitleBar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { PUB_ROUTES } from './metadata';
import { DOCS } from '../metadata';
import ExpPanel from '../../../../components/ExpPanel';
import Table from '@material-ui/core/Table';

import DocsGridWrapper from '../../../../components/documentation/DocsGridWrapper';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    height: 400,
    width: 295,
    overflow: 'hidden'
  },
  paperContent: {
    margin: 20,
    padding: 20,
    overflowX: 'auto',
  },
});

function Manuscripts(props) {
  const { classes } = props;
  const type = PUB_ROUTES.filter(x => { return x.type === 'Manuscripts';});
  return <DocsWrapper>
    <TitleBar title="Manuscripts" icon={ type[ 0 ].icon } color={ DOCS[ 0 ].avatarColor } />

    <DocsGridWrapper
      header={ <Typography variant="subtitle1" style={ { display: 'inline' } }><b>Publishing Manuscripts  </b></Typography> }
      body={ <Typography component="p" style={ { display:'inline' } }>
        Learn how to use the <a href="https://publishing.bookcicle.com">Bookcicle Publishing Portal</a>. Bookcicle Publishing
        allows you to upload or a start a new story. Make use of premium tools such as book analytics, advanced manuscript export,
        managed peer reviews and much more.
      </Typography> }
    />

    <DocsGridWrapper
      elevation={ 0 }
      customStyle="panel"
      body={ <ExpPanel header="Prepare You Manuscript" secondaryHeading={ 'Get your manuscript formatted for an error free upload.' } content={ <div></div> } /> } />

    <ExpPanel header="Upload Your Manuscript" secondaryHeading={ 'Learn how the Bookcicle upload process works.' } content={ <div>
      <Paper className={ classes.paperContent }>
        <Table>

        </Table>
      </Paper>
    </div> } />

    <ExpPanel header="Update and Review Your Manuscript" secondaryHeading={ 'Learn how to update details about your story, and review how it will look to users on Bookcicle.' } content={ <div>
      <Paper className={ classes.paperContent }>
        <Table>

        </Table>
      </Paper>
    </div> } />
  </DocsWrapper>;
}
export default withStyles(styles)(Manuscripts);