import BookcicleApi from '../bookcicleApi';

const DESCRIBE_BOOK = 'book/DESCRIBE_BOOK';
const DESCRIBE_BOOK_FAILURE = 'book/DESCRIBE_BOOK_FAILURE';
const DESCRIBE_BOOK_SUCCESS = 'book/DESCRIBE_BOOK_SUCCESS';

const LIST_BOOKS = 'book/LIST_BOOKS';
const LIST_BOOKS_FAILURE = 'book/LIST_BOOKS_FAILURE';
const LIST_BOOKS_SUCCESS = 'book/LIST_BOOKS_SUCCESS';

const LIST_PURCHASED_BOOKS = 'book/LIST_PURCHASED_BOOKS';
const LIST_PURCHASED_BOOKS_FAILURE = 'book/LIST_PURCHASED_BOOKS_FAILURE';
const LIST_PURCHASED_BOOKS_SUCCESS = 'book/LIST_PURCHASED_BOOKS_SUCCESS';

const PURCHASE_BOOK = 'book/PURCHASE_BOOK';
const PURCHASE_BOOK_FAILURE = 'book/PURCHASE_BOOK_FAILURE';
const PURCHASE_BOOK_SUCCESS = 'book/PURCHASE_BOOK_SUCCESS';
const PURCHASE_BOOK_CLEAR = 'book/PURCHASE_BOOK_CLEAR';

const DESCRIBE_BOOK_CHAPTERS = 'book/DESCRIBE_BOOK_CHAPTERS';
const DESCRIBE_BOOK_CHAPTERS_FAILURE = 'book/DESCRIBE_BOOK_CHAPTERS_FAILURE';
const DESCRIBE_BOOK_CHAPTERS_SUCCESS = 'book/DESCRIBE_BOOK_CHAPTERS_SUCCESS';

const INITIAL_STATE = {
  bookDetails: {},
  loadingDetails: false,
  loadingDetailsError: null,

  bookChapters: [],
  loadingChapters: false,
  chaptersError: null,

  books: [],
  error: null,
  loading: false,

  purchasedBooks: [],
  purchasedBooksError: null,
  loadingPurchasedBooks: false,

  purchaseBookComplete: false,
  purchaseBookError: null,
  loadingPurchaseBook: false,

};

export default function book(state = INITIAL_STATE, action) {
  switch (action.type) {
  case DESCRIBE_BOOK:
    return { ...state, loadingDetails: true };
  case DESCRIBE_BOOK_FAILURE:
    return { ...state, loadingDetails: false, loadingDetailsError: action.error };
  case DESCRIBE_BOOK_SUCCESS:
    return { ...state, loadingDetails: false, loadingDetailsError: null, bookDetails: action.result.Item };

  case DESCRIBE_BOOK_CHAPTERS:
    return { ...state, loadingChapters: true };
  case DESCRIBE_BOOK_CHAPTERS_FAILURE:
    return { ...state, loadingChapters: false, chaptersError: action.error };
  case DESCRIBE_BOOK_CHAPTERS_SUCCESS:
    return { ...state, loadingChapters: false, chaptersError: null, bookChapters: action.result.chapters };

  case LIST_BOOKS:
    return { ...state, loading: true };
  case LIST_BOOKS_FAILURE:
    return { ...state, loading: false, error: action.error };
  case LIST_BOOKS_SUCCESS:
    return { ...state, loading: false, error: null, books: action.result };

  case LIST_PURCHASED_BOOKS:
    return { ...state, loadingPurchasedBooks: true };
  case LIST_PURCHASED_BOOKS_FAILURE:
    return { ...state, loadingPurchasedBooks: false, purchasedBooksError: action.error };
  case LIST_PURCHASED_BOOKS_SUCCESS:
    return { ...state, loadingPurchasedBooks: false, purchasedBooksError: null, purchasedBooks: action.result };

  case PURCHASE_BOOK:
    return { ...state, loadingPurchaseBook: true };
  case PURCHASE_BOOK_FAILURE:
    return { ...state, loadingPurchaseBook: false, purchaseBookError: action.error };
  case PURCHASE_BOOK_SUCCESS:
    return { ...state, loadingPurchaseBook: false, purchaseBookError: null, purchaseBookComplete: true };
  case PURCHASE_BOOK_CLEAR:
    return { ...state, loadingPurchaseBook: false, purchaseBookError: null, purchaseBookComplete: false };
  default:
    return { ...state };
  }
}

export const describeBook = (auth, bookId) => (dispatch) => {
  dispatch({ type: DESCRIBE_BOOK });
  new BookcicleApi({ apiKey: auth.apiKey }).get({
    path: `book/${ bookId }/details`
  },
  (error, result) => {
    if (error) {
      console.error(error);
      return dispatch({ type: DESCRIBE_BOOK_FAILURE, error });
    }
    dispatch({ type: DESCRIBE_BOOK_SUCCESS, result });
  });
};

export const listBooks = (auth) => (dispatch) => {
  dispatch({ type: LIST_BOOKS });
  new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).get({
    path: 'books'
  },
  (error, result) => {
    if (error) {
      console.error(error);
      return dispatch({ type: LIST_BOOKS_FAILURE, error });
    }
    dispatch({ type: LIST_BOOKS_SUCCESS, result: result.Items });
  });
};

export const listPurchasedBooks = (auth) => (dispatch) => {
  dispatch({ type: LIST_PURCHASED_BOOKS });
  new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).get({
    path: `user/${ auth.userId }/purchase/books`,
    data: {
      action: 'list',
      version: Date.now()
    }
  },
  (error, result) => {
    if (error) {
      console.error(error);
      return dispatch({ type: LIST_PURCHASED_BOOKS_FAILURE, error });
    }
    dispatch({ type: LIST_PURCHASED_BOOKS_SUCCESS, result: result.Items });
  });
};

export const clearPurchaseBook = () => (dispatch) => {
  dispatch({ type: PURCHASE_BOOK_CLEAR });
};

export const purchaseBook = (auth, bookId, token, title, author, price) => (dispatch) => {
  dispatch({ type: PURCHASE_BOOK });
  new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).post({
    path: `user/${ auth.userId }/purchase/book`,
    data: {
      title: title,
      author: author,
      price: parseInt(price),
      bookId: bookId,
      token: token.id
    }
  }, (error, result) => {
    if (error) {
      if(result){
        return dispatch({ type: PURCHASE_BOOK_FAILURE, error: result.body.message });
      }
      else {
        return dispatch({ type: PURCHASE_BOOK_FAILURE, error: `${ error }` });
      }
    }
    dispatch({ type: PURCHASE_BOOK_SUCCESS, result: result });
  });
};

export const describeChapters = (auth, bookId) => (dispatch) => {
  dispatch({ type: DESCRIBE_BOOK_CHAPTERS });
  new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).get({
    path: `user/${ auth.userId }/purchase/book/${ bookId }`,
    data: {
      action: 'chapters'
    }
  }, (error, result) => {
    if (error) {
      if (result) {
        return dispatch({ type: DESCRIBE_BOOK_CHAPTERS_FAILURE, error: result.body.message });
      }
      else {
        return dispatch({ type: DESCRIBE_BOOK_CHAPTERS_FAILURE, error: `${ error }` });
      }
    }
    dispatch({ type: DESCRIBE_BOOK_CHAPTERS_SUCCESS, result });
  });
};