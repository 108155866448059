import React from 'react';
import { Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import connect from 'react-redux/src/connect/connect';

import Loader from '../../components/Loader';
import BookCard from './BookCard';
import BookDetailsCard from './BookDetailsCard';
import Reader from './reader/Reader';
import BookcicleErrorAlert from '../../components/BookcicleErrorAlert';
import { describeBook } from '../../reducers/book';

import BackCard from './BackCard';
import { getAuth } from '../../reducers/application';

const mapStateToProps = (state) => ({
  ...state.book,
  auth: getAuth(state),
});
const mapDispatchToProps = (dispatch) => {
  return { fetchBook: (auth, bookId) => dispatch(describeBook(auth, bookId)) };
};

class Book extends React.PureComponent {

    state = {
      tab: null,
      showBack: false,
    };

    componentDidMount() {
      // eslint-disable-next-line
      if ((!this.props.bookDetails || Object.keys(this.props.bookDetails).length === 0) || (this.props.bookDetails && this.props.bookDetails.bookId !== this.props.match.params.id)) {
        this.props.fetchBook(this.props.auth, this.props.match.params.id);
      }

      this.setState({
        tab: this.props.location.state.data ? this.props.location.state.data.tab : null,
      });

    }

    toggleShowBack = () => this.setState({ showBack: !this.state.showBack });

    render() {

      const gridRoot = {
        gridRoot: {
          flexGrow: 1,
        },
      };

      const { error, loadingDetails, bookDetails } = this.props;
      const { tab, showBack } = this.state;
      const bookId = this.props.match.params.id;
      let bookCard = {};
      let backCard = {};

      if (!loadingDetails && bookDetails && Object.keys(bookDetails).length > 0) {
        bookCard = <BookCard
          toggleShowBack={ this.toggleShowBack }
          showBack={ this.state.showBack }
          key={ bookId }
          bookDetails={ bookDetails }
          isGrid={ false }
          tab={ tab }
        />;

        backCard = <BackCard
          toggleShowBack={ this.toggleShowBack }
          bookDetails={ bookDetails }
          loading={ loadingDetails }
          backCoverDescription={ bookDetails.details.backCoverDescription }
          backCoverReview={ bookDetails.details.backCoverReview }
          backCoverReviewAuthor={ bookDetails.details.backCoverReviewAuthor }
        />;
      }

      return <div>
        {!loadingDetails && bookDetails && bookDetails !== null &&  Object.keys(bookDetails).length > 0 && <div>
          {error && <div style={ { marginTop: '25px' } }><BookcicleErrorAlert error={ error } variant="error" /></div>}
          <Route
            path={ `${ this.props.match.url }/reader` }
            render={ () => <Reader isLive={ true } bookId={ this.props.match.params.id } { ...this.props }/> }/>
          <Route
            exact
            path={ this.props.match.url }
            render={ () => <div style={ { backgroundColor: bookDetails.details.bookColor ? bookDetails.details.bookColor : '#8ED1FC' } }>
              {!loadingDetails && <BuildBookGridView backCard={ backCard } showBack={ showBack } bookCard={ bookCard } bookDetails={ bookDetails } loadingDetails={ loadingDetails } gridRoot={ gridRoot }/>}
              { loadingDetails && <Loader loader="sync"/> }
            </div> }
          />
        </div>}
        {loadingDetails && <Loader loader="sync"/>}
      </div>;
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Book);

class BuildBookGridView extends React.Component {
  render() {
    const { bookCard, backCard, loadingDetails, bookDetails, gridRoot, showBack } = this.props;
    return <div style={ { margin: 10 } }>
      <Grid container style={ gridRoot.gridRoot } spacing={ 40 }>
        <Grid item xs={ 12 }>
          <Grid container justify="center" spacing={ 24 }>
            <Grid item xs={ 6 } style={ { maxWidth: 500 } }>
              {!showBack && bookCard}
              {showBack && backCard}
            </Grid>
            <Grid item xs={ 6 } style={ { maxWidth: 500 } }>
              <BookDetailsCard loading={ loadingDetails } bookDetails={ bookDetails } showChapters={ true }/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>;
  }
}