import React from 'react';

import { PUB_ROUTES } from './metadata';
import TitleBar from '../../../../components/documentation/TitleBar';
import { DOCS } from '../metadata';
import PurchaseBookAnalytics from './PurchaseBookAnalytics';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

function BookAnalytics(){
  const service = window.location.hash;
  const avatarColor = DOCS[ 0 ].avatarColor;
  return (<DocsWrapper>
    <TitleBar title="Book Analytics" icon={ PUB_ROUTES[ 3 ].icon } color={ avatarColor } />
    <PurchaseBookAnalytics color={ avatarColor } service={ service }/>
  </DocsWrapper>);
}
export default BookAnalytics;