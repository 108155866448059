import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import TitleBar from '../../../components/documentation/TitleBar';
import PencilCircleOutline from 'mdi-material-ui/PencilCircleOutline';

import { DOCS } from './metadata';
import { theme } from '../../../theme';

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    height: 300,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  content: {
    height: 150,
    padding: 20,
  }
});

class DocsHome extends React.PureComponent {

  render() {
    const { classes } = this.props;
    return <div>
      <MuiThemeProvider theme={ theme }>

        <TitleBar icon={ <PencilCircleOutline/> } color={ green[ 500 ] } title={ 'Create' } showWarning={ true }/>

        <Paper className={ classes.root } elevation={ 1 }>
          <Typography variant="subtitle1" style={ { display: 'inline' } }><b>
            Welcome to Bookcicle!
          </b>
          </Typography>
          <Typography component="p" style={ { display:'inline' } }> Get to know our platform by reading, tinkering, and learning about our components, widgets, and tools!</Typography>
        </Paper>
        <Grid container className={ classes.root } spacing={ 16 }>
          <Grid item xs={ 12 }>
            <Grid container className={ classes.demo } justify="center" spacing={ 24 }>
              {DOCS.map((doc, index) => (
                <Grid key={ index } item>
                  <Paper className={ classes.paper } style={ { backgroundColor: doc.isActive ? '#FFFFFF' : '#EEEEEE' } }>
                    <List style={ { paddingBottom: 0 } }>
                      <ListItem>
                        <Avatar style={ { backgroundColor: doc.avatarColor } }>
                          {doc.icon}
                        </Avatar>
                        <ListItemText primary={ <Typography variant="h6" component="span">{doc.title}</Typography> }/>
                      </ListItem>
                      <Divider/>
                      <Typography className={ classes.content }>
                        {doc.desc}
                      </Typography>
                      <Button disabled={ !doc.isActive } variant="contained" style={ { backgroundColor: doc.avatarColor } } component={ Link } to={ `/create/${ doc.type }` } className={ classes.button }>
                        {doc.isActive ? doc.buttonLabel : 'COMING SOON'}
                      </Button>
                    </List>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    </div>;
  }

}
DocsHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DocsHome);