import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import Drawer from '@material-ui/core/Drawer';
import BookDetailsCard from '../BookDetailsCard';
import BookCard from '../BookCard';

const styles = {
  list: {
    maxWidth: 300,
  }
};

class DetailsDrawer extends React.Component {
  render() {
    const { toggleDrawer, classes } = this.props;
    const bookDetails = this.props.bookDetails;
    return (
      <div>
        <Drawer anchor="right" open={ this.props.right } onClose={ ()=> {toggleDrawer();} }>
          <div
            tabIndex={ 0 }
            role="button"
            onClick={ toggleDrawer }
            onKeyDown={ toggleDrawer }
          >
            <div className={ classes.list }>
              <BookCard bookDetails={ bookDetails } isGrid={ false } isDrawer={ true }/>
              <BookDetailsCard bookDetails={ bookDetails } loading={ this.props.loading }/>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

DetailsDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  right: PropTypes.bool.isRequired,
  bookId: PropTypes.string.isRequired,
};

export default withStyles(styles)(DetailsDrawer);