import superagent from 'superagent/lib/client';
import { checkType } from './utils';

const BOOKCICLE_API_URL = process.env.REACT_APP_BOOKCICLE_URL;

export const ContentType = {
  JSON: 'json',
  FORM: 'form'
};

export default class BookcicleApi {
  constructor(options) {
    options = {
      bookcicleUrl: BOOKCICLE_API_URL,
      request: superagent,
      ...options
    };
    this.bookcicleUrl = options.bookcicleUrl;
    this.request = options.request;
    this.accessToken = options.accessToken;
    this.apiKey = options.apiKey;
  }

  _send({ accept = 'json', req, data, ignoreStatus, result = 'body' }, cb) {
    req.accept(accept);
    if (this.accessToken) {
      req.set('Authorization', `Bearer ${ this.accessToken }`);
    }

    if(this.apiKey) {
      req.set('x-api-key', this.apiKey);
    }

    req.send(data).end((err, res) => {
      if (err) {
        if(ignoreStatus != null && ignoreStatus.indexOf(res.status) !== 1){
          return cb(null, null);
        }

        if (res && res.body) {
          return cb(err, res);
        }

        if(checkType(err) === 'String' || checkType(err) === 'na') {
          return cb(`${ err }`);
        }

        cb(err);
      }
      else {
        cb(null, res.noContent ? { noContent: true } : res[ result ]);
      }
    });
  }

  get({ accept, data, ignoreStatus, result, path }, cb) {
    let req;
    if (path.includes('https://')) {
      req = this.request.get(`${ path }`);
    }
    else {
      req = this.request.get(`${ this.bookcicleUrl }/${ path }`);
    }

    if (data) {
      req.query(data);
    }
    return this._send({ accept, ignoreStatus, req, result }, cb);
  }

  post({ accept, contentType, data, progress, result, path, queryData }, cb) {
    let req;
    if (path.includes('https://')) {
      req = this.request.post(`${ path }`);
    }
    else {
      req = this.request.post(`${ this.bookcicleUrl }/${ path }`);
    }

    if (progress) {
      req.on('progress', progress);
    }

    if (contentType) {
      req.type(contentType);
    }

    if (queryData) {
      req.query(queryData);
    }
    return this._send({ accept, req, data, result }, cb);
  }

  put({ accept, contentType, data, progress, result, path, headers, queryData }, cb) {
    let req;
    if (path.includes('https://')) {
      req = this.request.put(`${ path }`);
    }
    else {
      req = this.request.put(`${ this.bookcicleUrl }/${ path }`);
    }

    if(headers) {
      for (const key in headers) {
        // eslint-disable-next-line
        if (headers.hasOwnProperty(key)) {
          req.set(`${ key }`, `${ headers[ key ] }`);
        }
      }
    }

    if (progress) {
      req.on('progress', progress);
    }

    if (contentType && contentType !== 'EMPTY') {
      req.type(contentType);
    }

    if(contentType === 'EMPTY') {
      req.set('Content-type', '');
    }

    if (queryData) {
      req.query(queryData);
    }
    return this._send({ accept, req, data, result }, cb);
  }

  delete({ accept, data, ignoreStatus, result, path }, cb) {
    let req;
    if (path.includes('https://')) {
      req = this.request.delete(`${ path }`);
    }
    else {
      req = this.request.delete(`${ this.bookcicleUrl }/${ path }`);
    }
    if (data) {
      req.query(data);
    }
    return this._send({ accept, ignoreStatus, req, result }, cb);
  }

}