import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DocsHome from './DocsHome';
import Widgets from './widgets/Widgets';
import Tools from './Tools';
import AdComponents from './AdComponents';
import Publish from './publishing/Publish';
import MessengerDocs from './widgets/MessengerDocs';
import PaperDocs from './widgets/PaperDocs';
import NoteDocs from './widgets/NoteDocs';
import DividerDocs from './widgets/DividerDocs';
import TextDocs from './widgets/TextDocs';
import ImageDocs from './widgets/ImageDocs';
import VideoDocs from './widgets/VideoDocs';
import GifDocs from './widgets/GIFDocs';
import SoundDocs from './widgets/SoundDocs';
import ConverterDocs from './widgets/ConverterDocs';

import Ratings from './publishing/Ratings';
import PubOpp from './publishing/PubOpp';
import Manuscripts from './publishing/Manuscripts';
import BookAnalytics from './publishing/BookAnalytics';

export default function BookcicleRoutes() {
  return <Switch>
    <Route exact path="/create" component={ DocsHome }/>

    <Route exact path="/create/widgets" component={ Widgets }/>
    <Route exact path="/create/widgets/messenger" component={ MessengerDocs }/>
    <Route exact path="/create/widgets/note" component={ NoteDocs }/>
    <Route exact path="/create/widgets/report" component={ PaperDocs }/>
    <Route exact path="/create/widgets/divider" component={ DividerDocs }/>
    <Route exact path="/create/widgets/text" component={ TextDocs }/>
    <Route exact path="/create/widgets/sound" component={ SoundDocs }/>
    <Route exact path="/create/widgets/image" component={ ImageDocs }/>
    <Route exact path="/create/widgets/video" component={ VideoDocs }/>
    <Route exact path="/create/widgets/gif" component={ GifDocs }/>
    <Route exact path="/create/widgets/converter" component={ ConverterDocs } />

    <Route exact path="/create/ads" component={ AdComponents }/>

    <Route exact path="/create/tools" component={ Tools }/>

    <Route exact path="/create/publish" component={ Publish }/>
    <Route exact path="/create/publish/opportunity" component={ PubOpp } />
    <Route exact path="/create/publish/ratings" component={ Ratings } />
    <Route exact path="/create/publish/manuscripts" component={ Manuscripts } />
    <Route exact path="/create/publish/analytics/book" component={ BookAnalytics } />

  </Switch>;

}