import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';
import PostItNote from '../../../../components/widgets/paperWidgets/PostItNote';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import TitleBar from '../../../../components/documentation/TitleBar';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  textField: {
    maxWidth: 400
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'note'
  },
  {
    title: 'variant',
    desc: 'The background color for the note.',
    values: 'pink, darkPink, turquoise, yellow, darkYellow, white'
  },
  {
    title: 'size',
    desc: 'The size of the note.',
    values: 'small, default, large'
  },
  {
    title: 'message',
    desc: 'The message to display on the note.',
    values: 'max-length: 99 characters'
  }
];

export class NoteDocs extends React.PureComponent {

  state = {
    note: {
      widgetType: 'note',
      variant: 'yellow',
      size: 'default',
      message: 'This is a handwritten note, try me out!'
    }
  };

  onChangeJSON = (json) => {
    this.setState({ note: JSON.parse(json) });
  };

  handleChange = name => event => {
    this.setState({
      note: { ...this.state.note, [ name ]: event.target.value }
    });
  };

  render() {
    const { classes } = this.props;
    const { note } = this.state;
    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Note" icon={ WIDGETS_ICONS[ 4 ] } color={ DOCS[ 1 ].avatarColor }/>

        <DocsDesc header="Notes New-Line Widget" desc="Are short messages that are displayed with a hand-written style of font."/>
        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ note } onChangeJSON={ this.onChangeJSON } />
        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <List>
                <ListItem>
                  <TextField
                    id="message"
                    label="Message"
                    fullWidth
                    style={ { margin: 10 } }
                    className={ classes.textField }
                    value={ note.message }
                    onChange={ this.handleChange('message') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <ListItem>
                  <FormGroup>
                    <FormControl variant="outlined">
                      <InputLabel
                        htmlFor="variant"
                      >
                        Variant
                      </InputLabel>
                      <Select
                        className="margin-10"
                        native
                        value={ note.variant }
                        onChange={ this.handleChange('variant') }
                        input={
                          <OutlinedInput
                            labelWidth={ 70 }
                            name="variant"
                            id="variant"
                          /> }
                      >
                        <option>pink</option>
                        <option>darkPink</option>
                        <option>turquoise</option>
                        <option>yellow</option>
                        <option>darkYellow</option>
                        <option>white</option>
                      </Select>
                    </FormControl>
                  </FormGroup>
                  <FormGroup>
                    <FormControl variant="outlined">
                      <InputLabel
                        htmlFor="size"
                      >
                        Size
                      </InputLabel>
                      <Select
                        native
                        value={ note.size }
                        className="margin-10"
                        onChange={ this.handleChange('size') }
                        input={
                          <OutlinedInput
                            labelWidth={ 40 }
                            name="size"
                            id="size"
                          />
                        }
                      >
                        <option>small</option>
                        <option>default</option>
                        <option>large</option>
                      </Select>
                    </FormControl>
                  </FormGroup>
                </ListItem>
              </List>
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <PostItNote message={ note.message } variant={ note.variant } size={ note.size } />
            </Paper>
          </WidgetsDemoGrid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
NoteDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoteDocs);