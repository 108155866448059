import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import WidgetsIcon from '@material-ui/icons/Widgets';
import MonetizationIcon from '@material-ui/icons/MonetizationOn';
import BuildIcon from '@material-ui/icons/Build';

export const DOCS = [
  {
    type: 'publish',
    title: 'Publishing',
    avatarColor: '#B39DDB',
    buttonLabel: 'Learn to Publish',
    desc: 'Publishing has never been easier. Bring or write your works on Bookcicle!',
    icon:  <PublishIcon key="publish" />,
    isActive: true,
  },
  {
    type: 'widgets',
    title: 'Widgets',
    avatarColor: '#90CAF9',
    buttonLabel: 'Create More',
    desc: 'Enrich your stories with widgets that enhance the reader experience.',
    icon: <WidgetsIcon key="widgets" />,
    isActive: true,
  },
  {
    type: 'ads',
    title: 'Ad\'s',
    avatarColor: '#FFCCBC',
    buttonLabel: 'Make More',
    desc: 'Earn more with our unique ad platform.',
    icon: <MonetizationIcon key="ads" />,
    isActive: false,
  },
  {
    type: 'tools',
    title: 'tools',
    avatarColor: '#CFD8DC',
    buttonLabel: 'Grow Faster',
    desc: 'Grow your reader base with Bookcicle tools. Author site, email list management and more.',
    icon: <BuildIcon key="tools"/>,
    isActive: false
  }
];