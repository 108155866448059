import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CheckIcon from '@material-ui/icons/Check';
import ListItemText from '@material-ui/core/ListItemText';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import GearIcon from '@material-ui/icons/Settings';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import BeakerOutline from 'mdi-material-ui/BeakerOutline';

import { defaultReader, largeReader, smallReader, theme, xLargeReader } from '../../../theme';

const styles = {
  root: {
    width: '250px'
  },
  list: {
    maxWidth: 300,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
};

class ConfigurationDrawer extends React.Component {
  state = {
    openFont: false,
    openMsr: false,
  };

  handleClick = (name) => {
    this.setState(state => ({ [ name ]: !state[ name ] }));
  };

  render() {
    const { toggleDrawer, classes, currentFontSize, currentMsr } = this.props;
    return (
      <div>
        <Drawer anchor="right" open={ this.props.right } onClose={ ()=> {toggleDrawer();} }>
          <div
            tabIndex={ 0 }
            role="button"
          >
            <div className={ classes.list }>
              <div className={ classes.root }>
                <List
                  component="nav"
                >
                  <ListItem button>
                    <ListItemIcon><GearIcon/></ListItemIcon>
                    <ListItemText><Typography variant="h6">Settings</Typography></ListItemText>
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={ () => this.handleClick('openFont') }>
                    <ListItemIcon>
                      <TextFieldsIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Font Size" />
                    {this.state.openFont ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={ this.state.openFont } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button className={ classes.nested } onClick={ () => this.props.handleChangeFontSize('xlarge') }>
                        <ListItemText>
                          <MuiThemeProvider theme={ xLargeReader }><Typography >XLarge</Typography></MuiThemeProvider></ListItemText>
                        {currentFontSize === 'xlarge' && <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>}
                      </ListItem>
                      <ListItem button className={ classes.nested } onClick={ () => this.props.handleChangeFontSize('large') }>
                        <ListItemText>
                          <MuiThemeProvider theme={ largeReader }><Typography >Large</Typography></MuiThemeProvider></ListItemText>
                        {currentFontSize === 'large' && <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>}
                      </ListItem>
                      <ListItem button className={ classes.nested }  onClick={ () =>this.props.handleChangeFontSize('default') }>
                        <ListItemText><MuiThemeProvider theme={ defaultReader }><Typography>Default</Typography></MuiThemeProvider></ListItemText>
                        {currentFontSize === 'default' && <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>}
                      </ListItem>
                      <ListItem button className={ classes.nested }  onClick={ () =>this.props.handleChangeFontSize('small') }>
                        <ListItemText><MuiThemeProvider theme={ smallReader }><Typography>Small</Typography></MuiThemeProvider></ListItemText>
                        {currentFontSize === 'small' && <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>}
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem button onClick={ () => this.handleClick('openMsr') }>
                    <ListItemIcon>
                      <BeakerOutline />
                    </ListItemIcon>
                    <ListItemText inset primary="Measurements" />
                    {this.state.openMsr ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={ this.state.openMsr } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button className={ classes.nested } onClick={ () => this.props.handleChangeMsr('imperial') }>
                        <ListItemText primary="Imperial" />
                        {currentMsr === 'imperial' && <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>}
                      </ListItem>
                      <ListItem button className={ classes.nested } onClick={ () => this.props.handleChangeMsr('metric') }>
                        <ListItemText primary="Metric" />
                        {currentMsr === 'metric' && <ListItemIcon>
                          <CheckIcon />
                        </ListItemIcon>}
                      </ListItem>
                    </List>
                  </Collapse>
                </List>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

ConfigurationDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  right: PropTypes.bool.isRequired,
  currentFontSize: PropTypes.string.isRequired,
  currentMsr: PropTypes.string.isRequired,
  handleChangeFontSize: PropTypes.func.isRequired,
  handleChangeMsr: PropTypes.func.isRequired,
};

export default withStyles(styles)(ConfigurationDrawer);