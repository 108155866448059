import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = {
  root: {
    flexGrow: 1,
  },
};

class ProgressBar extends React.Component {

  render() {
    const { classes } = this.props;
    const { primaryColor, completed } = this.props;
    return (
      <div className={ classes.root }>
        {primaryColor && <LinearProgress variant="determinate" value={ completed } />}
        {!primaryColor && <LinearProgress color="secondary" variant="determinate" value={ completed } />}
      </div>
    );
  }
}

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  primaryColor: PropTypes.bool.isRequired,
  completed: PropTypes.number.isRequired
};

export default withStyles(styles)(ProgressBar);