import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { Elements, StripeProvider } from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutFrom';
import { ListItem, ListItemText } from '@material-ui/core';
import List from '@material-ui/core/List';

const PK_KEY = process.env.REACT_APP_PK_KEY;

const styles = () => ({
  button: {
    marginLeft: 'auto',
  },
  stripe: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    marginBottom: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
});

class StripeModal extends React.PureComponent {

  render() {

    const { handleOpen, handleClose, handleOpenSnack, open, price, title, author, bookId, classes } = this.props;
    const displayPrice = price / 100;

    return (
      <StripeProvider apiKey={ PK_KEY }>
        <div className={ classes.button }>
          <Button onClick={ handleOpen } size="small" color="primary" style={ { lineHeight: '2em' } }>
            <span style={ { lineHeight: '1.5em' } }>buy ${displayPrice}</span>
          </Button>
          <Dialog
            maxWidth="xs"
            open={ open }
            onClose={ handleClose }
            aria-labelledby="form-dialog-title"
          >

            <DialogTitle id="form-dialog-title" disableTypography={ true } className={ classes.title }>
              <List>
                <ListItem>
                  <img src={ this.props.cover } alt={ title } width="100" style={ { float: 'left', paddingRight: '15px' } }/>
                  <ListItemText primary={ title } secondary={ author }/>
                </ListItem>
              </List>

            </DialogTitle>
            <Elements>
              <InjectedCheckoutForm
                price={ price }
                title={ title }
                author={ author }
                bookId={ bookId }
                handleClose={ handleClose }
                handleOpenSnack={ handleOpenSnack }
              />
            </Elements>
          </Dialog>
        </div>
      </StripeProvider>
    );
  }
}

StripeModal.propType = {
  classes: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleOpen: PropTypes.func.isRequired,
  handleOpenSnack: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  bookId: PropTypes.string.isRequired
};
export default withStyles(styles)(StripeModal);