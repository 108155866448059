import React from 'react';
import PropTypes from 'prop-types';

function VideoWidget(props) {
  const { src } = props;
  // noinspection CheckTagEmptyBody
  const isValid = src.startsWith('https://player.vimeo.com/video/');
  return(
    <div>
      {isValid && <div style={ { margin: '0 auto', maxWidth: 700 } }>
        <div style={ { padding: '56.25% 0 0 0', position: 'relative', marginTop: 20, marginBottom: 20 } }>
          <iframe
            title="video"
            src={ src }
            style={ { position:'absolute', top:0, left:0, width: '100%', height: '100%', display: 'block' } }
            frameBorder="0"
            allowFullScreen={ true }
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
      }
    </div>
  );
}
VideoWidget.propTypes = {
  src: PropTypes.string.isRequired,
};
export default VideoWidget;