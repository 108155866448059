import React from 'react';
import QueryString from 'query-string';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';

import SnackbarAlerts from './SnackbarAlerts';

export default class BookcicleAlerts extends React.PureComponent {

  state = {
    open: false,
    alertMsg: '',
    alertType: '',
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    let parsed;
    let alertMsg;
    let alertType;

    if (this.props.location && this.props.location.search) {
      if (this.props.location.pathname === '/') {
        window.scrollTo(0,0);
      }
      parsed = QueryString.parse(this.props.location.search);
      if (parsed && parsed.alertMsg) {
        alertMsg = parsed.alertMsg;
        alertType = parsed.alertType ? parsed.alertType : 'warning';
        this.setState({
          alertMsg,
          alertType,
          open: true,
        });
        if(parsed.alert === 'forbidden') {
          this.props.showLoginModal();
        }
      }
    }
  }

  render() {
    const { alertMsg, alertType, open } = this.state;
    return <div>
      {alertMsg && <Snackbar
        anchorOrigin={ {
          vertical: 'bottom',
          horizontal: 'left',
        } }
        open={ open }
        autoHideDuration={ 6000 }
        onClose={ this.handleClose }
      >
        <SnackbarAlerts
          onClose={ this.handleClose }
          variant={ alertType }
          message={ alertMsg }
        />
      </Snackbar>}
    </div>;
  }
}
BookcicleAlerts.propTypes = {
  location: PropTypes.object
};