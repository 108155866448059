import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function InformationHelper(props) {
  const { primary, secondary } = props;
  return(<List>
    <ListItem style={ { textAlign: 'center' } }>
      <ListItemText primary={ primary } secondary={ secondary } />
    </ListItem>
  </List>);
}