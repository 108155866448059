import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import TextField from '@material-ui/core/TextField/TextField';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import TitleBar from '../../../../components/documentation/TitleBar';
import TextWidget from '../../../../components/widgets/paperWidgets/TextWidget';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';

import { CirclePicker } from 'react-color';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  textField: {
    maxWidth: 400
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  content: {
    height: 250,
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'divider'
  },
  {
    title: 'size',
    desc: 'Size of the font ',
    values: 'Pixel size of font: ie 20px, 30px, 50px, Default. Default uses the inherited.'
  },
  {
    title: 'color',
    desc: 'The color of the font.',
    values: 'Any hex color code, ie: #546E7A'
  },
  {
    title: 'fontFamily',
    desc: 'The font family. Default is the client default font.',
    values: 'Cairo, Montserrat, Roboto, Just Another Hand, Charm, Lora, Major Mono Display, Playfair Display, Roboto Mono, Default'
  },
  {
    title: 'align',
    desc: 'Where to align the text.',
    values: 'left, center, right'
  },
  {
    title: 'justify',
    desc: 'Where to align the text container. May not be visible depending on resolution and font size.',
    values: 'flex-start, center, flex-end'
  },
  {
    title: 'isBold',
    desc: 'Is this block of text bold?',
    values: 'true | false'
  },
  {
    title: 'isItalic',
    desc: 'Is this block of text italic?',
    values: 'true | false'
  }
];

export class TextDocs extends React.PureComponent{

  state = {
    widget: {
      widgetType: 'text',
      size: 'body1',
      color: '#FF6900',
      fontFamily: 'Default',
      isBold: false,
      isItalic: false,
      align: 'left',
      justify: 'flex-start',
      text: 'Hey, I am a block of text that has emphasis and style!! You could put many of me in a row on a Paper widget or directly in your manuscripts. You can manipulate me more than you would your regular works.'
    }
  };

  onChangeJSON = (json) => {
    this.setState({ widget: JSON.parse(json) });
  };

  handleColorChange = (color) => {
    this.setState({
      widget: { ...this.state.widget, color: color }
    });
  };

  handleChange = name => event => {
    let value = event.target.value;
    if (name.startsWith('is')) {
      value = value === 'true';
    }
    this.setState({
      widget: { ...this.state.widget, [ name ]: value }
    });
  };

  render() {
    const { classes } = this.props;
    const { widget } = this.state;

    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Text" icon={ WIDGETS_ICONS[ 0 ] } color={ DOCS[ 1 ].avatarColor }/>

        <DocsDesc header="Text New-Line Widget" desc="Create specialized blocks of text that can be used directly in your manuscript or in other widgets."/>
        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ widget } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <TextDocForm widget={ widget } classes={ classes } handleChange={ this.handleChange } handleColorChange={ this.handleColorChange } />
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <Typography>Here we are telling a story with our normal manuscript text....</Typography>
              <TextWidget
                text={ widget.text }
                isBold={ widget.isBold }
                isItalic={ widget.isItalic }
                size={ widget.size }
                fontFamily={ widget.fontFamily }
                justify={ widget.justify }
                color={ widget.color }
                align={ widget.align }/>
            </Paper>
          </WidgetsDemoGrid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
TextDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(TextDocs);

export function TextDocForm(props) {
  const { widget, handleChange, classes, handleColorChange } = props;
  return(<List>
    <ListItem>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="size"
        >
          size
        </InputLabel>
        <Select
          native
          style={ { margin: 8 } }
          value={ widget.size }
          onChange={ handleChange('size') }
          input={
            <OutlinedInput
              labelWidth={ 50 }
              name="size"
              id="size"
            />
          }
        >
          <option value="h1">h1 - heading 1</option>
          <option value="h2">h2 - heading 2</option>
          <option value="h3">h3 - heading 3</option>
          <option value="h4">h4 - heading 4</option>
          <option value="h5">h5 - heading 5</option>
          <option value="h6">h6 - heading 6</option>
          <option>default</option>
          <option value="body1">body 1</option>
          <option value="body2">body 2</option>
          <option value="subtitle1">subtitle 1</option>
          <option value="subtitle2">subtitle 2</option>
          <option>caption</option>
          <option>overline</option>
        </Select>
      </FormControl>
    </ListItem>
    <ListItem>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="fontFamily"
        >
          font family
        </InputLabel>
        <Select
          native
          style={ { margin: 8 } }
          value={ widget.fontFamily }
          onChange={ handleChange('fontFamily') }
          input={
            <OutlinedInput
              labelWidth={ 90 }
              name="fontFamily"
              id="fontFamily"
            />
          }
        >
          <option>Cairo</option>
          <option>Roboto</option>
          <option>Montserrat</option>
          <option>Just Another Hand</option>
          <option>Charm</option>
          <option>Lora</option>
          <option>Major Minor Display</option>
          <option>Playfair Display</option>
          <option>Roboto Mono</option>
          <option>Default</option>
        </Select>
      </FormControl>
    </ListItem>
    <ListItem>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="isBold"
        >
          Is Bold
        </InputLabel>
        <Select
          native
          style={ { margin: 8 } }
          value={ widget.isBold }
          onChange={ handleChange('isBold') }
          input={
            <OutlinedInput
              labelWidth={ 60 }
              name="Is Bold"
              id="isBold"
            />
          }
        >
          <option value={ true }>true</option>
          <option value={ false }>false</option>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="isItalic"
        >
          Is Italic
        </InputLabel>
        <Select
          native
          style={ { margin: 8 } }
          value={ widget.isItalic }
          onChange={ handleChange('isItalic') }
          input={
            <OutlinedInput
              labelWidth={ 60 }
              name="Is Italic"
              id="isItalic"
            />
          }
        >
          <option value={ true }>true</option>
          <option value={ false }>false</option>
        </Select>
      </FormControl>
    </ListItem>
    <ListItem>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="align"
        >
          Align
        </InputLabel>
        <Select
          native
          style={ { margin: 8 } }
          value={ widget.align }
          onChange={ handleChange('align') }
          input={
            <OutlinedInput
              labelWidth={ 60 }
              name="Align"
              id="align"
            />
          }
        >
          <option>left</option>
          <option>center</option>
          <option>right</option>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={ classes.formControl }>
        <InputLabel
          htmlFor="justify"
        >
          Justify
        </InputLabel>
        <Select
          native
          style={ { margin: 8 } }
          value={ widget.justify }
          onChange={ handleChange('justify') }
          input={
            <OutlinedInput
              labelWidth={ 60 }
              name="Justify"
              id="justify"
            />
          }
        >
          <option>flex-start</option>
          <option>center</option>
          <option>flex-end</option>
        </Select>
      </FormControl>
    </ListItem>
    <ListItem>
      <TextField
        id="text"
        label="Text"
        fullWidth
        multiline={ true }
        style={ { margin: 8 } }
        className={ classes.textField }
        value={ widget.text }
        onChange={ handleChange('text') }
        margin="normal"
        variant="outlined"
        InputLabelProps={ {
          shrink: true,
        } }
      />
    </ListItem>
    <ListItem>
      <CirclePicker
        onChangeComplete={ (color) => {
          handleColorChange(color.hex);
        } }
        triangle="hide"
        colors={
          [
            '#000000',
            '#585858',
            '#808080',
            '#A9A9A9',
            '#D3D3D3',
            '#F5F5F5',
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#03a9f4',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#cddc39',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#795548',
            '#A0522D',
          ]
        }
      />
    </ListItem>
  </List>);
}
TextDocForm.propTypes = {
  classes: PropTypes.object.isRequired,
  widget: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};