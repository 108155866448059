import React from 'react';

export function ReaderSectionOne() {
  return(<section className="spotlight">
    <div className="image"><img src="https://images.bookcicle.com/images/trending.png" alt="loading..."/></div>
    <div className="content">
      <h3>Better Book Prices</h3>
      <p>We incentivize our authors to publish on Bookcicle with the lowest possible price points,
        a model opposite that of our competitors. We can do this because our unique platform gives authors more ways to
        earn and higher royalties than any where else. Happy Reading!</p>
    </div>
  </section>);
}

export function ReaderSectionTwo() {
  return(<section className="spotlight">
    <div className="image"><img src="https://images.bookcicle.com/images/idea.png" alt="loading..."/></div>
    <div className="content">
      <h3>Unique Story Experience</h3>
      <p>Enjoy a truly unique experience. Our e-reader supports sounds, video, gifs, images,
        mobile app representations, and more. All to give authors the tools they need to enhance the story,
        to more accurately share how they imagine things to be.</p>
    </div>
  </section>);
}