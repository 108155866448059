import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';

const styles = () => ({
  textWidget: {
    maxWidth: 700,
    overFlow: 'hidden',
    wordWrap: 'break-word'
  }
});

function TextWidget(props) {
  const { size, fontFamily, color, isBold, isItalic, text, align, justify, classes } = props;

  let style;
  if (fontFamily !== 'Default') {
    style = {
      fontFamily: fontFamily,
      color: color,
      fontWeight: isBold ? 'bold' : 'normal',
      fontStyle: isItalic ? 'italic' : 'normal',
      textAlign: align
    };
  }
  else {
    style = {
      color: color,
      fontWeight: isBold ? 'bold' : 'normal',
      fontStyle: isItalic ? 'italic' : 'normal',
      textAlign: align
    };
  }

  return(
    <Grid item xs={ 12 } >
      <Grid container justify={ justify } alignItems="center">
        <Typography style={ style } variant={ size === 'default' ? 'body1' : size } className={ classes.textWidget }>
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}
TextWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf([ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'default', 'body1', 'body2', 'subtitle1', 'subtitle2', 'caption', 'overline' ]).isRequired,
  fontFamily: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isBold: PropTypes.bool.isRequired,
  isItalic: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  align: PropTypes.oneOf([ 'left', 'center', 'right' ]).isRequired,
  justify: PropTypes.oneOf([ 'flex-start', 'center', 'flex-end' ]).isRequired,
};
export default withStyles(styles)(TextWidget);