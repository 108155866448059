import React from 'react';
import Grid from '@material-ui/core/Grid/Grid';

// Widgets Demo Grids, for showing the demo cards within individual widgets docs.
export function WidgetsDemoGrid(props) {
  return(
    <Grid item xs={ 12 } sm={ 6 } >
      {props.children}
    </Grid>
  );
}
