import React from 'react';
import { Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faHandHoldingUsd, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import { DOCS } from '../metadata';
import { PUB_ROUTES } from './metadata';
import TitleBar from '../../../../components/documentation/TitleBar';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import Divider from '@material-ui/core/Divider';
import ExpPanel from '../../../../components/ExpPanel';

import DocsGridWrapper from '../../../../components/documentation/DocsGridWrapper';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paperContent: {
    margin: 20,
    padding: 20,
    overflowX: 'auto',
  },
  table: {
    maxWidth: 600,
  },
  code: {
    maxWidth: 500,
    whiteSpace: 'pre-wrap'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const rows = [
  {
    id: 0,
    price: '$0.99 - $3.99',
    royalty: 78,
    adShare: 28,
  },
  {
    id: 1,
    price: '$4.00 - $7.99',
    royalty: 75,
    adShare: 25,
  },
  {
    id: 2,
    price: '$7.99+',
    royalty: 73,
    adShare: 23,
  }
];

export function PubOpp(props) {
  const { classes } = props;
  const avatarColor = DOCS[ 0 ].avatarColor;
  return(<DocsWrapper>
    <TitleBar title="Opportunity" icon={ PUB_ROUTES[ 0 ].icon } color={ avatarColor } />

    <DocsGridWrapper
      header={ <Typography variant="subtitle1" style={ { display: 'inline' } }>
        <b>Author Opportunity </b>
      </Typography> }

      body={ <Typography component="p" style={ { display:'inline' } }>
        at Bookcicle is top of industry. We want to give authors the highest possible royalties share, and best Ad revenue.
      </Typography> }
    />

    <DocsGridWrapper
      elevation={ 0 }
      customStyle="info"
      body={ <ExpPanel header="Example 1" secondaryHeading={ <span>Author reaches <FontAwesomeIcon icon={ faPlusCircle } style={ { fontSize: 15 } }/> status</span> } content={ <div>
        <Paper      className={ classes.paperContent }>

          Author Andrew Bonar prices his book at $3.99 and includes the <Link to="/create/widgets/divider" style={ { color: 'lightblue' } }>Divider Widget </Link>
          and the <Link to="/create/widgets/note" style={ { color: 'lightblue' } } >Note Widget</Link>. In the month billing period, 1000 copies were sold on Bookcicle.
          Bookcicle was also able to fill Ad's associated with Andrew's book and as a result, Bookcicle collected $500 in Ad revenue
          over the one month period.

          <Grid container >
            <WidgetsDemoGrid>
              <Typography variant="h6" style={ { margin: 10 } }>Royalties</Typography>
              <div className={ classes.center }>
                <pre className={ classes.code }><code>1,000 Copies Sold X $3.99 = ${1000 * 3.99}</code></pre>
              </div>
              <div className={ classes.center }>
                <pre className={ classes.code }><code>$3,990 X 0.80 = ${1000 * 3.99 * .80} Author Share and ${1000 * 3.99 * .20} to Bookcicle.</code></pre>
              </div>
            </WidgetsDemoGrid>
            <WidgetsDemoGrid>
              <Typography variant="h6" style={ { margin: 10 } }>Ad Share</Typography>
              <div className={ classes.center }>
                <pre className={ classes.code }><code>$500 Monthly Ad's Revenue X 0.30 = $150 Author Share and $350 to Bookcicle.</code></pre>
              </div>
            </WidgetsDemoGrid>
          </Grid>

          <Typography variant="h6" style={ { margin: 10 } }>Author Total</Typography>
          <div className={ classes.center }>

            <pre className={ classes.code }><code>$150 Ad Revenue + $3,192 Royalties = ${1000 * 3.99 * .80 + 150}</code></pre>
          </div>
        </Paper>
      </div> } /> }
    />

    <DocsGridWrapper
      elevation={ 0 }
      customStyle="info"
      body={ <ExpPanel header="Example 2" secondaryHeading={ <span>Author does NOT reach <FontAwesomeIcon icon={ faPlusCircle } style={ { fontSize: 15 } }/> status</span> } content={ <div>
        <Paper      className={ classes.paperContent }>
          Author Eva MacDonnell prices her book at $5.99 and includes no Bookcicle Widgets. In the month billing period, 3500 copies were sold on Bookcicle.
          Bookcicle was also able to fill Ad's associated with Andrew's book and as a result, Bookcicle collected $2000 in Ad revenue
          over the one month period.

          <Grid container >
            <WidgetsDemoGrid>
              <Typography variant="h6" style={ { margin: 10 } }>Royalties</Typography>
              <div className={ classes.center }>
                <pre className={ classes.code }><code>3,500 Copies Sold X $5.99 = ${3500 * 5.99}</code></pre>
              </div>
              <div className={ classes.center }>
                <pre className={ classes.code }><code>${3500 * 5.99} X 0.75 = ${3500 * 5.99 * .75} Author Share and ${3500 * 5.99 * .25} to Bookcicle.</code></pre>
              </div>
            </WidgetsDemoGrid>
            <WidgetsDemoGrid>
              <Typography variant="h6" style={ { margin: 10 } }>Ad Share</Typography>
              <div className={ classes.center }>
                <pre className={ classes.code }><code>$2,000 Monthly Ad's Revenue X 0.27 = ${2000 * .27} For Author Share and ${2000 * .73} to Bookcicle.</code></pre>
              </div>
            </WidgetsDemoGrid>
          </Grid>

          <Typography variant="h6" style={ { margin: 10 } }>Author Total</Typography>
          <div className={ classes.center }>

            <pre className={ classes.code }><code>${2000 * .27} Ad Revenue + ${3500 * 5.99 * .75} Royalties = ${3500 * 5.99 * .75 + 2000 * .27} Total</code></pre>
          </div>
        </Paper>
      </div> } /> }
    />

    <Grid container>
      <WidgetsDemoGrid>
        <Paper className={ classes.paperContent }>
          <TitleBar color={ avatarColor } title="Royalties" icon={ <FontAwesomeIcon icon={ faCrown } style={ { fontSize: 17 } }/> } />
          <Divider variant="middle"/>
          <div className={ classes.center }>
            <Table className={ classes.table }>
              <TableHead>
                <TableRow>
                  <TableCell>Book Price</TableCell>
                  <TableCell align="right">Royalty</TableCell>
                  <TableCell align="right">Royalty <FontAwesomeIcon icon={ faPlusCircle }/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  return (
                    <TableRow key={ row.id }>
                      <TableCell component="th" scope="row">
                        {row.price}
                      </TableCell>
                      <TableCell align="right">{row.royalty}%</TableCell>
                      <TableCell align="right">{row.royalty + 2}%</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table></div>
          <Typography variant="caption">
            <FontAwesomeIcon icon={ faPlusCircle } style={ { fontSize: 13 } }/> is achieved by including 2 or more unique Bookcicle Widgets in your Bookcicle published works.
          </Typography>
        </Paper>
      </WidgetsDemoGrid>
      <WidgetsDemoGrid>
        <Paper className={ classes.paperContent }>
          <TitleBar color={ avatarColor } title="Ad Share" icon={ <FontAwesomeIcon icon={ faHandHoldingUsd } style={ { fontSize: 17 } } /> } />
          <Divider variant="middle"/>
          <div className={ classes.center }>
            <Table className={ classes.table }>
              <TableHead>
                <TableRow>
                  <TableCell>Book Price</TableCell>
                  <TableCell align="right">Ad Share</TableCell>
                  <TableCell align="right">Ad Share <FontAwesomeIcon icon={ faPlusCircle }/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => {
                  return (
                    <TableRow key={ row.id }>
                      <TableCell component="th" scope="row">
                        {row.price}
                      </TableCell>
                      <TableCell align="right">{row.adShare}%</TableCell>
                      <TableCell align="right">{row.adShare + 2}%</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table></div>
          <Typography variant="caption">
            <FontAwesomeIcon icon={ faPlusCircle } style={ { fontSize: 13 } }/> is achieved by including 2 or more unique Bookcicle Widgets in your Bookcicle published works.
          </Typography>
        </Paper>
      </WidgetsDemoGrid>
    </Grid>
  </DocsWrapper>);
}export default withStyles(styles)(PubOpp);