import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';

import MockAppBar from './MockAppBar';
import '../../../css/messenger.css';
import { theme } from '../../../theme';

const styles = {
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  chat: {
    width: '100%'
  }
};

class Messenger extends React.Component{

  render() {

    const { classes, messenger } = this.props;
    let { smallView } = this.props;

    if (!smallView) {
      smallView = false;
    }

    return <div>
      <div className={ smallView ? classes.chat : 'chat' }>
        {messenger && messenger.displayName && <MockAppBar barTitle={ messenger.displayName } smallView={ smallView }/>}
        {!messenger && !messenger.displayName && <MockAppBar barTitle="from" smallView={ smallView }/>}
      </div>
      <div className={ smallView ? classes.chat : 'chat' }>
        {messenger && messenger.messages.length > 0 && messenger.messages.map((msg, index) => {
          if(msg.isOwner) {
            return <div className="outgoing_msg" key={ index }>
              <div className="sent_msg">
                <p>{ReactHtmlParser(msg.message)}{msg.timestamp && <span className="time_date_sent"> {msg.timestamp} </span>}</p>
              </div>
            </div>;
          }
          return <div key={ index }>
            <div className="incoming_msg">
              <div className="incoming_msg_img"><Avatar className={ classes.avatar }>{msg.from.substring(0, 1).toUpperCase()}</Avatar>
              </div>
              <div className="received_msg">
                <div className="received_withd_msg">
                  <p>{ReactHtmlParser(msg.message)}{msg.timestamp && <span className="time_date_received"> {msg.timestamp} </span>}</p>
                </div>
              </div>
            </div>
          </div>;
        })

        }
      </div>
    </div>;
  }

}
Messenger.propTypes = {
  messenger: PropTypes.object.isRequired,
  smallView: PropTypes.bool
};
export default withStyles(styles)(Messenger);