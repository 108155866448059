import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField/TextField';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';

import PaperWidget from '../../../../components/widgets/paperWidgets/PaperWidget';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import TitleBar from '../../../../components/documentation/TitleBar';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';

import ReportAddWidgetDialog from '../../../../components/widgets/paperWidgets/ReportAddWidgetDialog';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  content: {
    height: 250,
  },
  textField: {
    maxWidth: 400
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'report'
  },
  {
    title: 'title',
    desc: 'The title of the report header.',
    values: 'max-length: 100 characters.'
  },
  {
    title: 'subtitle',
    desc: 'The subtitle of the report.',
    values: 'max-length: 100 characters.'
  },
  {
    title: 'alignHeader',
    desc: 'Where to align the header content on the page.',
    values: 'left, right, center'
  },
  {
    title: 'widgets',
    desc: 'An array of bookcicle widgets to make up the content section of the report.',
    values: '[ text widget, divider widget, ... ]'
  },
  {
    title: 'footerTitle',
    desc: 'A footer title for the report.',
    values: 'max-length: 100 characters'
  },{
    title: 'footerSubtitle',
    desc: 'A footer subtitle for the report.',
    values: 'max-length: 100 characters'
  }
];

export class PaperDocs extends React.PureComponent {
  state = {
    open: false,
    widget: {
      widgetType: 'report',
      title: 'Initial Report',
      subtitle: `Training Initiative, ${ new Date().toLocaleDateString('en-US') }`,
      alignHeader: 'left',
      widgets: [
        {
          'widgetType': 'text',
          'size': 'h5',
          'color': '#1769aa',
          'fontFamily': 'Default',
          'isBold': false,
          'isItalic': false,
          'align': 'left',
          'justify': 'flex-start',
          'text': '1. Training is best with examples and visual tools.'
        },
        {
          'widgetType': 'text',
          'size': 'h6',
          'color': '#1769aa',
          'fontFamily': 'Default',
          'isBold': false,
          'isItalic': false,
          'align': 'left',
          'justify': 'flex-start',
          'text': '2. Examples showing usage is also good.'
        },
        {
          'widgetType': 'divider',
          'type': 'star',
          'variant': 'middle',
          'margin': 20,
          'padding': 5
        },
        {
          'widgetType': 'text',
          'size': 'h6',
          'color': '#1769ac',
          'fontFamily': 'Default',
          'isBold': false,
          'isItalic': true,
          'align': 'left',
          'justify': 'flex-end',
          'text': 'Lets change things ups.'
        },
      ],
      footerTitle: 'Bookcicle, LLC',
      footerSubtitle: 'Documents Department'
    }
  };

  onChangeJSON = (json) => {
    if (json && json.startsWith('{') && json.length > 3) {
      this.setState({ widget: JSON.parse(json) });
    }
  };

  handleChange = name => event => {
    const value = event.target.value;
    this.setState({
      widget: { ...this.state.widget, [ name ]: value }
    });
  };

  handlePop = () => {
    const widgets = this.state.widget.widgets;
    widgets.pop();
    this.setState({
      widget: {
        ...this.state.widget
      }
    });
  };

  handlePush = (widget) => {
    const widgets = this.state.widget.widgets;
    widgets.push(widget);
    this.setState({
      widget: {
        ...this.state.widget
      }
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const { widget } = this.state;
    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Report" icon={ WIDGETS_ICONS[ 5 ] } color={ DOCS[ 1 ].avatarColor }/>

        <DocsDesc header="Report New-Line Widget" desc="Build a document that is meant to represent a report, official document, or formal letter!"/>

        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ widget } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <List>
                <ListItem>
                  <TextField
                    id="title"
                    label="Title"
                    fullWidth
                    style={ { margin: 8 } }
                    className={ classes.textField }
                    value={ widget.title }
                    onChange={ this.handleChange('title') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="subtitle"
                    label="Subtitle"
                    fullWidth
                    style={ { margin: 8 } }
                    className={ classes.textField }
                    value={ widget.subtitle }
                    onChange={ this.handleChange('subtitle') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="footerTitle"
                    label="Footer Title"
                    fullWidth
                    style={ { margin: 8 } }
                    className={ classes.textField }
                    value={ widget.footerTitle }
                    onChange={ this.handleChange('footerTitle') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="footerSubtitle"
                    label="Footer Subtitle"
                    fullWidth
                    style={ { margin: 8 } }
                    className={ classes.textField }
                    value={ widget.footerSubtitle }
                    onChange={ this.handleChange('footerSubtitle') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <Typography variant="h6" style={ { marginTop: 20 } }>Widgets {`(${ widget.widgets.length })`}</Typography>
                <Divider variant="middle" />
                <div style={ { maxHeight: 600, overflow: 'scroll' } }>
                  {widget.widgets.map((widg, index) => {
                    return <ListItem key={ index }>
                      <ListItemText primary={
                        <HandleWidgetsView widget={ widg } index={ index } />
                      }/>
                      {widget.widgets.length - 1 === index && <ListItemSecondaryAction>
                        <IconButton
                          style={ { height: 48 } }
                          title="Delete Widget"
                          onClick={ this.handlePop }
                        >
                          <DeleteIcon title="Delete"/>
                        </IconButton>
                      </ListItemSecondaryAction> }
                    </ListItem>;
                  })}
                </div>
              </List>
              <Button
                style={ { padding: '0 10px' } }
                variant="contained" color="primary" className={ classes.button } onClick={ this.handleClickOpen }>
                <AddIcon title="add"/> Add Widget
              </Button>
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <PaperWidget
              title={ widget.title }
              subtitle={ widget.subtitle }
              alignHeader={ widget.alignHeader }
              widgets={ widget.widgets }
              footerTitle={ widget.footerTitle }
              footerSubtitle={ widget.footerSubtitle }
            />
          </WidgetsDemoGrid>
        </Grid>
        <ReportAddWidgetDialog handleClose={ this.handleClose } openDialog={ this.state.open } handlePush={ this.handlePush }/>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
PaperDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaperDocs);

export class HandleWidgetsView extends React.Component {
    state = {
      open: false
    };

    handleClick = () => {
      this.setState(state => ({ open: !state.open }));
    };

    render() {
      const { widget, index } = this.props;
      return <List>
        <ListItem button onClick={ this.handleClick }>
          <ListItemText primary={ `${ index }: Widget: ${ widget.widgetType }` } secondary={ widget.widgetType === 'text' ? widget.text : widget.variant }/>
          {this.state.open ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse in={ this.state.open } timeout="auto" unmountOnExit>
          <BookcicleJson code={ widget } hideCopy={ true }/>
        </Collapse>
      </List>;
    }
}