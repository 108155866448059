import React from 'react';
import MessageIcon from '@material-ui/icons/Message';
import CameraIcon from '@material-ui/icons/Camera';
import VideoIcon from '@material-ui/icons/Videocam';
import NoteIcon from '@material-ui/icons/Note';
import NotesIcon from '@material-ui/icons/Notes';
import GifIcon from '@material-ui/icons/Gif';
import SoundIcon from '@material-ui/icons/MusicNote';
import TextIcon from '@material-ui/icons/TextFormat';
import DividerIcon from '@material-ui/icons/FormatLineSpacing';
import Beaker from 'mdi-material-ui/BeakerOutline';

export const SOUND_DEMO = {
  widgetType: 'sound',
  src: 'https://images.bookcicle.com/sounds/SS00615++++Italian+Church+Bells+mid+distance.mp3',
  autoPlay: false,
  controls: true,
};

export const MESSENGER_DEMO = {
  widgetType: 'messenger',
  displayName: 'Andy',
  messages: [
    {
      isOwner: false,
      from: 'Andy',
      message: 'Hi, Does this look like a real messenger?',
      timestamp: 'Today@10:42pm'
    },
    {
      isOwner: true,
      from: 'Tricia',
      message: '❤❤ Sure does!',
      timestamp: 'Just now'
    }
  ]
};

export const REPORT_DEMO = {
  widgetType: 'report',
  title: 'Initial Report',
  subtitle: `Training Initiative, ${ new Date().toLocaleDateString('en-US') }`,
  alignHeader: 'left',
  widgets: [
    {
      'widgetType': 'text',
      'size': 'h6',
      'color': '#1769aa',
      'fontFamily': 'Default',
      'isBold': false,
      'isItalic': false,
      'align': 'left',
      'justify': 'flex-start',
      'text': '1. Training is best with examples and visual tools.'
    }
  ],
  footerTitle: 'Bookcicle, LLC',
  footerSubtitle: 'Documents Department'
};

export const TEXT_DEMO = {
  widgetType: 'textr',
  size: 'h6',
  color: '#1769aa',
  fontFamily: 'Default',
  isBold: false,
  isItalic: false,
  justify: 'center',
  align: 'center',
  text: 'Hey, I am a block of text that has emphasis and style! Manipulate me!'
};

export const WIDGETS_DEMO_LIST = [
  '<bc>{"widgetType":"divider","type":"star","variant":"middle","margin":10,"padding":10, "size": "small"}</bc>',
  '<bc>{"widgetType":"gif","src":"https://giphy.com/embed/SjqU4gjf6FYpa"}</bc>',
  '<bc>{"widgetType":"image","src":"https://images.bookcicle.com/images/aaron-burden-300808-unsplash.jpg"}</bc>',
  '<bc>{"widgetType":"messenger","displayName":"Andy","messages":[{"isOwner":false,"from":"Andy","message":"Hi, Does this look like a real messenger?","timestamp":"Today@10:42pm"},{"isOwner":true,"from":"Tricia","message":"❤❤ Sure does!","timestamp":"Just now"}]}</bc>',
  '<bc>{"widgetType":"note","variant":"yellow","size":"default","message":"This is a handwritten note, try me out!"}</bc>',
  '<bc>{"widgetType":"sound","src":"https://images.bookcicle.com/sounds/SS00615++++Italian+Church+Bells+mid+distance.mp3","autoPlay":true,"controls":true,"loop":false}</bc>',
  '<bc>{"widgetType":"text","size":"h4","color":"#673ab7","fontFamily":"Charm","isBold":false,"isItalic":false,"align":"center","justify":"center","text":"Hey, I am a block of text that has emphasis and style!! You could put many of me in a row on a Paper widget or directly in your manuscripts. You can manipulate me more than you would your regular works."}</bc>',
  '<bc>{"widgetType":"video","src":"https://player.vimeo.com/video/303485323?title=0&byline=0&portrait=0"}</bc>',
  '<bc>{"widgetType":"conversion", "type": "measurement", "imperial": "few feet", "metric": "ninety one centimeters"}</bc>'
];

export const WIDGETS_ICONS = [
  <DividerIcon key="divider" />,
  <GifIcon key="gif_icon"/>,
  <CameraIcon key="picture_icon"/>,
  <MessageIcon key="message_icon"/>,
  <NoteIcon key="note_icon"/>,
  <NotesIcon key="paper_icon"/>,
  <SoundIcon key="sound_icon"/>,
  <TextIcon key="text"/>,
  <VideoIcon key="video_icon"/>,
  <Beaker key="measurements_icon"/>
];

export const WIDGETS = [
  {
    type: 'Divider',
    icon: <DividerIcon key="divider" />,
    isActive: true,
  },
  {
    type: 'Gif',
    icon: <GifIcon key="gif_icon"/>,
    isActive: true,
  },
  {
    type: 'Image',
    icon: <CameraIcon key="picture_icon"/>,
    isActive: true,
  },
  {
    type: 'Messenger',
    icon: <MessageIcon key="message_icon"/>,
    isActive: true,
  },
  {
    type: 'Note',
    icon: <NoteIcon key="note_icon"/>,
    isActive: true,
  },
  {
    type: 'Report',
    icon: <NotesIcon key="paper_icon"/>,
    isActive: true,
  },
  {
    type: 'Sound',
    icon: <SoundIcon key="sound_icon"/>,
    isActive: true,
  },
  {
    type: 'Text',
    icon: <TextIcon key="text"/>,
    isActive: true,
  },
  {
    type: 'Video',
    icon: <VideoIcon key="video_icon"/>,
    isActive: true,
  },
  {
    type: 'Converter',
    icon: <Beaker key="beaker_icon"/>,
    isActive: true,
  },
];

export const WIDGETS_COLOR = '#90CAF9';