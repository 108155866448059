import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid/Grid';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    margin: 20
  },
  small: {
    height: 200,
    width: 200,
  },
  default: {
    height: 250,
    width: 250,
  },
  large: {
    height: 350,
    width: 350,
  },
  pink: {
    backgroundColor: '#ff7eb9'
  },
  darkPink: {
    backgroundColor: '#ff65a3'
  },
  turquoise: {
    backgroundColor: '#7afcff'
  },
  yellow: {
    backgroundColor: '#feff9c'
  },
  darkYellow: {
    backgroundColor: '#fff740'
  },
  white: {
  }
});

const FONT_SIZE = {
  small: '2em',
  default: '2.5em',
  large: '3em'
};

class PostItNote extends React.Component {
  static defaultProps = {
    variant: 'white',
    size: 'default'
  };

  render() {
    const { classes, variant, size, className, message } = this.props;
    return (
      <Grid>
        <Grid item xs={ 12 } >
          <Grid container justify="center" alignItems="center">
            <Paper className={ classNames(classes[ size ], classes[ variant ], classes.root, className) } elevation={ 1 } style={ { overflow: 'hidden', wordWrap: 'break-word' } }>
              <Typography component="h5" style={ { fontFamily: 'Just Another Hand', fontSize: FONT_SIZE[ size ] } }>
                {message}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }

}
PostItNote.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([ 'pink', 'darkPink', 'turquoise', 'yellow', 'darkYellow', 'white' ]),
  size: PropTypes.oneOf([ 'small', 'default', 'large' ])
};

export default withStyles(styles)(PostItNote);