import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '../routes/history';

import CircularProgress from '@material-ui/core/CircularProgress';
import  MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BookIcon from '@material-ui/icons/LibraryBooks';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { isMobile } from 'react-device-detect';
import MenuIcon from '@material-ui/icons/Menu';

import { theme } from '../theme';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create([ 'margin', 'width' ], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  flex: {
    flexGrow: 1,
    textAlign: 'left',
  },
  menuButton: {
    height: 50,
    width: 50,
    marginLeft: 12,
    marginRight: 20,
  },
  button: {
    color: 'rgba(0, 0, 0, 0.54)',
    height: '50px',
    lineHeight: '2em'
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

function BookcicleAppBar(props) {
  const classes = useStyles();

  const { signingOut, isAuthenticated, showLoginModal, showAppBar, logout } = props;

  return(<MuiThemeProvider theme={ theme }>
    <div className={ classes.root }>
      <AppBar position="sticky" color={ 'default' } style={ showAppBar ? {} : { display: 'none' } }>
        <Toolbar>
          <Link to="/"><Avatar alt="Bookcicle" src="https://images.bookcicle.com/images/bookcicle.png" style={ {
            display: 'inline-block',
            float: 'left'
          } }/></Link>
          <Typography variant="h5" color="inherit" className={ classes.flex }>
            <Link to="/" style={ { color: '#008cba', marginLeft: 3, fontFamily: 'Playfair Display' } }>Bookcicle</Link>
          </Typography>
          <AppBarNavItems
            logout={ logout }
            signingOut={ signingOut }
            isAuthenticated={ isAuthenticated }
            showLoginModal={ showLoginModal }
            showAppBar={ showAppBar }
            classes={ classes }
          />
        </Toolbar>
      </AppBar>
    </div>
  </MuiThemeProvider>
  );
}
BookcicleAppBar.propTypes = {
  signingOut: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool,
  showLoginModal: PropTypes.func.isRequired,
  showAppBar: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};
export default BookcicleAppBar;

export function AppBarNavItems(props) {
  const [ state, setState ] = React.useState({ anchorEl: null, anchorElMenu: null });

  const handleAccount = event => {
    setState({ ...state, anchorEl: event.currentTarget });
  };

  const handleMenu = event => {
    setState({ ...state, anchorElMenu: event.currentTarget });
  };

  const handleClose = () => {
    setState({ anchorEl: null, anchorElMenu: null });
  };

  const { anchorEl, anchorElMenu } = state;
  const open = Boolean(anchorEl);
  const openMenu = Boolean(anchorElMenu);
  const { signingOut, isAuthenticated, showLoginModal, logout,  classes } = props;
  return(<div>
    {signingOut && <CircularProgress className={ classes.progress } />}
    {!signingOut && isMobile && <div>
      <IconButton
        aria-owns={ openMenu ? 'menu-appbar-2' : null }
        aria-haspopup="true"
        onClick={ handleMenu }
        className={ classes.button }
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar-2"
        anchorEl={ anchorElMenu }
        anchorOrigin={ {
          vertical: 'top',
          horizontal: 'right',
        } }
        transformOrigin={ {
          vertical: 'top',
          horizontal: 'right',
        } }
        open={ openMenu }
        onClose={ handleClose }
      >
        {anchorElMenu && <MenuBuilder
          items={
            [
              {
                name: 'Login',
                action: ()=>{showLoginModal('login');},
                showWhenAuth: false
              },
              {
                name: 'Sign-up',
                action: ()=>{showLoginModal('sign-up');},
                showWhenAuth: false
              },
              {
                name: 'Account',
                url: '/account',
                target: '_self',
                showWhenAuth: true
              },
              {
                name: 'Books',
                url: '/books',
                target: '_self',
                showWhenAuth: true
              },
              {
                name: 'Docs',
                url: '/create',
                target: '_self',
                showWhenAuth: 'both'
              },
              {
                name: 'Publishing',
                url: 'https://publishing.bookcicle.com',
                target: '_blank',
                showWhenAuth: 'both'
              },
              {
                name: 'Sign-out',
                url: '',
                showWhenAuth: true,
                action: ()=>{logout(true);}
              }
            ]
          }
          isAuthenticated={ isAuthenticated }
          handleClose={ handleClose }
        />}
      </Menu>
    </div>
    }
    {!signingOut && !isMobile && (
      <div>
        {!isAuthenticated && <Button className={ classes.button } style={ { color: 'rgba(0, 0, 0, 0.54)' } } onClick={ () => {
          showLoginModal('login');
        } }>Login</Button>}
        {!isAuthenticated && <Button className={ classes.button } style={ { color: 'rgba(0, 0, 0, 0.54)' } } onClick={ () => {
          showLoginModal('sign-up');
        } }>Sign Up</Button>}
        {isAuthenticated && <IconButton
          title="books"
          onClick={ () => {
            history.push('/books');
          } }
          className={ classes.button }
        >
          <BookIcon title="books"/>
        </IconButton>}
        <IconButton
          aria-owns={ openMenu ? 'menu-appbar-2' : null }
          aria-haspopup="true"
          onClick={ handleMenu }
          className={ classes.button }
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar-2"
          anchorEl={ anchorElMenu }
          anchorOrigin={ {
            vertical: 'top',
            horizontal: 'right',
          } }
          transformOrigin={ {
            vertical: 'top',
            horizontal: 'right',
          } }
          open={ openMenu }
          onClose={ handleClose }
        >
          {anchorElMenu && <MenuBuilder
            items={
              [
                {
                  name: 'Docs',
                  url: '/create',
                  target: '_self',
                  showWhenAuth: 'both',
                },
                {
                  name: 'Publishing',
                  url: 'https://publishing.bookcicle.com',
                  target: '_blank',
                  showWhenAuth: 'both'
                },
              ]
            }
            handleClose={ handleClose }
            isAuthenticated={ isAuthenticated }
          />}
        </Menu>
        {isAuthenticated && <IconButton
          aria-owns={ open ? 'menu-appbar' : null }
          aria-haspopup="true"
          onClick={ handleAccount }
          className={ classes.button }
        >
          <AccountCircle/>
        </IconButton>}
        {isAuthenticated && <Menu
          id="menu-appbar"
          anchorEl={ anchorEl }
          anchorOrigin={ {
            vertical: 'top',
            horizontal: 'right',
          } }
          transformOrigin={ {
            vertical: 'top',
            horizontal: 'right',
          } }
          open={ open }
          onClose={ handleClose }
        >
          {anchorEl && <MenuBuilder
            items={
              [
                {
                  name: 'Account',
                  url: '/account',
                  target: '_self',
                  showWhenAuth: true,
                },
                {
                  name: 'Sign-out',
                  url: '',
                  showWhenAuth: true,
                  action: ()=>{props.logout();}
                },
              ]
            }
            handleClose={ handleClose }
            isAuthenticated={ isAuthenticated }
          />}
        </Menu>}
      </div>
    )}
  </div>);
}
AppBarNavItems.propTypes = {
  signingOut: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool,
  showLoginModal: PropTypes.func.isRequired,
  showAppBar: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

function MenuBuilder(props) {
  return <div>
    {props.items.filter(x => {
      if(x.showWhenAuth === props.isAuthenticated || x.showWhenAuth === 'both') {
        return x;
      }
      else if(!props.isAuthenticated && !x.showWhenAuth){
        return x;
      }
      else{
        return false;
      }
    }).map((x, index) => {
      if(x.type && x.type === 'divider'){
        return <Divider key={ index } variant={ 'middle' }/>;
      }
      return <MenuItem key={ index } onClick={ () => {
        props.handleClose();
        if(x.url && x.target === '_blank'){
          window.open(x.url,x.target);
        }
        else if (x.url && x.target === '_self') {
          history.push(x.url);
        }
        else {
          return x.action();
        }
      } }>{x.name}</MenuItem>;
    })}
  </div>;
}
MenuBuilder.propTypes = {
  items: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  showWhenAuth:  PropTypes.oneOfType([ PropTypes.bool, PropTypes.string ]),
};