import BookcicleApi from '../bookcicleApi';
import { checkType } from '../utils';

const DESCRIBE_USER = 'user/DESCRIBE_USER';
const DESCRIBE_USER_SUCCESS = 'user/DESCRIBE_USER_SUCCESS';
const DESCRIBE_USER_FAILURE = 'user/DESCRIBE_USER_FAILURE';

const UPDATE_USER_LIST = 'user/UPDATE_USER_LIST';
const UPDATE_USER_LIST_SUCCESS = 'user/UPDATE_USER_SUCCESS';
const UPDATE_USER_LIST_FAILURE = 'user/UPDATE_USER_FAILURE';

const INITIAL_STATE = {
  userDetails: null,
  userLoading: false,
  userError: null,

  updateUserListComplete: false,
  updateUserListError: null,
  updatingUserList: false,
};

const updateUserListData = (list, value, userDetails) => {
  if (!userDetails.emailLists) {
    userDetails[ 'emailLists' ] = { [ list ]: value };
  }
  else{
    userDetails[ 'emailLists' ][ list ] = value;
  }
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
  case DESCRIBE_USER:
    return { ...state, userLoading: true, userError: null };
  case DESCRIBE_USER_FAILURE:
    return { ...state, userLoading: false, userError: action.error };
  case DESCRIBE_USER_SUCCESS:
    return { ...state, userLoading: false, userError: null, userDetails: action.result.user };

  case UPDATE_USER_LIST:
    return { ...state, updatingUserList: true, updateUserListError: null };
  case UPDATE_USER_LIST_FAILURE:
    return { ...state, updatingUserList: false, updateUserListError: action.error };
  case UPDATE_USER_LIST_SUCCESS: {
    updateUserListData(action.list, action.value, state.userDetails);
    return { ...state, updatingUserList: false, updateUserListError: null, updateUserListComplete: true };
  }

  default:
    return { ...state };
  }
}

export const describeUser = (auth, fresh) => (dispatch) => {
  dispatch({ type: DESCRIBE_USER });
  new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).get({
    path: `user/${ auth.userId }`,
    data: {
      version: fresh ? new Date() : 'v1'
    }
  },
  (error, result) => {
    if (error) {
      if(result) {
        return dispatch({ type: DESCRIBE_USER_FAILURE, error: result.body.message });
      }
      else if(checkType(error) === 'String' || checkType(error) === 'na') {
        return dispatch({ type: DESCRIBE_USER_FAILURE, error: `${ error }` });
      }
      else{
        return dispatch({ type: DESCRIBE_USER_FAILURE, error: error });
      }
    }
    dispatch({ type: DESCRIBE_USER_SUCCESS, result: result });
  });
};

export const updateUserList = (auth, data) => (dispatch) => {
  dispatch({ type: UPDATE_USER_LIST });
  new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).put({
    path: `user/${ auth.userId }`,
    queryData: data
  },
  (error, result) => {
    if (error) {
      if(result) {
        return dispatch({ type: UPDATE_USER_LIST_FAILURE, error: result.body.message });
      }
      else if(checkType(error) === 'String' || checkType(error) === 'na') {
        return dispatch({ type: UPDATE_USER_LIST_FAILURE, error: `${ error }` });
      }
      else{
        return dispatch({ type: UPDATE_USER_LIST_FAILURE, error: error });
      }
    }
    dispatch({ type: UPDATE_USER_LIST_SUCCESS, list: data.list, value: data.value });
  });
};