import React from 'react';
import PropTypes from 'prop-types';

function GIFWidget(props) {
  const { src } = props;
  const isValid = src.startsWith('https://giphy.com/embed/');
  // noinspection CheckTagEmptyBody
  return(
    <div>
      {isValid && <div style={ { margin: '0 auto', maxWidth: 700 } }>
        <div style={ { padding: '56.25% 0 0 0', position: 'relative', marginTop: 20, marginBottom: 20 } }>
          <iframe
            title="gif"
            src={ src }
            style={ { position:'absolute', top:0, left:0, width: '100%', height: '100%' } }
            frameBorder="0"
            allowFullScreen={ true }
          ></iframe>
        </div>
      </div>}
    </div>
  );
}
GIFWidget.propTypes = {
  src: PropTypes.string.isRequired,
};
export default GIFWidget;