import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import green from '@material-ui/core/colors/green';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';

import { validateEmail } from '../utils';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    flexBasis: '100%',
  },
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: green,
  },
});

class ShareModalForm extends React.Component {

  state = {
    email: '',
    error: null,
    disabled: false,
  };

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
      disabled: false
    });
  };

  validateAndAdd = (email) => {
    if (validateEmail(email)) {
      this.setState({
        error: false,
        disabled: false,
        email: ''
      }, () => {
        this.props.handleAdd(email);
      });
    }
    else {
      this.setState({ error: true, disabled: true });
    }
  };

  render() {
    const { classes } = this.props;

    return <div className={ classes.container }>
      <MuiThemeProvider theme={ theme }>
        <FormControl className={ classNames(classes.margin, classes.textField) }>
          <InputLabel htmlFor="add-email">Email</InputLabel>
          <Input
            error={ this.state.error }
            id="add-email"
            type="email"
            value={ this.state.email }
            onChange={ this.handleChange }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  style={ { height: '50px' } }
                  disabled={ this.state.disabled }
                  aria-label="Add Email"
                  onClick={ () => {
                    this.validateAndAdd(this.state.email);
                  } }
                >
                  <AddIcon/>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </MuiThemeProvider>
    </div>;
  }
}

ShareModalForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAdd: PropTypes.func.isRequired
};
export default withStyles(styles)(ShareModalForm);