import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Chip from '@material-ui/core/Chip/Chip';
import Avatar from '@material-ui/core/Avatar';
import AlertCircleOutline from 'mdi-material-ui/AlertCircleOutline';
import ShareVariant from 'mdi-material-ui/ShareVariant';
import { getAuth } from '../../reducers/application';
import connect from 'react-redux/src/connect/connect';
import history from '../../routes/history';
import ShareModal from '../../components/ShareModal';

const mapStateToProps = (state) => ({
  auth: getAuth(state)
});

const styles = theme => ({
  actions: {
    display: 'flex',
  },
  avatar: {
    backgroundColor: red[ 500 ],
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  media: {
    margin: '0 auto',
    height: 256,
    width: 180
  },
  iconHeight: {
    height: '50px',
    marginLeft: 'auto',
  }
});

class MarketingCard extends React.Component {
  state = {
    expanded: false,
    openModal: false,
  };

  redirectForPublishing = () => {
    history.push({
      pathname: '/publish',
    });
  };

  handleModalOpen = () => {
    this.setState({ openModal: true });
  };

  handleModalClose = (sent) => {
    if (sent) {
      this.setState({ success: true });
    }
    this.setState({ openModal: false });
  };

  render() {
    const width = window.innerWidth;
    const { classes, auth } = this.props;

    const username = auth.userDetails.displayName;

    const message = `<p>${ username } thought you might be interested in publishing at Bookcicle, `+
      'a new digital book publisher built to foster creative digital stories and enable more earning opportunity for authors.</p>' +
      '<p><a href="https://bookcicle.com">Learn more at Bookcicle</a></p>';

    const subject = 'Publishing on Bookcicle';
    const title = 'Hey There!';

    const smallView = width <= 500 ? { minWidth: width - 80 + 'px' } : {};
    return (
      <div className="card">
        <Card className="grid-card" style={ smallView }>
          <CardHeader
            style={ { maxWidth: '130px' } }
            avatar={
              <Chip
                avatar={
                  <Avatar>
                    <AlertCircleOutline/>
                  </Avatar>
                }
                label={ 'Publish On Bookcicle' }
                color="primary"
              />
            }
          />
          <CardMedia
            component="img"
            className={ classes.media }
            image="https://images.bookcicle.com/images/screen-180.png"
            title="title"
            height="256"
          />
          <CardContent>
            <div style={ { textAlign: 'left' } }>
              <Typography variant="h6">
                Your Story Title!
              </Typography>
              <Typography variant="subtitle1" style={ {
                maxWidth: 225,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis' } }>
                By {username}
              </Typography>
            </div>
          </CardContent>
          <CardActions className={ classes.actions } disableActionSpacing>
            <Button
              onClick={ this.redirectForPublishing }
              size="small"
              color="primary"
              style={ { lineHeight: '2em' } }>
              Learn More
            </Button>
            <IconButton
              onClick={ this.handleModalOpen }
              className={ classes.iconHeight }
              aria-label="Share"
            >
              <ShareVariant/>
            </IconButton>
          </CardActions>
        </Card>
        <ShareModal
          data={ {
            'message': message,
            'subject': subject,
            'title': title
          } }
          modalTitle={ <span>Tell Your Friends!</span> }
          handleClose={ this.handleModalClose }
          open={ this.state.openModal }
        />
      </div>
    );
  }
}
export default connect(
  mapStateToProps
)(withStyles(styles)(MarketingCard));