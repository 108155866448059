import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import green from '@material-ui/core/es/colors/green';

const styles = () => ({
  paperContent: {
    margin: 20,
    padding: 20,
    overflowX: 'auto',
  },
});

export function LoggedOut(props) {
  const { classes } = props;
  return(<div>
    <Grid>
      <Grid item xs={ 12 } >
        <Grid container justify="center" alignItems="center">
          <Paper className={ classes.paperContent }>
            <FontAwesomeIcon icon={ faSignOutAlt } size="5x" style={ { color: green[ 500 ], margin: 10 } }/>
            <Typography variant="h6">You have been logged out!!</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  </div>);
}export default withStyles(styles)(LoggedOut);