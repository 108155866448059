import React from 'react';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TitleBar from '../../../../components/documentation/TitleBar';
import { DOCS } from '../metadata';
import RatingsLegend from './RatingsLegend';
import { PUB_ROUTES, RATINGS, RATINGS_METADATA } from './metadata';

import DocsGridWrapper from '../../../../components/documentation/DocsGridWrapper';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paperContent: {
    margin: 20,
    padding: 20,
    overflowX: 'auto',
  },
  table: {
    maxWidth: 600,
  },
  code: {
    maxWidth: 500,
    whiteSpace: 'pre-wrap'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    height: 400,
    width: 295,
    overflow: 'hidden'
  },
  content: {
    height: 250,
    padding: 20,
  },
  avatar: {
    margin: 10,
    color: '#fff',
  },
});

const PARAMS = [
  {
    title: 'Nudity',
    desc: 'Graphic, intense, long lasting, depiction of nudity.'
  },
  {
    title: 'Mild Nudity',
    desc: 'Brief depiction of nudity, No depiction of genitalia.'
  },
  {
    title: 'Sexual Violence',
    desc: 'Depiction of any violent sexual act, including rape.'
  },
  {
    title: 'Sexual Interactions',
    desc: 'Depiction of non-violent sexual interactions.'
  },
  {
    title: 'Intense Explicit Content',
    desc: 'Depiction of explicit language or acts that are realistic, detailed, graphic and may last for a long period.'
  },
  {
    title: 'Extreme Graphic Violence',
    desc: 'Violence that is realistic and can include depictions of aggressive acts, blood, gore. Is a featured part of a section or chapter of a story.'
  },
  {
    title: 'Mild Graphic Violence',
    desc: 'Violence that may be realistic with minimal blood and gore. Is not a featured part of a story section or chapter.'
  },
  {
    title: 'Strong Language',
    desc: 'Prominent use of profanity.'
  },
  {
    title: 'Suggestive Sexual Interactions',
    desc: 'References, suggesting, and implying depiction of sex or sexual acts.'
  },
];

export function Ratings(props) {
  const { classes } = props;
  const avatarColor = DOCS[ 0 ].avatarColor;
  return(<DocsWrapper>
    <TitleBar title="Ratings" icon={ PUB_ROUTES[ 2 ].icon } color={ avatarColor } />
    <DocsGridWrapper
      header={ <Typography variant="subtitle1" style={ { display: 'inline' } }>
        <b>Bookcicle Ratings </b>
      </Typography> }
      body={ <Typography component="p" style={ { display:'inline' } }>
        Learn how to categorize your works so that readers are best prepared for the type of content they can expect to see.
      </Typography> }
    />

    <DocsGridWrapper
      elevation={ 0 }
      customStyle="info"
      body={ <RatingsLegend parameters={ PARAMS }/> }
    />

    <Grid container className={ classes.root } spacing={ 16 }>
      <Grid item xs={ 12 }>
        <Grid container className={ classes.demo } justify="center" spacing={ 24 }>
          {RATINGS.map((rating, index) => (
            <Grid key={ index } item>
              <Paper className={ classes.paper } style={ {  backgroundColor: '#FFFFFF' } }>
                <List style={ { paddingBottom: 0 } }>
                  {Card(rating, RATINGS_METADATA[ `${ rating.toLowerCase() }` ].description, classes)}
                </List>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>

  </DocsWrapper>);
}export default withStyles(styles)(Ratings);

function Card(rating, desc, classes) {
  return (<div>
    <ListItem>
      {RATINGS_METADATA[ `${ rating.toLowerCase() }` ].chip}
    </ListItem>
    <Divider/>
    <div className={ classes.content }>
      {desc}
    </div>
  </div>);
}