import React from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import blue from '@material-ui/core/colors/blue';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import PersonIcon from '@material-ui/icons/Person';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InfoIcon from '@material-ui/icons/Info';

import EmailIcon from '@material-ui/icons/Email';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ErrorIcon from '@material-ui/icons/Error';
import CheckBoxOutlineBlank from '@material-ui/icons/IndeterminateCheckBox';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

import { getAuth } from '../../reducers/application';
import BookcicleApi from '../../bookcicleApi';
import Loader from '../../components/Loader';
import AcceptTerms from '../../components/AcceptTerms';

import { describeUser, updateUserList } from '../../reducers/user';
import { parseToBool } from '../../utils';

const mapStateToProps = (state) => ({
  ...state.user,
  auth: getAuth(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (auth) => dispatch(describeUser(auth)),
    updateList: (auth, data) => dispatch(updateUserList(auth, data))
  };
};

const EMAIL_LISTS = [
  {
    list: 'Books',
    desc: 'Email featuring new books on the platform.'
  },
  {
    list: 'Authors',
    desc: 'Email featuring new authors on the platform.'
  },
  {
    list: 'Publishing',
    desc: 'Emails on author publishing services and features as they become available.'
  },
];

const styles = theme => ({
  gridRoot: {
    flexGrow: 1,
  },
  paper: {
    height: 400,
    width: 300,
    overflow: 'scroll'
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: blue[ 500 ],
  },
  container: {
    margin: 20
  },
  listRoot: {
    width: '100%',
    maxWidth: 360,
    maxHeight: 400,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
});

export class Account extends React.Component{

  state = {
    loading: false,
    transactions: [],
    error: null,
    open: false,
    checked: { books: false, authors: false, publishing: false },
  };

  handleEmailLists = (userDetails) => {
    if(userDetails && userDetails.emailLists){
      const checked = { ...this.state.checked };
      checked.books = userDetails.emailLists.books;
      checked.authors = userDetails.emailLists.authors;
      checked.publishing = userDetails.emailLists.publishing;
      this.setState({
        checked
      });
    }
  };

  componentDidMount() {
    if(!this.props.userDetails){
      this.props.fetchUser(this.props.auth);
    }
    this.loadTransactions();
    this.handleEmailLists(this.props.userDetails);
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.userDetails !== prevProps.userDetails){
      this.handleEmailLists(this.props.userDetails);
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  updateAccepted = () => {
    this.setState({
      user: {
        info: {
          acceptedTerms: true,
          acceptedDate: Date.now()
        }
      }
    });
  };

  handleToggle = value => () => {
    const { checked } = this.state;
    const newChecked = { ...checked };
    switch (value) {
    case 0:
      newChecked.books = !checked.books;
      this.props.updateList(this.props.auth, { update: 'lists', list: 'books', value: !checked.books });
      break;
    case 1:
      newChecked.authors = !checked.authors;
      this.props.updateList(this.props.auth, { update: 'lists', list: 'authors', value: !checked.authors });
      break;
    case 2:
      newChecked.publishing = !checked.publishing;
      this.props.updateList(this.props.auth, { update: 'lists', list: 'publishing', value: !checked.publishing });
      break;
    default: break;
    }
    this.setState({
      checked: newChecked,
    });
  };

  loadTransactions = () => {
    this.setState({
      loading: true,
      error: null,
    },
    () => {
      new BookcicleApi({ accessToken: this.props.auth.bookcicleAuth, apiKey: this.props.auth.apiKey }).get({
        path: `user/${ this.props.auth.userId }/purchase/books`,
        data: {
          action: 'list',
          details: true
        }
      },
      (error, result) => {
        if (error) {
          console.error(error);
          this.setState({
            error: (typeof error === 'string' || error instanceof String) ? error: error.toString(),
            loading: false
          });
        }
        else {
          this.setState({
            loading: false,
            error: null,
            transactions: result.Items
          });
        }
      });
    }
    );
  };

  render(){
    const { classes, auth, userDetails, userLoading, userError } = this.props;
    const { loading, transactions, error, open, checked } = this.state;
    const spacing = 40;
    return <div className={ classes.container }>
      <AcceptTerms user={ userDetails } open={ open } handleClose={ this.handleClose } updateAccepted={ this.updateAccepted } />
      <Grid>
        <Grid item xs={ 12 } >
          <Grid container justify="center" alignItems="center">
            <Avatar className={ classes.avatar }>
              <PersonIcon />
            </Avatar>
            <Typography variant="h6"> {auth.userDetails.displayName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <div style={ { margin: 10 } }>
        <Grid container className={ classes.gridRoot } spacing={ spacing }>
          <Grid item xs={ 12 }>
            <Grid container justify="center" spacing={ Number(spacing) }>
              {[ 0, 1, 2 ].map((value) => {
                if (value === 0) {
                  return <Grid key={ value } item>
                    <Paper className={ classes.paper }>
                      <List className={ classes.listRoot }>
                        <ListItem>
                          <Avatar style={ { backgroundColor: '#81D4FA' } }>
                            <InfoIcon />
                          </Avatar>
                          <ListItemText primary={
                            <Typography variant="h6" component="span">Info</Typography>
                          }/>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                          <Avatar>
                            <ContactMailIcon />
                          </Avatar>
                          <ListItemText primary="Email" secondary={ auth.userDetails.email }/>
                        </ListItem>
                        <ListItem>
                          <Avatar>
                            <AccountBoxIcon />
                          </Avatar>
                          <ListItemText primary="Username" secondary={ auth.username }/>
                        </ListItem>
                        {userLoading && <ListItem><Loader/></ListItem>}
                        {userError && <ListItem>
                          <Avatar>
                            <ErrorIcon />
                          </Avatar>
                          <ListItemText>Oh boy, we have an error. Try again later.</ListItemText>
                        </ListItem>}
                        {!userLoading && userDetails && <ListItem>
                          <Avatar>
                            {userDetails && !!userDetails.info.acceptedTerms && <CheckboxIcon />}
                            {userDetails && !userDetails.info.acceptedTerms && <CheckBoxOutlineBlank/>}
                          </Avatar>
                          <ListItemText
                            primary={ <span>Bookcicle Terms of Use.</span> }
                            secondary={ userDetails && !!userDetails.info.acceptedTerms ? <span>Last Accepted on {new Date(userDetails.info.acceptedDate * 1).toLocaleDateString('en-US')}</span> : <span style={ { color: 'lightblue', cursor: 'pointer' } } onClick={ this.handleClickOpen }>Accept Terms</span> }
                          />
                        </ListItem>}
                      </List>
                    </Paper>
                  </Grid>;
                } if (value === 1){
                  return <Grid key={ value } item>
                    <Paper className={ classes.paper }>
                      <List className={ classes.listRoot }>
                        <ListItem>
                          <Avatar style={ { backgroundColor: '#ea80fc' } }>
                            <EmailIcon />
                          </Avatar>
                          <ListItemText primary={
                            <Typography variant="h6" component="span">Email Lists</Typography>
                          }/>
                        </ListItem>
                        <Divider/>
                        {userLoading && <ListItem><Loader/></ListItem>}
                        {!userLoading && userDetails && [ 0, 1, 2 ].map(listValue => (
                          <ListItem key={ listValue } role={ undefined } dense button onClick={ this.handleToggle(listValue) }>
                            <Checkbox color="primary" checked={ parseToBool(checked[ EMAIL_LISTS[ listValue ].list.toLowerCase() ]) || false } />
                            <ListItemText primary={ EMAIL_LISTS[ listValue ].list } secondary={ EMAIL_LISTS[ listValue ].desc }/>
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Grid>;
                }
                else {
                  return <Grid key={ value } item>
                    <Paper className={ classes.paper }>
                      <List style={ { paddingBottom: 0 } }>
                        <ListItem>
                          <Avatar style={ { backgroundColor: '#80CBC4' } }>
                            <AccountBalanceIcon/>
                          </Avatar>
                          <ListItemText primary={ <Typography variant="h6" component="span">Billing History</Typography> }/>
                        </ListItem>
                        <Divider/>
                        {loading && <ListItem><Loader/></ListItem>}
                        {error && <ListItem>
                          <Avatar>
                            <ErrorIcon />
                          </Avatar>
                          <ListItemText>Oh boy, we have an error. Try again later.</ListItemText>
                        </ListItem>}
                      </List>
                      {!loading && !error && transactions && transactions.length > 0 && transactions.map(tnx => {
                        return <List className={ classes.listRoot } style={ { maxHeight: 200, paddingTop: 0 } } key={ tnx.transactionId }>
                          <ListItem alignItems="flex-start">
                            <ListItemText primary={
                              <React.Fragment>
                                <Typography component="span" className={ classes.inline } >
                                  {new Date(tnx.purchaseDate * 1000).toLocaleDateString('en-US')}
                                </Typography>
                                <Typography component="span" style={ { float: 'right', paddingTop: 3 } }>
                                  ${tnx.purchasePrice / 100}
                                </Typography>
                              </React.Fragment>
                            } secondary={
                              <React.Fragment>
                                <Typography component="span" color="textPrimary">
                                  {tnx.title}
                                </Typography>
                                <Typography component="span" variant="caption">
                                  by {tnx.author}
                                </Typography>
                                <Typography component="span" variant="caption">
                                  {tnx.transactionId}
                                </Typography>
                              </React.Fragment>
                            }/>
                          </ListItem>
                          <Divider variant="middle" />
                        </List>;
                      })}
                      {!loading && !error && (!transactions || transactions.length === 0) && <List>
                        <ListItem>
                          <Avatar>
                            <AnnouncementIcon />
                          </Avatar>
                          <ListItemText primary="No purchases yet..."/>
                        </ListItem>
                      </List>}
                    </Paper>
                  </Grid>;
                }
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>;
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Account));