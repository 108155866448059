import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper/Paper';
import classNames from 'classnames';
import { ListItem, withStyles } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';

import { AlertOctagon, Check } from 'mdi-material-ui';
import { WarningOutlined, InfoOutlined } from '@material-ui/icons';

import Close from '@material-ui/icons/Close';
import { List, ListItemIcon, ListItemText, Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    width: '100%',
  },
  success: {
    backgroundColor: green[ 600 ],
  },
  error: {
    backgroundColor: theme.palette.error.light,
  },
  info: {
    backgroundColor: theme.palette.primary.light,
  },
  warning: {
    backgroundColor: amber[ 700 ],
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  button: {
    display: 'flex',
    width: 50,
    height: 50,
  },
  message: {
    padding: 10,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
});

const variantIcon = {
  success: <Check/>,
  warning: <WarningOutlined/>,
  error: <AlertOctagon/>,
  info: <InfoOutlined/>,
};

class BookcicleErrorAlert extends React.Component {

  render(){
    const { variant, error, classes, className, handleClose } = this.props;
    let maxWidth = 300;
    let size = this.props.size;

    if (!size) size = 'md';

    switch (size) {
    case 'md':
      maxWidth = 400; break;
    case 'lg':
      maxWidth = 500; break;
    default: break;
    }

    return <div className={ classes.root } >
      <Grid>
        <Grid item xs={ 12 } >
          <Grid container justify="center" alignItems="center">
            <Paper className={ classNames(classes[ variant ], className) } elevation={ 1 } style={ { marginTop: 20, marginBottom: 20, maxWidth: maxWidth } }>
              <div>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      {variantIcon[ variant ]}
                    </ListItemIcon>
                    <ListItemText secondary={ error }/>
                    {handleClose && <IconButton aria-label="close" onClick={ handleClose } className={ classes.button }>
                      <Close />
                    </IconButton>}
                  </ListItem>
                </List>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>;
  }
}
BookcicleErrorAlert.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  error: PropTypes.string.isRequired,
  size: PropTypes.oneOf([ 'sm', 'md', 'lg' ]),
  variant: PropTypes.oneOf([ 'success', 'warning', 'error', 'info' ]).isRequired,
};
export default (withStyles(styles)(BookcicleErrorAlert));