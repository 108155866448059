import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import FormControl from '@material-ui/core/FormControl/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import Paper from '@material-ui/core/Paper/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';

import { TextDocForm } from '../../../routes/member/documentation/widgets/TextDocs';
import { DividerDocForm } from '../../../routes/member/documentation/widgets/DividerDocs';
import { theme } from '../../../theme';

const styles = () => ({
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  textField: {
    maxWidth: 400
  },
  paperContent: {
    margin: 20,
    padding: 20,
  },
  content: {
    height: 250,
  },
});

export class ReportAddWidgetDialog extends React.Component {

  state = {
    selection: '',
    textWidget: {
      widgetType: 'text',
      size: '20px',
      color: '#1769aa',
      fontFamily: 'Default',
      isBold: false,
      isItalic: false,
      align: 'left',
      justify: 'flex-start',
      text: 'Hey, I am a block of text that has emphasis and style!! You could put many of me in a row on a Paper widget or directly in your manuscripts. You can manipulate me more than you would your regular works.'
    },
    dividerWidget: {
      widgetType: 'divider',
      type: 'line',
      variant: 'middle',
      margin: 10,
      padding: 10,
    }
  };

  handleChange = name => event => {
    const value = event.target.value;
    this.setState({
      [ name ]: value
    });
  };

  handleTextChange = name => event => {
    let value = event.target.value;
    if (name.startsWith('is')) {
      value = value === 'true';
    }
    this.setState({
      textWidget: { ...this.state.textWidget, [ name ]: value }
    });
  };

  handleColorChange = (color) => {
    this.setState({
      textWidget: { ...this.state.textWidget, color: color }
    });
  };

  handleDividerChange = name => event => {
    let value = event.target.value;
    if (name === 'margin' || name === 'padding') {
      value = parseInt(value);
    }
    this.setState({
      dividerWidget: { ...this.state.dividerWidget, [ name ]: value }
    });
  };

  render() {
    const { openDialog, handleClose, handlePush, classes } = this.props;
    const { textWidget, dividerWidget, selection } = this.state;
    return <Dialog
      fullWidth={ true }
      maxWidth="sm"
      open={ openDialog }
      onClose={ handleClose }
    >
      <DialogTitle id="dialog">
        <FormControl variant="outlined" className={ classes.formControl }>
          <InputLabel
            htmlFor="widget"
          >
            Widget
          </InputLabel>
          <Select
            native
            style={ { margin: 8 } }
            value={ selection }
            onChange={ this.handleChange('selection') }
            input={
              <OutlinedInput
                labelWidth={ 80 }
                name="widget"
                id="widget"
              />
            }
          >
            <option></option>
            <option value="showText">Text Widget</option>
            <option value="showDivider">Divider Widget</option>
          </Select>
        </FormControl>
      </DialogTitle>
      <DialogContent>
        {selection !== '' && <Paper className={ classes.paperContent }>
          {selection === 'showText' && <TextDocForm widget={ textWidget } classes={ classes } handleChange={ this.handleTextChange } handleColorChange={ this.handleColorChange } />}
          {selection === 'showDivider' && <DividerDocForm widget={ dividerWidget } classes={ classes } handleChange={ this.handleDividerChange } />}
        </Paper>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose } color="primary">
          Close
        </Button>
        <Button onClick={ () => handlePush(selection === 'showText' ? textWidget : dividerWidget) } color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>;
  }
}
ReportAddWidgetDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handlePush: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReportAddWidgetDialog);