import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import amber from '@material-ui/core/colors/amber';
import { withStyles } from '@material-ui/core/styles/index';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import InfoIcon from '@material-ui/icons/Info';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[ 600 ],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[ 700 ],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  close: {
    maxHeight: '45px'
  }
});

class SnackbarAlerts extends React.Component {
  render() {
    const { classes, className, message, onClose, variant, ...other } = this.props;
    const Icon = variantIcon[ variant ];

    return <SnackbarContent
      className={ classNames(classes[ variant ], className) }
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={ classes.message }>
          <Icon className={ classNames(classes.icon, classes.iconVariant) }/>
          {message}
        </span>
      }
      action={ [
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={ classes.close }
          onClick={ onClose }
        >
          <CloseIcon className={ classes.icon }/>
        </IconButton>,
      ] }
      { ...other }
    />;
  }
}

SnackbarAlerts.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([ 'success', 'warning', 'error', 'info' ]).isRequired,
};

export default withStyles(styles1)(SnackbarAlerts);