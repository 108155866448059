import React from 'react';
import { Link } from 'react-router-dom';

function TOA(props) {
  const host = window.location.hostname;
  return (<div className={ props.none ? '' : 'reader' }  style={ { marginTop: '20px', textAlign: 'left' } }>
    <h2>Bookcicle, LLC Terms of Service</h2>
    <h3>Introduction</h3>
    <p>Prior to using any Bookcicle, LLC (hereinafter referred to as “<em>we</em>”, “<em>us</em>”, “<em>our</em>” or “<em>Bookcicle</em>”)
      application, services, or website and prior to signing in to our website, please read the following Terms of
      Service Agreement
      (these “<em>Terms</em>” or this “<em>Agreement</em>”). These Terms govern your (“<em>you</em>” or “<em>your</em>”)
      access to and use of the website <a href={ `http://${ host }.com` }>{host}</a> and the services owned, operated and
      provided by Bookcicle (collectively, the “<em>Site</em>”).
      Your access to and use of the Site are expressly conditioned on your acceptance of and compliance with these
      Terms.
    </p>
    <p>
      <strong>BY ACCESSING AND USING THE SITE IN ANY MANNER, YOU ARE ACCEPTING AND AGREEING TO BE BOUND BY THESE TERMS
        TO THE EXCLUSION OF ALL OTHER TERMS. IF YOU DO NOT UNCONDITIONALLY ACCEPT THESE TERMS IN THEIR ENTIRETY, YOU
        SHALL NOT (AND SHALL HAVE NO RIGHT TO) ACCESS OR USE THE SITE. IF THE TERMS OF THIS AGREEMENT ARE CONSIDERED
        AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS. ANY VIOLATION OF THESE TERMS MAY RESULT IN
        TERMINATION OF YOUR ABILITY TO ACCESS AND USE THE SITE AND YOUR RIGHTS IN CONNECTION WITH THE SITE. THESE
        TERMS</strong> <strong>SHOULD BE READ</strong> <strong>IN CONJUNCTION WITH <Link style={ { color: '#2196f3' } } to="/privacy">BOOKCICLE’S PRIVACY
          POLICY.</Link></strong>
    </p>
    <p>Bookcicle reserves the right to change all or any
      part of these Terms, the Site and the fees or charges for use of the Site at any time. Any such change(s) will be
      effective immediately upon notice, which may be provided by any means, including, without limitation, e-mail
      correspondence or a post on the Site. Your continued use of the Site after such notice is provided shall
      constitute your acceptance of such changes.
    </p>
    <h3>Sign-in</h3>
    <p>At the time that you
      sign in to the Site, you agree to the following:
    </p>
    <ol>
      <li>That you are at least thirteen (13) years old.</li>
      <li>That the information you provide is accurate, current and complete (including your valid email address).
      </li>
      <li>That you will maintain and update your information (including your valid email address) to keep it accurate,
        current and complete.
      </li>
    </ol>
    <p>You acknowledge that if any information provided by you is untrue, inaccurate, not current or incomplete and as
      a result you do not receive communications we have attempted to send to you, then you cannot claim a lack of
      notice regarding such communications. You further acknowledge that such communications could materially affect
      your rights under these Terms, and that Bookcicle reserves the right to terminate your access to and use of the
      Site at any time.
    </p>
    <p>We may refuse to grant you a username for any reason in our sole discretion. You will be responsible
      for the confidentiality and use of your username and password and agree not to disclose your username and password
      or transfer or sell your use of or access to the Site to any third party. YOU ARE ENTIRELY RESPONSIBLE FOR
      MAINTAINING THE CONFIDENTIALITY OF YOUR USERNAME AND PASSWORD AND FOR ANY AND ALL ACTIVITIES (INCLUDING PURCHASES,
      AS APPLICABLE) THAT ARE CONDUCTED THROUGH YOUR ACCOUNT.
    </p>
    <h3>Use of the Site</h3>
    <p>
      Subject to and conditioned upon your compliance with these Terms, and solely for so long as you are permitted
      by Bookcicle to access to the Site, we grant to you a non-exclusive, non-transferable, non-sub-licensable,
      limited right and license to access the Site, including any text, images, graphics, videos, visuals, sounds,
      data, files, links and other materials incorporated into the Site (other than your Content (as herein defined)),
      solely as made available by us, solely as necessary to access the Site and solely for your own purposes,
      provided that you keep intact all copyright and other proprietary notices. The Site, including all such
      materials and all intellectual property rights therein, remains the property of Bookcicle or its licensors or
      suppliers. Except as expressly authorized by these Terms, you may not use, reproduce, distribute, modify,
      transmit, perform, display or create derivative works of any portion of the Site without the prior written
      consent of Bookcicle. Nothing herein grants any rights to commercially exploit any portion of the Site or any
      content therein. All rights not expressly granted hereunder are expressly reserved.
    </p>
    <p>
      You acknowledge that
      technical, maintenance and other issues may make the site unavailable from time to time. BOOKCICLE DOES NOT
      WARRANT OR GUARANTEE THAT THE SITE WILL OPERATE IN A TIMELY, UNINTERRUPTED OR ERROR-FREE MANNER, OR THAT THE SITE
      WILL MEET YOUR PURPOSES. Bookcicle reserves the right to modify the Site, including its availability and features,
      at any time without notice.
    </p>
    <p>
      You acknowledge and agree that your use of the Site shall comply with all
      applicable laws, rules and regulations. Further, you agree that, in using the Site, you shall not:</p>
    <ol>
      <li>Defame, abuse, harass, stalk, threaten or otherwise violate the rights of others, including, without
        limitation, others' privacy rights or rights of publicity;
      </li>
      <li>Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with any person
        or entity;
      </li>
      <li>Restrict or inhibit any other user from using the Site, including, without limitation, by means of hacking
        or defacing any portion of the Site;
      </li>
      <li>Express or imply that any statements you make are endorsed by us without our prior written consent;</li>
      <li>Upload to, transmit through or display on the Site:
        <ul>
          <li>Any material that is unlawful, fraudulent, threatening, abusive, libelous, defamatory, obscene or
            otherwise objectionable, or that infringes our or any third party's intellectual property or other rights;
          </li>
          <li>Any advertisements, solicitations, chain letters, pyramid schemes, investment opportunities or other
            unsolicited commercial communication (except as otherwise expressly permitted by us);
          </li>
          <li>Any confidential, proprietary, trade secret or other intellectual property right information of any
            third party; or
          </li>
          <li>Any material that you do not own all intellectual property right in;</li>
        </ul>
      </li>
      <li>Use the Site to harm minors in any way, including, without limitation, by uploading content that violates
        child pornography laws, child sexual exploitation laws and laws prohibiting the depiction of minors engaged in
        sexual conduct;
      </li>
      <li>Engage in spamming or flooding;</li>
      <li>Attempt to upload images by any method to another user's account without permission;</li>
      <li>Access or use (or attempt to access or use) another user's content without permission;</li>
      <li>Transmit any software or other materials that contain any viruses, worms, Trojan horses, defects, date
        bombs, time bombs or other items of a destructive nature;
      </li>
      <li>Modify, adapt, sublicense, translate, sell, reverse engineer, decompile or disassemble any portion of the
        Site;
      </li>
      <li>Remove any copyright, trademark or other proprietary rights notices contained in or displayed on any portion
        of the Site;
      </li>
      <li>“Frame” or “mirror” any portion of the Site, or link to any page of or material on the Site other than the
        URLs provided by us to you for such purposes as part of the Site, without our prior written authorization;
      </li>
      <li>Use any robot, spider, site search/retrieval application or other manual or automatic device or process to
        retrieve, index, “data mine” or in any way reproduce or circumvent the navigational structure or presentation
        of the Site; or
      </li>
      <li>Harvest or collect information about or from users of the Site without their express consent and, if such
        consent is provided, only pursuant to applicable law.
      </li>
    </ol>
    <p>FAILURE TO COMPLY WITH THE ABOVE RULES MAY RESULT IN A SUSPENSION OR TERMINATION OF YOUR ACCESS TO AND USE OF
      THE SITE. ANY SUCH DETERMINATION SHALL BE MADE AT BOOKCICLE’S SOLE DISCRETION.
    </p>
    <h3>Content</h3>
    <p>
      If you post or upload to the Site in compliance with the “<strong><em>Use
        of the Site</em></strong>” section set forth above, or otherwise submit to or through Bookcicle as part of your
      use of the Site, any materials including, without limitation, text, photographs and other images, graphics,
      videos, visuals, sounds, data, files, links and other materials (collectively, “<em>Content</em>”), then you will
      retain ownership of such Content. You hereby grant us and our designees a worldwide, non-exclusive, sub-licensable
      (through multiple tiers), assignable, royalty-free (except as otherwise agreed during the online publishing
      process) right to use, reproduce, distribute (through multiple tiers) and publicly display such Content, solely in
      connection with the Site. You acknowledge and agree that any template or layout in which you arrange or organize
      such Content through tools and features made available through Bookcicle are not proprietary to you, that
      Bookcicle may use such tools and features for any purpose, and that you have no rights in any such template and/or
      layout, and such template or layout shall be the sole and exclusive property of Bookcicle.
    </p>
    <p>
      You represent, warrant and covenant that:
    </p>
    <ol>
      <li>You own or otherwise possess all necessary rights with respect to your Content;</li>
      <li>Your Content does not and will not infringe, misappropriate, use or disclose without authorization, or
        otherwise violate any intellectual property or proprietary right of any third party, and are not unlawful,
        fraudulent, threatening, abusive, libelous, defamatory, obscene or otherwise objectionable;
      </li>
      <li>At Bookcicle’s request, and without further consideration, you will promptly provide Bookcicle with
        reasonable evidence of such adequate and enforceable rights of third parties (i.e., consents, approvals,
        licenses or sublicenses), and agree that Bookcicle may provide a copy of these Terms to anyone claiming an
        infringement of copyright or trademark relating to your Content or to law enforcement or other entities as
        required by law or as Bookcicle reasonably determines to be necessary to protect its rights or the rights of
        others;
      </li>
      <li>You will abide by any content policy and guidelines posted on the Site; and</li>
      <li>You hereby consent to the use of your likeness, and you have obtained the written consent, release, or other
        permission of every identifiable individual who appears in your Content to use such individual's likeness, for
        purposes of using and otherwise exploiting the Content in the manner contemplated by these Terms (including
        for purposes of promoting the Site), or, if any such identifiable individual is under the age of eighteen
        (18), then you have obtained such written consent, release or other permission from such individual's parent
        or guardian (and you agree to provide to Bookcicle a copy of any such consent, release or other permission
        upon Bookcicle’s request). If you do submit Content that contains the likeness of an identifiable individual
        who is under the age of eighteen (18), we strongly encourage you not to include any identifying information
        (such as the individual's name or address) with such Content. Any violation of this Section, as determined in
        Bookcicle’s sole discretion, shall be cause to remove any and all of your Content from the Site and from
        applicable distribution channels, and to terminate your account and access to the Site.
      </li>
    </ol>
    <p>You agree and acknowledge that Bookcicle may, with respect to any Content and at its sole discretion, do any or
      all of the following:</p>
    <ol>
      <li>Monitor and filter any Content (including, without limitation, by means of blocking or replacing expletives
        or other language that may be deemed harmful or offensive);
      </li>
      <li>Alter, remove or refuse to send, transmit or otherwise use any Content (including, without limitation, by
        suspending the processing and shipping of any order relating to any Content); and
      </li>
      <li>Disclose any Content, and the circumstances surrounding the transmission or use thereof, to any third party
        in order to operate the services on the Site; to protect us and our affiliates, distributors, partners,
        licensors, advertisers and sponsors, and our and their directors, officers, employees, consultants, agents,
        other representatives, and users; comply with legal obligations or governmental requests; to enforce these
        Terms; or for any other reason or purpose.
      </li>
    </ol>
    <p>Please note that Bookcicle is not responsible for, and shall not assume any liability for, the removal or
      non-removal of Content from the Site and is not obligated to act in response to a report or request from any
      user. Further, we recommend that you store copies of your Content separate from the Site, as the Site is not
      intended to function as a back-up system for your Content.
    </p>
    <h3>Purchases</h3>
    <p>
      In
      order to purchase any products or services through the Site, you must accept these Terms. At the time of any
      purchase, we shall ask you to supply certain information applicable to the purchase, including, without
      limitation, payment information. All information that you provide to us (or any third-party payment processor we
      utilize) must be accurate; all such information shall be treated in the manner described in our <Link style={ { color: '#2196f3' } } to="/privacy">Privacy Policy</Link>.
      YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY CREDIT CARD(S) OR OTHER
      PAYMENT MEANS USED TO INITIATE ANY TRANSACTION. You agree to pay all charges incurred by you or any users of your
      account and credit card (or other applicable payment mechanism) at the prices in effect when such charges are
      incurred. You will also be responsible for paying any applicable taxes relating to your purchases. Verification of
      information applicable to a purchase may be required prior to our acceptance of any order.
    </p>
    <p>
      Descriptions,
      images, references, features, content, specifications, products, price and availability of any products or
      services are subject to change without notice. <br/>Our current prices can be found on the Site. The inclusion of
      any products or services on the Site at a particular time does not imply or warrant that these products or
      services will be available at any time. It is your responsibility to ascertain and obey all applicable local,
      state, federal and international laws (including minimum age requirements) in regard to the possession, use and
      sale of any item purchased through the Site. By placing an order, you represent that the products ordered will be
      used only in a lawful manner. We reserve the right, with or without prior notice, to: limit the available quantity
      of or discontinue any product or service; honor, or impose conditions on the honoring of any coupon, coupon code,
      promotional code or other similar promotions; bar any user from making any or all purchase(s); and refuse to
      provide any user with any product or service.
    </p>
    <p>
      Title and risk of loss for any purchases pass to you upon our
      delivery to our carrier. We reserve the right to ship partial orders (at no additional cost to you), and the
      portion of any order that is partially shipped may be charged at the time of shipment.
    </p>
    <h3>Copyright</h3>
    <p>
      The Digital Millennium Copyright Act of 1998 (the “<em>DMCA</em>”)
      provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights
      under U.S. copyright law. If you believe in good faith that any material used or displayed on or through the Site
      infringes your copyright, then you may send us a notice requesting that the material be removed or access to it
      blocked. The notice must include the following information: </p>
    <ol>
      <li>Identification of the copyrighted work claimed to have been infringed (or if multiple copyrighted works are
        covered by a single notification, a representative list of such works);
      </li>
      <li>Identification of the material that is claimed to be infringing or the subject of infringing activity and
        information reasonably sufficient to allow us to locate the material on the Site;
      </li>
      <li>The name, address, telephone number and email address (if available) of the complaining party;</li>
      <li>A statement that the complaining party has a good faith belief that use of the material in the manner
        complained of is not authorized by the copyright owner, its agent or the law;
      </li>
      <li>A statement that the information in the notification is accurate and, under penalty of perjury, that the
        complaining party is either the owner or is authorized to act on behalf of the owner of an exclusive right
        that is allegedly infringed; and
      </li>
      <li>A physical or electronic signature of the owner or a person authorized to act on behalf of the owner of an
        exclusive right that is allegedly infringed.
      </li>
    </ol>
    <p>In the event that you believe, in good faith, that a notice of copyright infringement has been wrongly filed
      against you, the DMCA permits you to send us a counter-notice. Notices and counter-notices must meet the
      then-current statutory requirements imposed by the DMCA; see <a
      href="http://www.copyright.gov/">http://www.copyright.gov/</a> for details. DMCA notices and counter-notices
      regarding the Site should be sent to:
    </p>
    <pre><code>support@bookcicle.com</code></pre>
    <p>
      In the event that Bookcicle removes Content in response to a
      DMCA notice (a “<em>DMCA Takedown</em>”), we shall investigate and reserve the right to ban the user whose Content
      violated the DMCA. Further, in the event of a DMCA Takedown, the user who violated the DMCA shall be solely liable
      to Bookcicle and other users for applicable refunds.
    </p>
    <h3>Publishing</h3>
    <p>
      In the
      event that you publish Content through <a href={ 'http://{host}.com' }>{host}</a>, you will generally
      direct the process for how your Content is published and the conditions for distribution and sale of the Content.
      The publishing process will convert your Content into a proprietary format
      for use solely on Bookcicle.com, and is unavailable and unusable for other distributors of published works.</p>
    <p>Bookcicle shall have the right to distribute samples of your Content in any form, including printed media, in
      order to promote you and your Content or to promote Bookcicle’s services. Bookcicle shall also have the right to
      reserve a portion of the back cover of a book of any published Content for placement of a unique identifying
      number and barcode (this number may be the Content’s ISBN number, if an ISBN number is assigned), and to delete
      Content from Bookcicle’s database after a defined period of time, as set forth in the then current policies of
      Bookcicle.
    </p>
    <p>
      You shall remain the sole owner and copyright holder of your Content. As such, you shall have
      the right to remove any of your Content from the Site; <em>provided</em>, <em>however</em>, that any copies of
      your Content purchased by another user shall continue to be available to that user.
    </p>
    <h3>Revenue</h3>
    <p>
      As used herein and for the sale of Content through the Site:
    </p>
    <ol>
      <li>
        “<em>Royalty Revenue</em>” means the percentage of Net Revenue (as herein defined) due to you as
        author-publisher for Content which was sold and paid for in full. Subject to specific arrangements with
        distribution channel partners, the following Royalty Revenue percentage generally applies:
        <ul>
          <li><strong>Royalty Revenue</strong> - for sales of electronic material – up to 80% - of Total Revenue.</li>
          <li><strong>Ad Revenue</strong> – for ads placed and revenue generated directly relating to your content, up to 30%.</li>
        </ul>
      </li>
      <li>
        “<em>Gross Selling Price</em>” means the Sales List Price (as herein defined) after any discount that you as
        an author-publisher would like to include in order to promote your Content, if applicable.
      </li>
      <li>
        “<em>Royalty</em>” means Creator Revenue that is reported to the U.S. Internal Revenue Service (the
        “<em>IRS</em>”), if applicable.
      </li>
      <li>
        “<em>Sales List Price</em>” means the price determined by you as author-publisher for the Content, subject
        to variances in international manufacturing costs. The Sales List Price will be the price listed in any
        distribution channel.
      </li>
    </ol>
    <p>
      If you have published and sold Content on the Site, the amount of Creator Revenue you are owed will be visible
      in your publishing dashboard. Bookcicle shall make monthly payments to users whose accounts contain Creator
      Revenue in excess of One Hundred Dollars ($100). Users with accounts containing less than One Hundred Dollars
      ($100) in Creator Revenue may request that Bookcicle pay out the balance of their account at any time; Bookcicle
      shall comply with any such request within thirty (30) days’ receipt of the request. Any payment made by
      Bookcicle may be made via paper check or digital distribution at Bookcicle’s sole discretion.
    </p>
    <p>
      It is your
      responsibility to update your contact information. If we are unable to contact you using the contact information
      provided, then we may, at our sole discretion, charge a termination fee equal to the amount of unpaid Creator
      Revenue to cover administrative costs.
    </p>
    <p>
      In order to use the basic services provided by the Site, you are not
      required to provide any taxpayer information. However, based on withholding that may be required by the IRS, the
      payment you receive may be less than the Creator Revenue. You are also responsible for any taxes, fees and
      penalties associated with your receipt of Creator Revenue or sale of your Content you agree to indemnify Bookcicle
      for any such tax, fee or penalty.
    </p>
    <p>
      You are responsible to any third party for payment of royalties,
      commissions, etc. that may be owed to them based upon agreements you have made with a third party and for
      liabilities you have incurred to third parties.
    </p>
    <p>
      Bookcicle reserves the right to place any payment on hold
      based upon any perceived suspicious activity, including, without limitation, potential fraud, copyright
      infringement or other violations of these Terms, claims by third parties or questions regarding rightful ownership
      of an account.
    </p>
    <h3>Third Parties</h3>
    <p>
      The Site may direct you to websites, software
      or services owned or operated by third parties (“<em>Third-Party Properties</em>”). Third-Party Properties to
      which you may be directed operate under their own Terms and we have no control over such Third-Party Properties.
      We are not responsible for the content and operation of such Third-Party Properties or for the privacy or other
      practices of such Third-Party Properties. The fact that the Site directs you to such Third-Party Properties does
      not indicate any approval or endorsement of any Third-Party Properties. We direct you to such Third-Party
      Properties only as a convenience. You are responsible for the costs associated with such Third-Party Properties,
      including any applicable license fees and service charges. Accordingly, we strongly encourage you to become
      familiar with the terms of use and practices of any such Third-Party Properties.
    </p>
    <p>
      Other websites may provide
      links to the Site with or without our authorization. We do not endorse such sites, and are not and shall not be
      responsible or liable for any links from those sites to the Site, any content, advertising, products or other
      materials available on or through such other sites, or any loss or damages incurred in connection therewith.</p>
    <p>YOU AGREE THAT YOUR USE OF THIRD-PARTY WEBSITES AND RESOURCES, INCLUDING WITHOUT LIMITATION, YOUR USE OF ANY
      CONTENT, INFORMATION, DATA, ADVERTISING, PRODUCTS OR OTHER MATERIALS ON OR AVAILABLE THROUGH SUCH WEBSITES AND
      RESOURCES IS AT YOUR OWN RISK AND IS SUBJECT TO THE TERMS AND CONDITIONS OF USE APPLICABLE TO SUCH SITES AND
      RESOURCES.
    </p>
    <p>
      Bookcicle shall have the right, at any time and in its sole discretion, to block links to the
      Site through technological or other means without prior notice.
    </p>
    <h3>Returns and Refunds </h3>
    <p>
      No user shall be entitled to a refund from Bookcicle for any Content purchased on the
      Site. In the event that a user receives a book that is damaged, Bookcicle may provide a replacement at its sole
      discretion upon receipt of sufficient photographic evidence. Bookcicle does not assume responsibility for the
      quality of Content hosted or purchased from the Site or for the formatting, design, or overall appearance of the
      Content. Bookcicle does not approve the visual presentation of Content and does not monitor or approve individual
      Content prior to publication or sale through the Site.
    </p>
    <h3>Removing Content; Termination of Account</h3>
    <p>
      As stated above, you may choose at any time to remove your published Content from the
      Site. Should you do so, that Content will no longer be visible to other users of the Site and will not be
      available for sale on the Site. Additionally, such Content will be deleted from Bookcicle’s database after a
      defined period of time, as set forth in the then current policies of Bookcicle.
    </p>
    <p>
      You may terminate your
      account by contacting us at: </p>
    <pre><code>support@bookcicle.com</code></pre>
    <p>All Creator Revenue owed to you shall be paid within thirty
      (90) days of termination. Bookcicle shall retain all or any part of your information for as long as is reasonably
      required to fulfill the purposes for which it was collected.
    </p>
    <h3>Restrictions on Use of Content</h3>
    <p>
      If you purchase Content from the Site (including the receipt of any free Content),
      then you thereby agree to the following restrictions with respect to such acquired Content and the information
      contained therein:</p>
    <ol>
      <li>You shall not resell any other Content or otherwise profit from its use or display;</li>
      <li>You shall not share, copy, adapt, redistribute, reconfigure, modify, or create derivative works from the
        Content <em>unless</em> expressly permitted in writing by the Content Owner of the Content and only to the
        extent so permitted. You agree to abide by any restrictions included with the Content;
      </li>
      <li>The Content owners who sell Content through Bookcicle retain ownership of the copyrights or other licenses
        in the Content. You agree not to allow any other party to: resell, redistribute, sublicense, assign, delegate
        or otherwise transfer the Content, any part thereof, or any related rights or obligations hereunder, to any
        third party except as may be expressly allowed in the terms under which the Content is provided to you; and
      </li>
      <li>You will not remove or obscure any proprietary rights notices contained in or on the Content.</li>
    </ol>
    <h3>Privacy</h3>
    <p>
      In general, Bookcicle collects information when you sign in to the
      Site, use the Site or otherwise visit pages on the Site. For a complete description of how Bookcicle collects and
      uses its users’ information, please see <Link style={ { color: '#2196f3' } } to="/privacy">Bookcicle’s Privacy Policy</Link>.
    </p>
    <h3>Indemnification</h3>
    <p>
      You agree to indemnify, defend and hold harmless Bookcicle and its
      distributors, partners and licensors, and our their directors, officers, employees, consultants, agents and other
      representatives, from and against any and all claims, damages, losses, costs (including reasonable attorneys'
      fees) and other expenses that arise directly or indirectly out of or from:</p>
    <ol>
      <li>Your breach of these Terms, including any violation of the rules set forth in the “<strong><em>Use of the
        Site</em></strong>” section above;
      </li>
      <li>Your Content (including, without limitation, any and all third party infringement claims or violations of
        privacy rights);
      </li>
      <li>Your activities in connection with the Site (including, without limitation, any and all purchases);</li>
      <li>Any taxes, fees or penalties associated with your receipt of Creator Revenue or the sale of your Content;
        or
      </li>
      <li>Any combination of (a)-(d).</li>
    </ol>
    <p>You agree not to do anything to intentionally prejudice the rights granted hereunder, but in the event that you
      lose any rights, licenses, consents or permissions relating to specific Content that are necessary for you to
      grant the rights you grant to us hereunder, or you receive notice of a third-party claim relating to Content
      which you reasonably deem to be of concern, you shall immediately retire or delete such Content from our
      Site.
    </p>
    <p>
      Notwithstanding anything contained herein to the contrary, you shall use commercially reasonable
      efforts to maintain the rights to the Content that you provide to us pursuant to these Terms.
    </p>
    <h3>Disclaimer of Warranties</h3>
    <p>
      THE SITE, INCLUDING, WITHOUT LIMITATION, ALL MATERIALS INCORPORATED THEREIN AND
      ALL FEATURES AND FUNCTIONALITY THEREOF, IS PROVIDED “AS IS,” “AS AVAILABLE” AND WITHOUT WARRANTIES OF ANY KIND,
      EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, BOOKCICLE AND ITS DISTRIBUTORS,
      PARTNERS AND LICENSORS, AND OUR AND THEIR DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, AGENTS AND OTHER
      REPRESENTATIVES, DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
      TITLE, NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES THAT
      MAY ARISE FROM COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE. WE, OUR AFFILIATES, DISTRIBUTORS,
      PARTNERS AND LICENSORS, AND OUR AND THEIR DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, AGENTS AND OTHER
      REPRESENTATIVES, DO NOT WARRANT THAT YOUR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY, ERROR-FREE OR SECURE,
      THAT DEFECTS WILL BE CORRECTED OR THAT THE SITE, THE SERVER(S) ON WHICH THE SITE IS OFFERED OR ANY SOFTWARE
      INCORPORATED INTO THE SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOU ASSUME ALL RESPONSIBILITY AND RISK
      FOR YOUR USE OF THE SITE AND YOUR RELIANCE THEREON. NO OPINION, ADVICE OR STATEMENT OF BOOKCICLE OR ITS
      DISTRIBUTORS, PARTNERS AND LICENSORS, AND OUR AND THEIR DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, AGENTS AND
      OTHER REPRESENTATIVES, WHETHER MADE ON OR THROUGH THE SITE OR OTHERWISE, SHALL CREATE ANY WARRANTY. <strong>YOU
        AGREE AND ACKNOWLEDGE THAT</strong> <strong>YOUR USE OF THE SITE IS ENTIRELY AT YOUR OWN RISK.</strong>
    </p>
    <h3>Limitation of Liability</h3>
    <p>
      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER
      BOOKCICLE NOR ANY OF OUR DISTRIBUTORS, PARTNERS, OR LICENSORS, NOR OUR OR THEIR DIRECTORS, OFFICERS, EMPLOYEES,
      CONSULTANTS, AGENTS OR OTHER REPRESENTATIVES, ARE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL,
      CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
      BUSINESS, LOSS OF DATA OR LOST PROFITS), UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY ARISING
      OUT OF OR RELATING IN ANY WAY TO THE SITE (INCLUDING, WITHOUT LIMITATION, ALL MATERIALS INCORPORATED THEREIN AND
      ALL FEATURES AND FUNCTIONALITY OF THE SITE). YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SITE IS
      TO STOP USING THE SITE. ADDITIONALLY, BOOKCICLE SHALL NOT BE LIABLE FOR THE ACTIONS OF ITS USERS, INCLUDING,
      WITHOUT LIMITATION, COPYRIGHT VIOLATIONS OR TORTIOUS OR CRIMINAL CONDUCT COMMITED BY USERS OF THE SITE. TO THE
      FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE SOLE, EXCLUSIVE AND MAXIMUM LIABILITY OF BOOKCICLE ARISING OUT OF
      OR IN CONNECTION WITH THESE TERMS OR THE SITE, WHETHER IN CONTRACT, TORT (INCLUDING, WITHOUT LIMITATION,
      NEGLIGENCE) OR OTHERWISE, SHALL BE ONE HUNDRED DOLLARS ($100).
    </p>
    <p>
      BOOKCICLE DOES NOT PROVIDE LEGAL, FINANCIAL,
      TAX, ACCOUNTING, OR OTHER PROFESSIONAL SERVICE ADVICE AND PROHIBITS YOUR USE OF ANY COMMUNICATION OR INFORMATION
      FROM BOOKCICLE FOR SUCH PURPOSES.
    </p>
    <h3>Intellectual Property</h3>
    <p>
      Bookcicle is the
      owner of all intellectual property rights associated with the Site, including all copyright, patents and
      trademarks, with all rights reserved, exclusive of Content provided by third parties, which is owned by the
      licensors of such Content. Bookcicle owns the design, format and layout of the Site. The Bookcicle logo(s) and the
      names of all Bookcicle products and services as posted on the Site are trademarks, service marks or registered
      trademarks or service marks of Bookcicle.
    </p>
    <p>
      All trademarks and service marks on the Site not owned by us are
      the property of their respective owners. The trade names, trademarks and service marks owned by us, whether
      registered or unregistered, may not be used in connection with any product or service that is not ours, in any
      manner that is likely to cause confusion. Nothing contained on the Site should be construed as granting, by
      implication, estoppel or otherwise, any license or right to use any of our trade names, trademarks or service
      marks without our express prior written consent.
    </p>
    <p>
      FOR AVOIDANCE OF DOUBT, AND WITHOUT LIMITING THE FOREGOING,
      YOU HAVE NO RIGHT TO POST, MAKE AVAILABLE TO OTHERS OR OTHERWISE DISTRIBUTE IN ANY FORMAT ANY ELECTRONIC MATERIALS
      DOWNLOADED FROM THE SITE OR ANY DERIVATIVE WORKS OF SUCH MATERIALS.
    </p>
    <h3>Governing Law and Jurisdictional Issues</h3>
    <p>
      These Terms shall be governed and construed in accordance with the laws
      of the State of New Hampshire, without giving effect to its conflict of laws provisions. You agree that any
      dispute concerning the subject matter of these Terms shall be resolved only by litigation in applicable New
      Hampshire state courts or federal courts in the District of New Hampshire of proper jurisdiction and venue. You
      agree to submit to such exclusive jurisdiction and venue for all purposes hereunder.
    </p>
    <p>
      The Site is published,
      controlled and operated in the United States of America, and is not intended to subject Bookcicle to the laws or
      jurisdiction of any state, country or territory other than that of the United States of America. Bookcicle does
      not represent or warrant that the Site or any part thereof is appropriate or available for use in any particular
      jurisdiction. Those who choose to access the Site do so on their own initiative and at their own risk and are
      responsible for complying with all local laws, rules and regulations. Bookcicle may limit the Site's availability,
      in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in its sole
      discretion.
    </p>
    <p>
      Bookcicle makes no representation that material on the Site is appropriate to or available at
      locations outside of the United States. You may not use the Site or export the Contents in violation of U.S.
      export regulations. If you access this Site from a location outside of the United States, you are responsible for
      compliance with all applicable local laws and payment of any local taxes that may be payable in connection with
      any purchase from the Site.
    </p>
    <h3>Term and Termination</h3>
    <p>
      These Terms shall remain
      effective until terminated as set forth herein. Bookcicle may, in its sole discretion, immediately terminate these
      Terms or your access to and use of the Site or any portion thereof, at any time and for any reason, including,
      without limitation, if we believe that you have violated or acted inconsistently with the letter or spirit of
      these Terms. Upon termination of these Terms, your right to access and use the Site will immediately cease. You
      agree that any termination of your access to or use of the Site may be effected without prior notice and that we
      may immediately deactivate or delete your password, user name and all related information and files associated
      with it (including all Content), and that we may bar any further access to such information or files. You agree
      that we shall not be liable to you or any third party for any termination of your access to the Site or to any
      such information or files, and that we shall not be required to make such information or files available to you
      after any such termination.
    </p>
    <h3>General Provisions</h3>
    <p>
      The provisions of these Terms
      that relate to disclaimer of warranties, limitations of liability, indemnification and intellectual property shall
      survive termination of these Terms.
    </p>
    <p>
      Except for delay in payments due, if anything happens that is beyond
      reasonable control of either party (by way of example: wars, sabotage, riots, labor disturbance, failure or delay
      of transportation, terrorist act, severe weather, natural disaster, act of God, etc.), then delays will be
      expected and performance may be excused. Bookcicle and any of its subcontractors or suppliers shall not be liable
      for any delay caused by the occurrence of such an event beyond their reasonable control.
    </p>
    <p>
      These Terms do not
      designate either party as the agent, employee, legal representative, partner or joint venturer of the other party
      for any purpose whatsoever.
    </p>
    <p>
      References to business or distribution channel partners in connection with
      Bookcicle’s operations refers to organizations with which Bookcicle has business relationships and does not imply
      formal legal partnerships.
    </p>
    <p>
      Every provision of these Terms is intended to be severable. If any provision of
      these Terms is found to be invalid or unenforceable by a court of competent jurisdiction and venue, then such
      provision shall be deemed amended and interpreted, if possible, in a way that renders it enforceable. If such an
      interpretation is not possible, then the provision shall be deemed removed from these Terms and the rest of these
      Terms shall remain in full force and effect.
    </p>
    <p>
      No waiver by either party of any breach or default hereunder
      shall be deemed to be a waiver of any preceding or subsequent breach or default, nor shall any delay or omission
      on the part of either party to exercise or avail itself of any right or remedy that it has or may have hereunder
      operate as a waiver of any right or remedy.
    </p>
    <p>
      This is the entire agreement between the parties relating to the
      subject matter herein and supersedes any and all prior or contemporaneous written or oral agreements between the
      parties with respect to such subject matter.
    </p>
    <p>
      These Terms are not assignable, transferable, or sub-licensable
      by you (including in connection with a change of control or by operation of law) except with Bookcicle’s prior
      written consent.
    </p>
    <p>
      These Terms may not be modified or amended by you except with Bookcicle’s prior written
      consent.
    </p>
    <p>
      Any heading, caption or section title contained in these Terms is inserted only as a matter of
      convenience and in no way defines or explains any section or provision hereof.
    </p>
    <h3>Updates or Amendments to these Terms</h3>
    <p>
      We reserve the right to periodically amend or revise these Terms.
      Material changes shall be effective immediately upon the display of the revised Terms. The last revision will be
      reflected by the date given in the “<strong><em>Last Modified</em></strong>” section below. Your continued use of
      the Site, following the notification of such amendments on the Site, constitutes your acknowledgment and
      acceptance of such amendments to these Terms and your agreement to be bound by the terms of such amendments.</p>
    <h3>Contact</h3>
    <p>
      If you have any general questions about the Site or these Terms, then
      you can contact us at:</p>
    <pre><code>info@bookcicle.com</code></pre>
    <h3>Last Modified</h3>
    <pre><code>03/30/2019</code></pre>
  </div>
  );

}

export default TOA;