import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import InfoIcon from '@material-ui/icons/Info';
import ShareIcon from '@material-ui/icons/Share';
import GearIcon from '@material-ui/icons/Settings';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Snackbar from '@material-ui/core/Snackbar';

import SnackbarAlerts from '../../../components/SnackbarAlerts';
import ChapterDrawer from './ChapterDrawer';
import DetailDrawer from './DetailsDrawer';
import ConfigurationDrawer from './ConfigurationDrawer';
import ShareModal from '../../../components/ShareModal';
import IconButton from '@material-ui/core/IconButton';
import {
  isMobile
} from 'react-device-detect';
import { getAuth } from '../../../reducers/application';
import connect from 'react-redux/src/connect/connect';

const mapStateToProps = (state) => ({
  ...state.book,
  auth: getAuth(state),
});

const styles = theme => ({
  root: {
    height: 0,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing.unit * 5,
    right: theme.spacing.unit * 2,
  },
  chapters: {
    backgroundColor: '#e040fb',
    maxHeight: 48,
    maxWidth: 48
  },
  share: {
    backgroundColor: '#00BFA5',
    maxHeight: 48,
    maxWidth: 48
  },
  details: {
    backgroundColor: '#448AFF',
    maxHeight: 48,
    maxWidth: 48
  },
  settings: {
    backgroundColor: '#FFCCBC',
    maxHeight: 48,
    maxWidth: 48
  },
  touch: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});

const actions = [
  { icon: <InfoIcon/>, name: 'Book Details' },
  // { icon: <SettingsIcon />, name: 'Settings' },
  { icon: <ShareIcon/>, name: 'Share' },
  { icon: <BookmarkIcon/>, name: 'Chapters' },
  { icon: <GearIcon/>, name: 'Settings' }
];

class ReaderSpeedDial extends React.PureComponent {
  state = {
    closeOnDelay: false,
    open: false,
    left: false,
    right: false,
    secondRight: false,
    openModal: false,
    success: false,
  };

  handleModalOpen = () => {
    this.setState({ openModal: true });
  };

  handleModalClose = (sent) => {
    if (sent) {
      this.setState({ success: true });
    }
    this.setState({ openModal: false });
  };

  handleClick = () => {
    this.setState(state => ({
      open: !state.open,
    }));
  };

  handleCloseSnackBar = () => {
    this.setState({ success: false });
  };

  handleAction = (action) => {
    this.handleClose();
    switch (action) {
    case 'Chapters':
      this.toggleDrawer();
      break;
    case 'Share':
      this.handleModalOpen();
      break;
    case 'Settings':
      this.toggleSecondRight();
      break;
    case 'Book Details':
      this.toggleRightDrawer();
      break;
    default:
      break;
    }
  };

  handleOpen = () => {
    this.setState({
      closeOnDelay: false,
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleDelayedClose = () => {
    this.setState({ closeOnDelay: true });
    setTimeout(() => {
      if (this.state.closeOnDelay) {
        this.setState({ closeOnDelay: false }, () => this.handleClose());
      }
    }, 800);
  };

  toggleDrawer = () => {
    this.setState({
      left: !this.state.left,
    });
  };

  toggleRightDrawer = () => {
    this.setState({
      right: !this.state.right,
    });
  };

  toggleSecondRight = () => {
    this.setState({
      secondRight: !this.state.secondRight,
    });
  };

  render() {
    const { classes, chapters, bookId, readerFontSize, bookDetails, loadingDetails } = this.props;
    const { open, success } = this.state;
    const msgTitle = 'Hey There!';
    const msgSubject = 'Bookcicle Book Recommendation.';
    const username = this.props.auth.userDetails.displayName;

    let message = '';
    if (bookDetails) {
      message = `<p>${ username } thought you might be interested in <i>${ bookDetails.title }</i> by ${ bookDetails.author } available on Bookcicle, `+
        'a new digital book publisher built to create unique, engrossing, reading experiences to take advantage of all your devices capabilities.</p>' +
        '<p><a href="https://bookcicle.com">Learn more at Bookcicle</a></p>';
    }

    return (
      <div>
        {!loadingDetails && bookDetails && <div>
          <ChapterDrawer
            toggleDrawer={ this.toggleDrawer }
            left={ this.state.left }
            currentChapter={ this.props.currentChapter }
            handleChangePage={ this.props.handleChangePage }
            chapters={ chapters }
          />
          <DetailDrawer
            toggleDrawer={ this.toggleRightDrawer }
            bookId={ bookId }
            right={ this.state.right }
            currentChapter={ this.props.currentChapter }
            bookDetails={ bookDetails }
            loading={ this.props.loadingDetails }
          />
          <ConfigurationDrawer
            toggleDrawer={ this.toggleSecondRight }
            right={ this.state.secondRight }
            currentFontSize={ readerFontSize }
            currentMsr={ this.props.msr }
            handleChangeFontSize={ this.props.handleChangeFontSize }
            handleChangeMsr={ this.props.handleChangeMsr }
          />
          <div className={ classes.root }>
            <SpeedDial
              ButtonProps={ {} }
              ariaLabel="SpeedDial"
              className={ classes.speedDial }
              icon={ <SpeedDialIcon style={ { lineHeight: 0 } }/> }
              onBlur={ isMobile ? this.handleDelayedClose : this.handleClose }
              onClick={ this.handleClick }
              onClose={ this.handleClose }
              onFocus={ this.handleOpen }
              onMouseEnter={ this.handleOpen }
              onMouseLeave={ this.handleDelayedClose }
              open={ open }
            >
              {actions.map((action) => {
                let setStyle;
                switch (action.name.toLowerCase()) {
                case 'chapters':
                  setStyle = classes.chapters;
                  break;
                case 'share':
                  setStyle = classes.share;
                  break;
                case 'settings':
                  setStyle = classes.settings;
                  break;
                case 'book details':
                  setStyle = classes.details;
                  break;
                case 'font size':
                  setStyle = classes.font;
                  break;
                default:
                  setStyle = classes.default;
                  break;
                }

                if (isMobile) {
                  return <IconButton
                    style={ { margin: 5, display: open ? 'inline' : 'none' } }
                    onClick={ ()=> {this.handleAction(action.name);} }
                    className={ setStyle }
                    key={ action.name }
                  >
                    {action.icon}
                  </IconButton>;
                }
                else {
                  return <SpeedDialAction
                    classes={ { button: setStyle } }
                    key={ action.name }
                    tooltipTitle={ action.name }
                    icon={ action.icon }
                    onClick={ () => {
                      this.handleAction(action.name);
                    } }
                  />;
                }
              })}
            </SpeedDial>
            <ShareModal
              modalTitle={ <span>Recommend <i>{bookDetails.title}</i> to friends!</span> }
              open={ this.state.openModal }
              handleClose={ this.handleModalClose }
              data={ {
                'message': message,
                'subject': msgSubject,
                'title': msgTitle
              } }/>
            {success && <Snackbar
              anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'left',
              } }
              open={ success }
              autoHideDuration={ 6000 }
              onClose={ this.handleCloseSnackBar }
            >
              <SnackbarAlerts
                onClose={ this.handleCloseSnackBar }
                variant="success"
                message="Awesome! We sent along the recommendations."
              />
            </Snackbar>}
          </div>
        </div>}
      </div>
    );
  }
}

ReaderSpeedDial.propTypes = {
  classes: PropTypes.object.isRequired,
  currentChapter: PropTypes.number.isRequired,
  msr: PropTypes.string.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeFontSize: PropTypes.func.isRequired,
  handleChangeMsr: PropTypes.func.isRequired,
  chapters: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
)(withStyles(styles)(ReaderSpeedDial));