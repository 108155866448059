export const EXAMPLE_HTML = `<div>Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div>&emsp;&emsp;"Fall est optimus vicis ut vivere in Nova Anglia, ius?" Et dixit vir sedens in tenebris post me, non est responsum. Odi cum hoc centrum virium convergunt me sedebat in silentio, quid adumbratam Dudus. Clamant, et ego in eo. 'Cur ludens mutam Jack?"</div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"divider","type":"star","variant":"middle","margin":10,"padding":10,"size":"small"}&lt;/bc&gt;</div>
<div>&emsp;&emsp;"Manere focused Just," vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;"Ita, quod hodie propter officium facis? Duras ianitor te? "Ego quaeritur eo. Ut de hoc guy scit, et nos sumus in hoc aliquid unum, sed quolibet tempore peto illi responsum quaestiones I impetro idem. </div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div>&emsp;&emsp;"Fall est optimus vicis ut vivere in Nova Anglia, ius?" Et dixit vir sedens in tenebris post me, non est responsum. Odi cum hoc centrum virium convergunt me sedebat in silentio, quid adumbratam Dudus. Clamant, et ego in eo. 'Cur ludens mutam Jack?"</div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"image", "src": "https://images.bookcicle.com/images/aaron-burden-300808-unsplash.jpg"}&lt;/bc&gt;</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div>&emsp;&emsp;"Fall est optimus vicis ut vivere in Nova Anglia, ius?" Et dixit vir sedens in tenebris post me, non est responsum. Odi cum hoc centrum virium convergunt me sedebat in silentio, quid adumbratam Dudus. Clamant, et ego in eo. 'Cur ludens mutam Jack?"</div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"messenger","displayName":"Andy","messages":[{"isOwner":false,"from":"Andy","message":"Hi, Does this look like a real messenger?","timestamp":"Today@10:42pm"},{"isOwner":true,"from":"Tricia","message":"❤❤ Sure does!","timestamp":"Just now"}]}&lt;/bc&gt;</div>
<div>&emsp;&emsp;"Manere focused Just," vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;"Ita, quod hodie propter officium facis? Duras ianitor te? "Ego quaeritur eo. Ut de hoc guy scit, et nos sumus in hoc aliquid unum, sed quolibet tempore peto illi responsum quaestiones I impetro idem. </div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"gif","src": "https://giphy.com/embed/SjqU4gjf6FYpa"}&lt;/bc&gt;</div>
<div>&emsp;&emsp;"Manere focused Just," vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;"Ita, quod hodie propter officium facis? Duras ianitor te? "Ego quaeritur eo. Ut de hoc guy scit, et nos sumus in hoc aliquid unum, sed quolibet tempore peto illi responsum quaestiones I impetro idem. </div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"note","variant":"yellow","size":"default","message":"This is a handwritten note, try me out!"}&lt;/bc&gt;</div>
<div>&emsp;&emsp;"Manere focused Just," vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;"Ita, quod hodie propter officium facis? Duras ianitor te? "Ego quaeritur eo. Ut de hoc guy scit, et nos sumus in hoc aliquid unum, sed quolibet tempore peto illi responsum quaestiones I impetro idem. </div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"report", "title": "Initial Report", "subtitle": "Training Initiative, 3/27/2019","alignHeader": "left", "widgets":[{"widgetType" :"text" , "size" : "h5", "color": "#1769aa","fontFamily": "Default","isBold": false, "isItalic":false, "align":"left", "justify":"flex-start", "text":"1. Training is best with examples and visual tools."}, {"widgetType" :"text", "size":"h6", "color":"#1769aa", "fontFamily":"Default", "isBold":false, "isItalic":false, "align":"left", "justify":"flex-start", "text":"2. Examples showing usage is also good."}, {"widgetType" :"divider", "type":"star", "variant":"middle", "margin":20, "padding":5}, {"widgetType" :"text", "size":"h6", "color":"#1769ac", "fontFamily":"Default", "isBold":false, "isItalic":true, "align":"left", "justify":"flex-end", "text":"Lets change things ups."}], "footerTitle":"Bookcicle,  LLC", "footerSubtitle":"Documents Department"}&lt;/bc&gt;</div>
<div>&emsp;&emsp;"Manere focused Just, " vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;"Ita, quod hodie propter officium facis? Duras ianitor te? "Ego quaeritur eo. Ut de hoc guy scit, et nos sumus in hoc aliquid unum, sed quolibet tempore peto illi responsum quaestiones I impetro idem. </div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" : "sound", "src":  "https://images.bookcicle.com/sounds/SS00615++++Italian+Church+Bells+mid+distance.mp3", "autoPlay": true, "controls": true, "loop":false}&lt;/bc&gt;</div>
<div>&emsp;&emsp;"Manere focused Just," vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;"Ita, quod hodie propter officium facis? Duras ianitor te? "Ego quaeritur eo. Ut de hoc guy scit, et nos sumus in hoc aliquid unum, sed quolibet tempore peto illi responsum quaestiones I impetro idem. </div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"text","size":"h6","color":"#03a9f4","fontFamily":"Default","isBold":false,"isItalic":false,"align":"left","justify":"flex-start","text":"Hey, I am a block of text that has emphasis and style!! You could put many of me in a row on a Paper widget or directly in your manuscripts. You can manipulate me more than you would your regular works."}&lt;/bc&gt;</div>
<div>&emsp;&emsp;"Manere focused Just," vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;"Ita, quod hodie propter officium facis? Duras ianitor te? "Ego quaeritur eo. Ut de hoc guy scit, et nos sumus in hoc aliquid unum, sed quolibet tempore peto illi responsum quaestiones I impetro idem. </div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div style="color: lightblue">&lt;bc&gt;{"widgetType" :"video","src":  "https://player.vimeo.com/video/303485323?title=0&amp;byline=0&amp;portrait=0"}&lt;/bc&gt;</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>
<div>&emsp;&emsp;"Manere focused Just," vir respondit autem vox aspero. Horrida, rauco smoker me commonuit, purgando semper faucibus. Etsi nunquam cigarettes et quasi odoratus; Odoratus quasi semper oeconomiae tergens chemicals mundi thermarum huiusmodi uteris.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. Erat arida frigus noctis. Aere sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi.</div>
<div>&emsp;&emsp;Erat arida frigus noctis. A <span style="color: lightblue">&lt;bc&gt;{"widgetType" :"conversion","type": "measurement", "imperial": "six feet","metric":"one hundred eighty three centimeters"}&lt;/bc&gt;</span> tall man - sentitur rigidas mundum intravit secundum laterum. A typicam Octobris Nova Anglia in nocte cogitavi. </div>`;