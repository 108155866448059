import BookcicleApi from '../bookcicleApi';
import { checkType, getBookContentAsHTML } from '../utils';
import { getLocalStorage, setLocalStorage } from '../middleware/bookcicleStorage';

const FETCH_BOOK_CONTENT = 'bookContent/FETCH_BOOK_CONTENT';
const FETCH_BOOK_CONTENT_FAILURE = 'bookContent/FETCH_BOOK_CONTENT_FAILURE';
const FETCH_BOOK_CONTENT_SUCCESS = 'bookContent/FETCH_BOOK_CONTENT_SUCCESS';

const BOOK_CHAPTERS_LIST = 'bookContent/BOOK_CHAPTERS_LIST';
const BOOK_CHAPTERS_LIST_SUCCESS = 'bookContent/BOOK_CHAPTERS_LIST_SUCCESS';
const BOOK_CHAPTERS_LIST_FAILURE = 'bookContent/BOOK_CHAPTERS_LIST_FAILURE';

const INITIAL_STATE = {
  loadingBookContent: false,
  bookContentError: null,
  bookContent: null,
  bookContentHtml: null,

  bookHeader: null,
  bookSubheader: null,
  currentChapter: 1,
  contentBookId: null,

  loadingBookChapterList: false,
  bookChapterList:  {
    totalChapters: 0,
    chapters: null
  },
  bookChapterListError: null,

};

export default function bookContent(state = INITIAL_STATE, action) {
  switch(action.type) {
  case BOOK_CHAPTERS_LIST:
    return { ...state, loadingBookChapterList: true, bookChapterListError: null };
  case BOOK_CHAPTERS_LIST_FAILURE:
    return { ...state, loadingBookChapterList: false, bookChapterListError: action.error };
  case BOOK_CHAPTERS_LIST_SUCCESS:
    return { ...state, loadingBookChapterList: false, bookChapterList: action.result, bookChapterListError: null };

  case FETCH_BOOK_CONTENT:
    return { ...state, loadingBookContent: true, bookContent: null, contentBookId: action.bookId };
  case FETCH_BOOK_CONTENT_FAILURE:
    return { ...state, loadingBookContent: false, bookContentError: action.error, currentChapter: action.page };
  case FETCH_BOOK_CONTENT_SUCCESS:{
    window.setTimeout(()=> {
      window.scrollTo(0, action.offset);
    }, 300);

    const htmlContent = getBookContentAsHTML(action.result.chapter.paragraphs);
    return {
      ...state,
      loadingBookContent: false,
      bookContent: action.result,
      bookContentHtml: htmlContent.html,
      bookHeader: htmlContent.header,
      bookSubheader: htmlContent.subHeader,
      bookContentError: null, currentChapter:
      action.page };
  }
  default:
    return { ...state };
  }
}

export const getChaptersList = (auth, bookId, totalChapters, cb) => (dispatch) => {
  dispatch({ type: BOOK_CHAPTERS_LIST });
  const localChapters = getLocalStorage(`editor_${ bookId }_${ auth.userId }_chapters`);
  if (totalChapters > 0 && localChapters) {
    const parsedChapters = JSON.parse(localChapters);
    dispatch({
      type: BOOK_CHAPTERS_LIST_SUCCESS,
      result: { totalChapters: parsedChapters.totalChapters, chapters: parsedChapters.chapters }
    });
    cb(parsedChapters.chapters);
  }
  else {
    new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).get({
      path: `user/${ auth.userId }/purchase/book/${ bookId }`,
      data: {
        action: 'chapters'
      }
    }, (error, result) => {
      if(error){
        if(error){
          if(result) {
            return dispatch({ type: BOOK_CHAPTERS_LIST_FAILURE, error: result.body.message });
          }
          if(checkType(error) === 'String' || checkType(error) === 'na') {
            return dispatch({ type: BOOK_CHAPTERS_LIST_FAILURE, error: `${ error }` });
          }
          return dispatch({ type: BOOK_CHAPTERS_LIST_FAILURE, error: error });
        }
      }
      setLocalStorage(`editor_${ bookId }_${ auth.userId }_chapters`, result);
      dispatch({
        type: BOOK_CHAPTERS_LIST_SUCCESS,
        result: { chapters: result.chapters, totalChapters: result.totalChapters }
      });
      cb(result.chapters);
    });
  }
};

export const fetchBookContent = (auth, bookId, chapterNumber, offset) => (dispatch) => {
  dispatch({ type: FETCH_BOOK_CONTENT, bookId: bookId });
  new BookcicleApi({ accessToken: auth.bookcicleAuth, apiKey: auth.apiKey }).get(
    {
      path: `user/${ auth.userId }/purchase/book/${ bookId }`,
      data: {
        action: 'chapter',
        chapterNumber: chapterNumber,
        isWeb: true
      },
    }, (error, result) => {
      if(error){
        if(result) {
          return dispatch({ type: FETCH_BOOK_CONTENT_FAILURE, error: result.body.message });
        }
        if(checkType(error) === 'String' || checkType(error) === 'na') {
          return dispatch({ type: FETCH_BOOK_CONTENT_FAILURE, error: `${ error }`, page: chapterNumber });
        }
        return dispatch({ type: FETCH_BOOK_CONTENT_FAILURE, error: error, page: chapterNumber });
      }
      return dispatch({ type: FETCH_BOOK_CONTENT_SUCCESS, result: result, page: chapterNumber, offset });
    }
  );
};