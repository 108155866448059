import React from 'react';
import jwt from 'jsonwebtoken';

const NAME = /^get[A-Z]/;
export const createActionDispatcher = (actionCreators) => (dispatch) =>
  Object.keys(actionCreators).reduce((actionDispatchers, name) => {
    const actionCreator = actionCreators[ name ];
    if (typeof actionCreator === 'function' && !NAME.test(name)) {
      actionDispatchers[ name ] = (...args) => dispatch(actionCreator(...args));
    }
    return actionDispatchers;
  }, { dispatch });

export const sanitizeText = (text) => {
  let result = text;
  if(text.includes('<i>')) {
    const split = text.split('<i>');
    const nextSplit = split[ 1 ].split('</i>');
    result = <span>{split[ 0 ]}<i>{nextSplit[ 0 ]}</i>{nextSplit[ 1 ]}</span>;
  }

  return result;
};

export const matchBcWidgets = (text) => {
  const result = text.match(new RegExp(/<bc>[\s\S]*?<\/bc>/, 'g'));
  return result;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  const re = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*()=+]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  return re.test(String(password));
};

export const isFacebookApp = () => {
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  return (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
};

const stringConstructor = 'test'.constructor;
const arrayConstructor = [].constructor;
const objectConstructor = {}.constructor;

export const checkType = (object) => {
  if (object === null) {
    return 'null';
  }
  else if (object === undefined) {
    return 'undefined';
  }
  else if (object.constructor === stringConstructor) {
    return 'String';
  }
  else if (object.constructor === arrayConstructor) {
    return 'Array';
  }
  else if (object.constructor === objectConstructor) {
    return 'Object';
  }
  else if (typeof object === 'boolean'){
    return 'Bool';
  }
  else {
    return 'na';
  }
};

export function parseToBool(str) {
  if (str && checkType(str) === 'String') {
    return str.toLowerCase() === 'true';
  }
  else if(str && checkType(str) === 'Bool') {
    return str;
  }
  return false;
}

export const getBookContentAsHTML = (paragraphs) => {
  const htmlRes = [];
  let header = '';
  let subHeader = '';
  paragraphs.forEach((x, index) => {
    if (x.isTitle === true && !x.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g)) {
      header = x.content;
    }
    else if (x.isTitle === true && x.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g)){
      const subHeaders = x.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g);
      let title = x.content;
      subHeaders.forEach(y => {title = title.replace(y, '');});
      header = title;
      subHeader = subHeaders[ 0 ].replace(/<\s*h\d[^>]*>/g, '').replace(/<\s*\/\s*h\d>/g, '');
    }
    else {
      if (index === 1) {
        if (x.content !== '<br/>') htmlRes.push(`${ x.content }`);
      }
      else{
        htmlRes.push(`<p><span class="bc-indent">${ x.content }</span></p>`);
      }
    }
  });

  return { header: header, subHeader: subHeader, html: htmlRes.join('') };
};

// eslint-disable-next-line
String.prototype.hashCode = function() {
  let hash = 0, i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr   = this.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return (hash + 2147483647) + 1;
};

// eslint-disable-next-line
Date.prototype.addDays = function(days) {
  this.setDate(this.getDate() + parseInt(days));
  return this;
};

export const createJwt = (email, password, name) => {
  return jwt.sign({ provider: 'bookcicle', name, password, email: email.toLowerCase() }, password);
};

export const decodeJwt = (token) => {
  return jwt.decode(token);
};