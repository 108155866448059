import { combineReducers } from 'redux';
import application from './application';
import book from './book';
import user from './user';
import bookContent from './bookContent';

const appReducer = combineReducers({
  application,
  book,
  user,
  bookContent,
});

const rootReducer = (state, action) => {
  if (action.type === 'application/LOGOUT_SUCCESS' || action.type === 'application/LOGOUT_FAILURE') {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;