import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import TitleBar from '../../../../components/documentation/TitleBar';
import GifWidget from '../../../../components/widgets/media/GIFWidget';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  textField: {
    maxWidth: 400
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  content: {
    height: 250,
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'gif'
  },
  {
    title: 'src',
    desc: '<div>URL for the GIF video. Public  <a href="https://giphy.com">Giphy</a> GIFs are only supported.</div>',
    values: 'Giphy Publicly Accessible Video.'
  }
];

export class GIFDocs extends React.PureComponent {

  state = {
    widget: {
      widgetType: 'gif',
      src: 'https://giphy.com/embed/SjqU4gjf6FYpa'
    }
  };

  onChangeJSON = (json) => {
    this.setState({ widget: JSON.parse(json) });
  };

  handleChange = name => event => {
    const value = event.target.value;
    this.setState({
      widget: { ...this.state.widget, [ name ]: value }
    });
  };

  render() {
    const { classes } = this.props;
    const { widget } = this.state;

    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Gif Widget" icon={ WIDGETS_ICONS[ 1 ] } color={ DOCS[ 1 ].avatarColor }/>
        <DocsDesc header="Gif New-Line Widget" desc="Use Gif's within your works."/>
        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ widget } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <List>
                <ListItem>
                  <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel
                      htmlFor="src"
                    >
                      Source
                    </InputLabel>
                    <Select
                      native
                      className="margin-10"
                      value={ widget.src }
                      onChange={ this.handleChange('src') }
                      input={
                        <OutlinedInput
                          labelWidth={ 50 }
                          name="Source"
                          id="src"
                        />
                      }
                    >
                      <option>https://giphy.com/embed/SjqU4gjf6FYpa</option>
                      <option>https://giphy.com/embed/l3diEJzobDAU7H5dK</option>
                      <option>https://giphy.com/embed/iHu2wbkr4XDbO</option>
                      <option>https://giphy.com/embed/RkSELnt3C7Aoo</option>
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <Typography>Here we are telling a story with our normal manuscript text....</Typography>
              <GifWidget src={ widget.src }/>
            </Paper>
          </WidgetsDemoGrid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
GIFDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GIFDocs);