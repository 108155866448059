import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

class BasicChip extends React.Component{
  render(){
    const { classes, label, avatar } = this.props;
    return (
      <span className={ classes.root }>
        {avatar && <Chip label={ label } className={ classes.chip } avatar={ avatar }/>}
        {!avatar && <Chip label={ label } className={ classes.chip } />}
      </span>
    );
  }
}

BasicChip.propTypes = {
  label: PropTypes.string.isRequired,
  avatar: PropTypes.object,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(BasicChip);