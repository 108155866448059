import React from 'react';
import Scroll from 'react-scroll';
import connect from 'react-redux/src/connect/connect';
import { Link } from 'react-router-dom';

import BookcicleAlert from '../components/BookcicleAlerts';

import { PublishingSectionOne, PublishingSectionTwo } from '../components/marketing/PublishingSection';
import { ReaderSectionOne, ReaderSectionTwo } from '../components/marketing/ReadersSection';
import Connect from '../components/Connect';

import { getAuth, showLoginModal, hideLoginModal } from '../reducers/application';
import { createActionDispatcher } from '../utils';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';

import Eye from '@material-ui/icons/RemoveRedEye';
import Contact from '@material-ui/icons/ContactPhone';
import Business from '@material-ui/icons/BusinessCenter';

import { theme } from '../theme';
import green from '@material-ui/core/colors/green';
import withStyles from '@material-ui/core/styles/withStyles';

const ScrollLink = Scroll.Link;
const Events = Scroll.Events;
const scrollSpy = Scroll.scrollSpy;

const ICONS = [
  <Eye key={ 'eye' }/>,
  <Contact key={ 'contact' }/>,
  <Business key={ 'business' }/>
];

const ICON_COLOR = [
  '#00B0FF',
  '#1DE9B6',
  '#9575CD'
];

const ICON_TITLE = [
  'Our Vision',
  'Connect',
  'About'
];

const CONTENT = [
  <div style={ { padding: 20 } } key={ 'vision' }>
    <Typography style={ { fontSize: 17 } } >
      Is to create a platform that delivers a great experience.
      A platform that simplifies the publishing process, and helps authors create amazing stories.
    </Typography>
  </div>,
  <Connect key="contact" />,
  <div style={ { padding: 20 } } key={ 'business' }>
    <Typography style={ { fontSize: 17 } } >
      Bookcicle was founded in 2018 by an Author, Engineer, and Entrepreneur.
      Welcome to the beginning! Make sure to check back often or join our mailing lists to get notified
      as we bring on more authors and services.
    </Typography>
  </div>
];

const mapStateToProps = (state) => ({
  ...state.application,
  auth: getAuth(state)
});

const mapDispatchToProps = createActionDispatcher({ showLoginModal, hideLoginModal });

const styles = () => ({
  root: {
    ...theme.mixins.gutters(),
  },
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: green[ 500 ],
  },
  paper: {
    minHeight: 300,
    width: 275,
  },
});

const buttonStyle = {
  margin: '5px 0',
  maxWidth: 150
};

class Home extends React.Component {

  componentDidMount() {
    this.setState({ 'a': 'a' });
    Events.scrollEvent.register('begin', function() {
    });
    Events.scrollEvent.register('end', function() {
    });
    scrollSpy.update();
  }

  static componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  render() {

    const { isAuthenticated, classes } = this.props;

    return <div id="top">
      <BookcicleAlert location={ this.props.location } showLoginModal={ this.props.showLoginModal }/>
      <header id="header">
        <div className="content">
          <h1 style={ { margin: 0 } }>Bookcicle</h1>
          <p>Where books belong</p>
          <ul className="actions">
            <li>
              {isAuthenticated && <Link to="/books"><div className="button icon scrolly" style={ buttonStyle } >Books</div></Link>}
              {!isAuthenticated && <div className="button icon scrolly" onClick={ this.props.showLoginModal } style={ buttonStyle } >Books</div>}
            </li>
            <ScrollLink activeClass="active"
              className="scrollto"
              to="authors"
              spy={ true }
              smooth={ true }
              duration={ 500 }>
              <li><div className="button icon scrolly" style={ buttonStyle }>Authors</div></li>
            </ScrollLink>
            <ScrollLink activeClass="active"
              className="scrollto"
              to="readers"
              spy={ true }
              smooth={ true }
              duration={ 500 }>
              <li><div className="button icon scrolly" style={ buttonStyle }>Readers</div></li>
            </ScrollLink>
            <ScrollLink activeClass="active"
              className="scrollto"
              to="aboutus"
              spy={ true }
              smooth={ true }
              duration={ 500 }>
              <li><div className="button icon scrolly" style={ buttonStyle }>About Us</div></li>
            </ScrollLink>
          </ul>
        </div>
        <div className="image phone">
          <div className="inner"><img src="https://images.bookcicle.com/images/screen.jpg" alt="loading..."/></div>
        </div>
      </header>

      <section id="authors" className="wrapper style2 special">
        <header className="major">
          <h2>Authors<br/></h2>
        </header>
        <p> The digital publisher of the future is here.<br/>
          Books, shorts, poems... Share your stories like never before.</p>
        <ul className="actions special" style={ { margin: '20px 0' } }>
          <li>
            {isAuthenticated &&
            <Link to="/publish">
              <Button className="button primary fa-download" style={ { lineHeight: 0 } }>Learn More</Button>
            </Link>}
            {!isAuthenticated &&
            <Button className="button primary fa-download" style={ { lineHeight: 0 } } onClick={ ()=>this.props.showLoginModal() }>Log In to Learn More</Button>
            }
          </li>
        </ul>
      </section>

      <section id="author-two" className="wrapper">
        <div className="inner alt">
          <PublishingSectionOne />
          <PublishingSectionTwo />
        </div>
      </section>

      <section id="readers" className="wrapper style2 special">
        <header className="major">
          <h2>Readers<br/></h2>
        </header>
        Read stories on any device. <br/> A modern experience that engrosses you into the story.
        <ul className="actions special" style={ { margin: '20px 0' } }>
          <li>
            {isAuthenticated &&
            <Link to="/books">
              <Button className="button primary fa-download" style={ { lineHeight: 0 } }>Learn More</Button>
            </Link>}
            {!isAuthenticated &&
            <Button className="button primary fa-download" style={ { lineHeight: 0 } } onClick={ ()=>this.props.showLoginModal() }>Log In to Learn More</Button>
            }
          </li>
        </ul>
      </section>

      <section id="reader-two" className="wrapper">
        <div className="inner alt">
          <ReaderSectionOne/>
          <ReaderSectionTwo/>
        </div>
      </section>

      <section id="aboutus" className="wrapper style2 special">
        <header className="major">
          <h2>About Us</h2>
        </header>
        <header className="major">
          <Grid container className={ classes.root } spacing={ 16 }>
            <Grid item xs={ 12 }>
              <Grid container className={ classes.demo } justify="center" spacing={ 24 }>
                {[ 0, 1, 2 ].map(index => (
                  <Grid key={ index } item >
                    <Paper className={ classes.paper }>
                      <List style={ { paddingBottom: 0 } }>
                        <ListItem>
                          <Avatar style={ { backgroundColor: ICON_COLOR[ index ] } }>
                            {ICONS[ index ]}
                          </Avatar>
                          <ListItemText primary={ <Typography variant="h6" component="span">{ICON_TITLE[ index ]}</Typography> }/>
                        </ListItem>
                        <Divider/>
                        {CONTENT[ index ]}
                      </List>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </header>
      </section>
    </div>;
  }
}
export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home));
