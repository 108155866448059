import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import DividerWidget from '../../../components/widgets/paperWidgets/DividerWidget';
import TextWidget from '../../../components/widgets/paperWidgets/TextWidget';

const styles = () => ({
  paper: {
    width: '95%',
    maxWidth: 700,
    margin: 20,
    padding: 20,
  },
});

class PaperWidget extends React.Component {

  handleWidgets = (widget, index) => {
    switch (widget.widgetType) {
    case 'text':
      return <TextWidget
        text={ widget.text }
        isItalic={ widget.isItalic }
        isBold={ widget.isBold }
        color={ widget.color }
        fontFamily={ widget.fontFamily }
        size={ widget.size }
        align={ widget.align }
        justify={ widget.justify }
        key={ index }/>;
    case 'divider':
      return <DividerWidget variant={ widget.variant } type={ widget.type } padding={ widget.padding } size={ widget.size } margin={ widget.margin } key={ index } />;
    default:
      return <i></i>;
    }
  };

  render() {
    const { classes, title, subtitle, alignHeader, widgets, footerTitle, footerSubtitle } = this.props;
    return <div style={ { width: '95%', margin: '0 auto' } }><Grid>
      <Grid item xs={ 12 } >
        <Grid container justify="center" alignItems="center">
          <Paper className={ classes.paper } elevation={ 5 }>
            <Typography variant="h5" style={ { textAlign: alignHeader } }>{title}</Typography>
            <Typography variant="subtitle1" style={ { textAlign: alignHeader } } >{subtitle}</Typography>
            <Divider variant="fullWidth" />
            <div style={ { margin: '20px 0' } }>
              {widgets.map((widget, index) => {
                return this.handleWidgets(widget, index);
              })}
            </div>
            <Divider variant="fullWidth" />
            <div style={ { margin: 10 } }>
              <Typography variant="caption" style={ { textAlign: alignHeader } }>{footerTitle}</Typography>
              <Typography variant="caption" style={ { textAlign: alignHeader } } >{footerSubtitle}</Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
    </div>;
  }
}
PaperWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  alignHeader: PropTypes.string.isRequired,
  widgets: PropTypes.array.isRequired,
  footerTitle: PropTypes.string.isRequired,
  footerSubtitle: PropTypes.string.isRequired
};
export default (withStyles(styles))(PaperWidget);