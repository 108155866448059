import React from 'react';
import EmoticonHappyOutline from 'mdi-material-ui/EmoticonHappyOutline';
import FileReplaceOutline from 'mdi-material-ui/FileReplaceOutline';
import FileWordBox from 'mdi-material-ui/FileWordBox';

import InformationHelper from './InformationHelper';
import TitleBar from '../../../../components/documentation/TitleBar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import withStyles from '@material-ui/core/es/styles/withStyles';
import ExpPanel from '../../../../components/ExpPanel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import DocsGridWrapper from '../../../../components/documentation/DocsGridWrapper';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  paper: {
    maxWidth: 380,
    minHeight: 600,
  },
  details: {
    maxWidth: 380,
  },
  detailsContent: {
    maxHeight: 500,
    overflow: 'auto'
  },
  bottomAlign: {
    alignSelf: 'flex-end',
  },
  buy: {
    minWidth: 500,
    maxWidth: 1000,
    padding: 30,
  },
  grid: {
    minWidth: 350,
    margin: '40px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  tableContainer: {
    maxHeight: 250,
    maxWidth: 380,
    overflow: 'scroll'
  },
  button: {
    margin: 20
  }
});

class PurchaseBookAnalytics extends React.PureComponent{

  render() {
    const { classes, color, service } = this.props;
    return (<DocsWrapper>
      <DocsGridWrapper
        header="Improve your story before publishing with Bookcicle Book Analytics."
        body={ <Typography>Analytics breaks down your story chapter by chapter, and paragraph by paragraph. Giving you the most
          granular insight to help you make decisions. Improve the reader experience by identifying and minimizing
          problematic words and more.</Typography> }
        caption="Included in Bookcicle Publishing Premium ~39/year - Unlimited Manuscripts"
      />

      <DocsGridWrapper
        customStyle="info"
        header=""
        body={ <List>
          <ListItem>
            <ListItemIcon>
              <InfoOutlined/>
            </ListItemIcon>
            <ListItemText secondary="The frequency graphs include phrases or words found within character dialog.
                  In general, these word rules can be ignored when finding in such a context. There is no greater freedom in writing than character dialog."/>
          </ListItem>
        </List> }
      />

      <Grid container spacing={ 8 } justify="center" alignItems="center" id="ner">
        <Grid item xs = { 3 } className={ classes.grid } >
          <Paper className={ classes.paper }>
            <TitleBar color={ color } icon={ <FileReplaceOutline/> } title="Entities"/>
            <div className={ classes.tableContainer } >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Entity</TableCell>
                    <TableCell align="right">Entity Type</TableCell>
                    <TableCell align="right">Paragraphs</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Jack
                    </TableCell>
                    <TableCell align="right">Person</TableCell>
                    <TableCell align="right" style={ { maxWidth: 100 } }>2, 3, 4</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Boston
                    </TableCell>
                    <TableCell align="right">Location</TableCell>
                    <TableCell align="right" style={ { maxWidth: 100 } }>22</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      2014
                    </TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right" style={ { maxWidth: 100 } }>1</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      100,000
                    </TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right" style={ { maxWidth: 100 } }>1</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <InformationHelper
              primary=""
              service="ner"
              secondary="A table of discovered entities. Entities are people, places, organizations, dates, and more. Entities are useful to highlight some of the important things in a chapter and can be helpful to identify mistakes." />
          </Paper>
          <div className={ classes.details } >
            <ExpPanel header="" secondaryHeading="Entity Types" content={ <List className={ classes.detailsContent }>
              <ListItem>
                <ListItemText primary="Other" secondary="Entities discovered that do not fit into any other category."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Commercial Item" secondary="A branded product."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Date" secondary="A full date, day, month, or year."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Event" secondary="An event, such as a festival, election or concert."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Location" secondary="A specific location, such as a country, city, building, etc."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Organization" secondary="Large organization such as government, company, religion, etc."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Person" secondary="Individuals, groups of people, nicknames, etc."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Quantity" secondary="A quantified amount, such as currency, percentages, numbers, bytes, etc."/>
              </ListItem>
              <ListItem>
                <ListItemText primary="Title" secondary="An official name given to any creation or creative work."/>
              </ListItem>
            </List> } expanded={ service === '#ner' } />
          </div>
        </Grid>
        <Grid item xs = { 3 } className={ classes.grid }  id="ngram" >
          <Paper className={ classes.paper }>
            <TitleBar color={ color } icon={ <FileWordBox/> } title="Phrases"/>
            <img src="https://images.bookcicle.com/images/ngram.png"  alt="words" width={ 200 } />
            <InformationHelper
              primary=""
              service="ngram"
              secondary={ <span>Phrases shows us the top 50 used <i>two-word</i> phrases by chapter and their frequency. We explode out slices highlighting potentially problematic phrases as found on our phrase list below, consider minimizing these from your works.</span> } />
          </Paper>
          <div className={ classes.details } >
            <ExpPanel header="" secondaryHeading="Phrase List" content={ <List className={ classes.detailsContent }>
              <ListItem>
                <ListItemText primary="one of" secondary='Either it is, or it is not. "One of the most important" vs "The most important"' />
              </ListItem>
              <ListItem>
                <ListItemText primary="a lot" secondary="Subjective, and often times can be removed when adding more context." />
              </ListItem>
              <ListItem>
                <ListItemText primary="used to" secondary="Consider replacing with past tense verb." />
              </ListItem>
              <ListItem>
                <ListItemText primary="have got" secondary="Can you have and got something?" />
              </ListItem>
              <ListItem>
                <ListItemText primary="first created" secondary='Can something be created and be first? "He first created ..." vs "He created ..."' />
              </ListItem>
              <ListItem>
                <ListItemText primary="free gift" secondary="If a gift is not free, is it a gift?" />
              </ListItem>
              <ListItem>
                <ListItemText primary="sort of" secondary="Replace with more explicit words, be more descriptive." />
              </ListItem>
              <ListItem>
                <ListItemText primary="kind of" secondary="Replace with more explicit words, be more descriptive." />
              </ListItem>
            </List> } expanded={ service === '#ngram' } />
          </div>
        </Grid>
        <Grid item xs = { 3 } className={ classes.grid } id="bow" >
          <Paper className={ classes.paper }>
            <TitleBar color={ color } icon={ <FileWordBox/> } title="Words"/>
            <img src="https://images.bookcicle.com/images/bow.png"  alt="words" width={ 250 } />
            <InformationHelper
              primary=""
              service="bow"
              secondary="Words shows us the top 50 used words by chapter and their frequency. We explode out slices highlighting potentially problematic words as found on our word list below, consider minimizing these from your work." />
          </Paper>
          <div className={ classes.details } >
            <ExpPanel header="" secondaryHeading="Word List" content={ <List className={ classes.detailsContent }>
              <ListItem>
                <ListItemText primary="and" secondary='"And" is easily overused. Try to minimize its use to prevent run on sentences and paragraphs.' />
              </ListItem>
              <ListItem>
                <ListItemText primary="then" secondary="Weak transition. Be more descriptive where possible." />
              </ListItem>
              <ListItem>
                <ListItemText primary="next" secondary="Weak transition. Be more descriptive where possible." />
              </ListItem>
              <ListItem>
                <ListItemText primary="like" secondary="Replace with more explicit words, be more descriptive." />
              </ListItem>
              <ListItem>
                <ListItemText primary="just" secondary='Usually redundant. "Just listen to the birds." vs "Listen to the birds."' />
              </ListItem>
              <ListItem>
                <ListItemText primary="think" secondary="Weak and can sound defensive." />
              </ListItem>
              <ListItem>
                <ListItemText primary="really" secondary='Usually redundant. "He has a really great idea." vs "He has a great idea."' />
              </ListItem>
              <ListItem>
                <ListItemText primary="so" secondary="Sharpen your writing by removing leading words." />
              </ListItem>
              <ListItem>
                <ListItemText primary="often" secondary="Sharpen your writing by removing leading words." />
              </ListItem>
              <ListItem>
                <ListItemText primary="oftentimes" secondary="Sharpen your writing by removing leading words." />
              </ListItem>
              <ListItem>
                <ListItemText primary="very" secondary='Usually redundant. "He was very strong." vs "He was strong."'  />
              </ListItem>
              <ListItem>
                <ListItemText primary="amazing" secondary="Avoid overstating how great something it." />
              </ListItem>
              <ListItem>
                <ListItemText primary="wonderful" secondary="Avoid overstating how great something it." />
              </ListItem>
              <ListItem>
                <ListItemText primary="look" secondary="Easily overused, be more descriptive." />
              </ListItem>
            </List> } expanded={ service === '#bow' } />
          </div>
        </Grid>
        <Grid item xs = { 3 } className={ classes.grid } id="sentiment" >
          <Paper className={ classes.paper }>
            <TitleBar color={ color } icon={ <EmoticonHappyOutline/> } title="Sentiment"/>
            <img src="https://images.bookcicle.com/images/sentiment.png" alt="words" width={ 200 } style={ { marginTop: 20 } }/>
            <InformationHelper
              primary=""
              service="sentiment"
              secondary={ <span>This shows the sentiment, as it changes from paragraph to paragraph throughout a chapter.
                This can help you see how a reader may feel as they read through your story.
                Allowing you to tune the reading experience and increase the impact of emotional sequences.<br/><br/>
                Additionally, this sentiment graph will show you paragraph density and how the density changes from paragraph to paragraph.</span> } />
          </Paper>
          <div className={ classes.details } >
            <ExpPanel header="" secondaryHeading="Scoring" content={ <List className={ classes.detailsContent }>
              <ListItem>
                <ListItemText primary="Green" secondary="Positive sentiment." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Blue" secondary="Neutral sentiment." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Red" secondary="Negative sentiment." />
              </ListItem>
            </List> } expanded={ service === '#sentiment' } />
          </div>
        </Grid>
      </Grid>
    </DocsWrapper>);
  }
}
export default withStyles(styles)(PurchaseBookAnalytics);