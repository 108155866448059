import React from 'react';
import PropTypes from 'prop-types';
import { SyncLoader, MoonLoader } from 'react-spinners';

export default class Loader extends React.Component {
  render() {
    const loader = this.props.loader;
    return <div className="sweet-loading">
      {loader && loader === 'moon' &&
        <MoonLoader
          color={ '#123abc' }
          loading={ true }
        />
      }{loader && loader === 'sync' &&
      <SyncLoader
        color={ '#123abc' }
        loading={ true }
      />}
      {!loader &&
        <SyncLoader
          color={ '#123abc' }
          loading={ true }
        />
      }
    </div>;
  }

}

Loader.propTypes = {
  loader: PropTypes.string
};
