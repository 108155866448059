import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper/Paper';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List/List';
import FormControl from '@material-ui/core/FormControl/FormControl';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput/OutlinedInput';
import TextField from '@material-ui/core/TextField/TextField';

import { theme } from '../../../../theme';
import { WIDGETS_ICONS } from './metadata';
import BookcicleJson from '../../../../components/widgets/BookcicleJson';
import WidgetsParameters from '../../../../components/widgets/WidgetsParameters';
import TitleBar from '../../../../components/documentation/TitleBar';
import DocsDesc from '../../../../components/documentation/DocsDesc';
import { WidgetsDemoGrid } from '../../../../components/widgets/WidgetsDemoGrids';
import { DOCS } from '../metadata';
import DocsWrapper from '../../../../components/documentation/DocsWrapper';

const styles = () => ({
  paper: {
    height: 400,
    width: 250,
  },
  button: {
    margin: theme.spacing.unit,
    lineHeight: '2em',
    '&:hover': {
      color: '#546E7A'
    }
  },
  textField: {
    maxWidth: 400
  },
  paperContent: {
    margin: 20,
    padding: 20
  },
  content: {
    height: 250,
  },
});

const PARAMS = [
  {
    title: 'widgetType',
    desc: 'The type of widget you are using.',
    values: 'conversion'
  },
  {
    title: 'type',
    desc: 'The type of conversion supported',
    values: 'measurement'
  },
  {
    title: 'imperial',
    desc: 'The text to show the reader if they choose imperial.',
    values: 'max-length 250 characters'
  },
  {
    title: 'metric',
    desc: 'The text to show the reader if they choose metric.',
    values: 'max-length 250 characters'
  },
];

export class ConverterDocs extends React.PureComponent {

  state = {
    msr: 'imperial',
    widget: {
      widgetType: 'conversion',
      type: 'measurement',
      imperial: 'six feet',
      metric: 'one hundred eighty three centimeters'
    }
  };

  onChangeJSON = (json) => {
    this.setState({ widget: JSON.parse(json) });
  };

  handleChange = name => event => {
    const value = event.target.value;
    this.setState({
      widget: { ...this.state.widget, [ name ]: value }
    });
  };

  handleChangeMsr = name => event => {
    this.setState({
      [ name ]: event.target.value
    });
  };

  render() {
    const { classes } = this.props;
    const { widget, msr } = this.state;

    return <DocsWrapper>
      <MuiThemeProvider theme={ theme }>

        <TitleBar title="Converter Widget" icon={ WIDGETS_ICONS[ 9 ] } color={ DOCS[ 1 ].avatarColor }/>

        <DocsDesc header="Converter In-Line Widget" desc="Allow readers to choose the unit of measurement they are comfortable with. While the author can choose what that content will be for either group."/>

        <WidgetsParameters parameters={ PARAMS }/>
        <BookcicleJson code={ widget } onChangeJSON={ this.onChangeJSON } />

        <Grid container>
          <Grid item xs={ 12 }>
            <List>
              <ListItem>
                <ListItemText primary={ <Typography variant="h6">Demo</Typography> }/>
              </ListItem>
              <Divider />
            </List>
          </Grid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <List>
                <ListItem>
                  <TextField
                    id="imerialText"
                    label="Imperial"
                    fullWidth
                    style={ { margin: 8 } }
                    className={ classes.textField }
                    value={ widget.imperial }
                    onChange={ this.handleChange('imperial') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    id="metricText"
                    label="Metric"
                    fullWidth
                    style={ { margin: 8 } }
                    className={ classes.textField }
                    value={ widget.metric }
                    onChange={ this.handleChange('metric') }
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={ {
                      shrink: true,
                    } }
                  />
                </ListItem>
                <ListItem>
                  <FormControl variant="outlined" className={ classes.formControl }>
                    <InputLabel
                      htmlFor="src"
                    >
                      Unit Type
                    </InputLabel>
                    <Select
                      native
                      className="margin-10"
                      value={ msr }
                      onChange={ this.handleChangeMsr('msr') }
                      input={
                        <OutlinedInput
                          labelWidth={ 50 }
                          name="Unit Type"
                          id="msr"
                        />
                      }
                    >
                      <option value="imperial">Imperial</option>
                      <option value="metric">Metric</option>
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
            </Paper>
          </WidgetsDemoGrid>
          <WidgetsDemoGrid>
            <Paper className={ classes.paperContent }>
              <Typography>Jack is a very tall man. Some say he stands {widget[ msr ]} tall.</Typography>
            </Paper>
          </WidgetsDemoGrid>
        </Grid>
      </MuiThemeProvider>
    </DocsWrapper>;
  }
}
ConverterDocs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ConverterDocs);