import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import StripeElementWrapper from './StripeElementWrapper';

export default function StripeCardsSection(){
  return (
    <div>
      <StripeElementWrapper label="Card Number" component={ CardNumberElement }/>
      <StripeElementWrapper label="Expiry (MM / YY)" component={ CardExpiryElement }/>
      <StripeElementWrapper label="CVC" component={ CardCVCElement }/>
    </div>
  );
}