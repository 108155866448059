import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ProgressBar from '../../../components/ProgressBar';
import { theme } from '../../../theme';

import history from '../../history';

const styles = {
  list: {
    maxWidth: 300,
  }
};

class ChapterDrawer extends React.PureComponent {

  componentDidMount() {
    if (!this.props.chapters) {
      history.push({
        pathname: '/books',
      });
    }
  }

  render() {
    const { classes, toggleDrawer, chapters } = this.props;
    const currentChapter = this.props.currentChapter;
    let completed = 0;
    if(chapters && chapters.length > 0){
      completed = (currentChapter / chapters.length) * 100;
    }
    return (
      <div>
        <Drawer open={ this.props.left } onClose={ ()=> {toggleDrawer();} }>
          <div
            tabIndex={ 0 }
            role="button"
            onClick={ toggleDrawer }
          >
            <div className={ classes.list }>
              <List>
                <ListItem>
                  <img src="https://images.bookcicle.com/images/bookcicle-large.png" alt="Bookcicle" width="100%"/>
                </ListItem>
              </List>
              <ProgressBar primaryColor={ true } completed={ completed }/>
              <List>
                {chapters && chapters.map((chapter, index) => {
                  return <ListItem key={ chapter.title.hashCode() } button onClick={ ()=>{this.props.handleChangePage(index + 1);} }>
                    {index + 1 < currentChapter && <ListItemText primary={ <b>{`${ index + 1 }. ${ chapter.title }`}</b> } secondary={ chapter.subtitle }/>}
                    {index + 1 === currentChapter && <ListItemText primary={ <b style={ { color: theme.palette.primary.main } }>{`${ index + 1 }. ${ chapter.title }`}</b> } secondary={ chapter.subtitle }/>}
                    {index + 1 > currentChapter && <ListItemText primary={ `${ index + 1 }. ${ chapter.title }` } secondary={ chapter.subtitle } />}
                  </ListItem>;
                })}
              </List>
            </div>
          </div>
        </Drawer>
      </div>
    );
  }
}

ChapterDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  left: PropTypes.bool.isRequired,
  currentChapter: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  chapters: PropTypes.array.isRequired
};

export default withStyles(styles)(ChapterDrawer);