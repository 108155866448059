import React from 'react';
import { createUltimatePagination, ITEM_TYPES } from 'react-ultimate-pagination';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigationLastPage from '@material-ui/icons/LastPage';
import NavigationChevronLeft from '@material-ui/icons/ChevronLeft';
import NavigationChevronRight from '@material-ui/icons/ChevronRight';

const flatButtonStyle = {
  height: 50,
  width: 50,
  lineHeight: 0,
  fontSize: isMobile ? 15 : 18,
  color: '#90A4AE'
};

const flatButtonStyleActive = {
  height: 50,
  width: 50,
  lineHeight: 0,
  fontSize: isMobile ? 15 : 18,
  color: '#263238'
};

const Page = ({ value, isActive, onClick, isDisabled }) => (
  <IconButton
    style={ isActive ? flatButtonStyleActive : flatButtonStyle }
    onClick={ onClick }
    disabled={ isDisabled }
  >{value.toString()}</IconButton>
);

const Ellipsis = ({ onClick, isDisabled }) => (
  <IconButton
    style={ flatButtonStyle }
    onClick={ onClick }
    disabled={ isDisabled }
  >...</IconButton>
);

const FirstPageLink = ({ onClick, isDisabled }) => (
  <IconButton
    style={ flatButtonStyle }
    onClick={ onClick }
    disabled={ isDisabled }
  ><FirstPageIcon/></IconButton>
);

const PreviousPageLink = ({ onClick, isDisabled }) => (
  <IconButton
    style={ flatButtonStyle }
    onClick={ onClick }
    disabled={ isDisabled }
  ><NavigationChevronLeft/></IconButton>
);

const NextPageLink = ({ onClick, isDisabled }) => (
  <IconButton
    style={ flatButtonStyle }
    onClick={ onClick }
    disabled={ isDisabled }
  ><NavigationChevronRight/></IconButton>
);

const LastPageLink = ({ onClick, isDisabled }) => (
  <IconButton
    style={ flatButtonStyle }
    onClick={ onClick }
    disabled={ isDisabled }
  ><NavigationLastPage/></IconButton>
);

const itemTypeToComponent = {
  [ ITEM_TYPES.PAGE ]: Page,
  [ ITEM_TYPES.ELLIPSIS ]: Ellipsis,
  [ ITEM_TYPES.FIRST_PAGE_LINK ]: FirstPageLink,
  [ ITEM_TYPES.PREVIOUS_PAGE_LINK ]: PreviousPageLink,
  [ ITEM_TYPES.NEXT_PAGE_LINK ]: NextPageLink,
  [ ITEM_TYPES.LAST_PAGE_LINK ]: LastPageLink
};

const UltimatePaginationMaterialUi = createUltimatePagination({ itemTypeToComponent });

export default UltimatePaginationMaterialUi;