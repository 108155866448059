import React from 'react';
import { Router, Link } from 'react-router-dom';
import connect from 'react-redux/src/connect/connect';
import BookcicleRouter from './routes/BookcicleRoutes';
import Login from './routes/Login';
import { createActionDispatcher } from './utils';
import history from './routes/history';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import ScrollToTop  from './components/ScrollToTop';
import { getAuth, showLoginModal, hideLoginModal, logout, clearLogin } from './reducers/application';
import Snackbar from '@material-ui/core/Snackbar';
import { getLocalStorage, setLocalStorage } from './middleware/bookcicleStorage';

import BookcicleAppBar from './components/BookcicleAppBar';

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

const mapStateToProps = (state) => ({
  ...state.application,
  auth: getAuth(state)
});

const mapDispatchToProps = createActionDispatcher({ showLoginModal, hideLoginModal, logout, clearLogin });

class App extends React.PureComponent {
  state = {
    anchorEl: null,
    siteMessageOpen: true,
    siteMessage: 'Bookcicle collects cookies, IP addresses, and other data which allow us to deliver you the best possible experience.',
  };

  // eslint-disable-next-line
  componentDidMount() {
    const hideSiteMessage = getLocalStorage('hideSiteMessage');
    if(hideSiteMessage) {
      this.setState({ siteMessageOpen: false });
    }
    if(window.location.hostname.startsWith('www')) {
      window.location.replace(`https://${ window.location.hostname.replace('www.', '') }`);
    }
  }

  handleCloseSiteMessage = () => {
    this.setState({ siteMessageOpen: false });
  };

  handleCloseSiteManual = () => {
    this.setState({ siteMessageOpen: false }, () => {
      setLocalStorage('hideSiteMessage', true);
    });
  };

  render() {
    const { auth, signingOut, isAuthenticated, showAppBar } = this.props;

    return (
      <div className="App">
        <Router history={ history }>
          <ScrollToTop>
            <div>
              <BookcicleAppBar
                logout={ this.props.logout }
                showAppBar={ showAppBar }
                signingOut={ signingOut }
                isAuthenticated={ isAuthenticated }
                showLoginModal={ this.props.showLoginModal }
              />
              <div className="content">
                <BookcicleRouter
                  isAuthenticated={ isAuthenticated } handleToggleDisplayAppBar={ this.handleToggleDisplayAppBar }/>
              </div>

              <footer id="footer">
                <p style={ { margin: 0 } }><Link to="/">Home</Link> | <Link to="contact">Contact</Link>{isAuthenticated && <span> | <Link to="/books">Books</Link> | <Link to="/account">Account</Link></span>}</p>
                <p className="copyright">&copy; {new Date().getFullYear()} Bookcicle | <Link to="/toa">Terms of
                  Service</Link> | <Link to="/privacy">Privacy Policy</Link></p>
              </footer>
              {this.props.showingLogin &&
              <Login
                auth={ auth }
                error={ this.props.loginError }
                open={ this.props.showingLogin }
                onClose={ this.props.hideLoginModal }
                clearLogin={ this.props.clearLogin }/>
              }
            </div>
          </ScrollToTop>
        </Router>
        <Snackbar
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'right',
          } }
          autoHideDuration={ 13000 }
          onClose={ this.handleCloseSiteMessage }
          open={ this.state.siteMessageOpen }
          ContentProps={ {
            'aria-describedby': 'message-id',
          } }
          message={ <span id="message-id">{this.state.siteMessage}</span> }
          action={ [
            <IconButton
              style={ { height: 50, width: 50 } }
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={ this.handleCloseSiteManual }
            >
              <CloseIcon />
            </IconButton>,
          ] }
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
