import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/src/connect/connect';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ReaderSpeedDial from './SpeedDial';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import BookcicleErrorAlert from '../../../components/BookcicleErrorAlert';
import { isMobile } from 'react-device-detect';

import { getLocalStorage, setLocalStorage } from '../../../middleware/bookcicleStorage';
import Typography from '@material-ui/core/Typography';
import { getAuth } from '../../../reducers/application';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { defaultReader, largeReader, smallReader, xLargeReader } from '../../../theme';

import { handleWidgets } from '../../../components/widgets/HandleWidget';
import { describeBook } from '../../../reducers/book';
import { fetchBookContent, getChaptersList } from '../../../reducers/bookContent';
import Divider from '@material-ui/core/Divider';

const mapStateToProps = (state) => ({
  ...state.application,
  ...state.book,
  ...state.bookContent,
  auth: getAuth(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    handleLoadChapterList: (auth, bookId, totalChapters, cb) => dispatch(getChaptersList(auth, bookId, totalChapters, cb)),
    fetchBook: (auth, bookId) => dispatch(describeBook(auth, bookId, true)),
    fetchContent: (auth, bookId, page, offset) => dispatch(fetchBookContent(auth, bookId, page, offset)),
  };
};

class Reader extends React.PureComponent {

  state = {
    layers: {
      widgets: true,
      ads: true,
      msr: true,
      error: true,
    },
    loading: true,
    error: null,
    chapter: [],
    page: 1,
    title: '',
    offset: 0,
    onEnter: true,
    readerFontSize: 'default',
    msr: 'imperial',
  };

  handleChangeFontSize = (fontSize) => {
    this.setState({
      readerFontSize: fontSize
    },() => {
      setLocalStorage(`${ this.props.auth.userId }_reader_font_size`, fontSize);
    });
  };

  handleChangeMeasurements = (pref) => {
    this.setState({
      msr: pref
    }, () => {
      setLocalStorage(`${ this.props.auth.userId }_msr`, pref);
    });
  };

  handleChangePage = (page) => {
    this.setState({
      page: page,
      loading: true,
    }, () => {
      this.loadChapters();
    });
  };

  loadChapters = () => {
    this.props.handleLoadChapterList(this.props.auth, this.props.match.params.id, this.props.bookChapterList.totalChapters, this.loadChaptersContent);
  };

  loadChaptersContent = (chapters) => {
    this.props.fetchContent(this.props.auth, this.props.match.params.id, chapters[ this.state.page - 1 ].location, this.state.offset);
  };

  componentDidMount() {
    this._mounted = true;
    if (this.props.isLive) {
      if(!this.props.bookDetails) {
        this.props.fetchBook(this.props.auth, this.props.match.params.id);
      }

      const fontSize = getLocalStorage(`${ this.props.auth.userId }_reader_font_size`);
      if (fontSize) {
        if(this._mounted) {
          this.setState({ readerFontSize: fontSize.split('"')[ 1 ] });
        }
      }

      const msr = getLocalStorage(`${ this.props.auth.userId }_msr`);
      if(msr){
        if(this._mounted){
          this.setState({ msr: msr.split('"')[ 1 ] });
        }
      }

      const lastPosition = getLocalStorage(`${ this.props.auth.userId }_${ this.props.match.params.id }_last_position`);
      if (lastPosition && lastPosition !== 'undefined' && this.state.onEnter) {
        const position = JSON.parse(JSON.parse(lastPosition));
        if(this._mounted) {
          this.setState({
            onEnter: false,
            page: position.chapter,
            offset: position.offset,
          }, () => {
            this.handleChangePage(position.chapter);
          });
        }
      }
      else {
        this.loadChapters(0);
      }
      window.addEventListener('scroll', this.handleScroll, true);
    }
    else{
      if(this._mounted) {
        this.setState({
          chapter: this.props.chapter,
          loading: false,
        });
      }
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if(this.props.isLive) {
      window.removeEventListener('scroll', this.handleScroll, true);
    }
  }

  handleScroll = () => {
    const position = {
      chapter: this.state.page,
      offset: window.pageYOffset
    };
    setLocalStorage(`${ this.props.auth.userId }_${ this.props.match.params.id }_last_position`, JSON.stringify(position));
  };

  handleChangeDrawerPage = (page) => {
    this.setState({
      offset: 0,
    }, () => {
      this.handleChangePage(page);
      this.handleScroll();
    });
  };

  handleChangeLayers = (name) => {
    this.setState({
      layers: {
        ...this.state.layers,
        [ name ]: !this.state.layers[ name ]
      }
    });
  };

  render() {
    const { isLive, bookDetails, loadingBookContent, bookContent, bookContentError } = this.props;
    const { loadingBookChapterList, bookChapterList, bookChapterListError } = this.props;

    const totalChapters = bookChapterList.totalChapters;

    const width = window.innerWidth;
    const { page, readerFontSize } = this.state;
    const hidePagingLinks = width < 500 || isMobile || totalChapters < 10;

    let theme;
    switch (readerFontSize) {
    case 'xlarge':
      theme = xLargeReader;
      break;
    case 'large':
      theme = largeReader;
      break;
    case 'small':
      theme = smallReader;
      break;
    default:
      theme = defaultReader;
      break;
    }

    return <div style={ { marginTop: '10px' } }>
      {bookContentError && <BookcicleErrorAlert size="sm" error={ bookContentError } variant="error" />}
      {bookChapterListError && <BookcicleErrorAlert size="sm" error={ bookChapterListError } variant="error" />}
      {(loadingBookContent || loadingBookChapterList) && <Loader/>}
      {(!loadingBookContent || !loadingBookChapterList) && !bookChapterListError && !bookContentError && bookContent && bookDetails && new Date(bookDetails.created * 1000) < Date.now() + 60000 &&
      <div className="reader noselect" style={ { backgroundColor: theme.palette.background.paper } }>
        <MuiThemeProvider theme={ theme }>
          <List>
            {(!bookContent.chapter.paragraphs || bookContent.chapter.paragraphs.length === 0) && <ListItem>Oops, there is no content</ListItem>}
            {bookContent.chapter.paragraphs.length > 0 && bookContent.chapter.paragraphs.map((res, index) => {
              if (res.isTitle === true && !res.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g)) {
                return <div key={ index }><Typography component="h4" variant="h4" gutterBottom>
                  {res.content}
                </Typography><Divider variant="middle" style={ { margin: 15 } }/></div>;
              }
              else if(res.isTitle === true && res.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g)){
                const subHeaders = res.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g);
                let title = res.content;
                subHeaders.forEach(x => {title = title.replace(x, '');});
                return <div key={ index }>
                  <Typography component="h4" variant="h4" gutterBottom>
                    {title}
                  </Typography>
                  {subHeaders.map((x, indx) => {
                    const str = x.replace(/<\s*h\d[^>]*>/g, '').replace(/<\s*\/\s*h\d>/g, '');
                    return <Typography key={ indx } variant="h6">{str}</Typography>;
                  })}
                  <Divider variant="middle" style={ { margin: 15 } }/>
                </div>;

              }
              return <div key={ `widget_${ index }` }>{handleWidgets(res.content, index, this.state.msr, this.state.layers)}</div>;
            })}
          </List>
        </MuiThemeProvider>
        <div style={ { margin: '20px 0' } }>
          {isLive && totalChapters && <Pagination
            currentPage={ page }
            totalPages={ totalChapters }
            hideEllipsis
            hideFirstAndLastPageLinks={ hidePagingLinks }
            boundaryPagesRange={ isMobile ? 0 : 1 }
            onChange={ (number) => this.handleChangeDrawerPage(number) }/>}
        </div>
        {isLive && bookDetails && <ReaderSpeedDial
          { ...this.props }
          layers={ this.state.layers }
          currentChapter={ page }
          chapters={ bookChapterList.chapters  }
          handleChangePage={ this.handleChangeDrawerPage }
          handleChangeFontSize={ this.handleChangeFontSize }
          handleChangeMsr={ this.handleChangeMeasurements }
          handleChangeLayers={ this.handleChangeLayers }
          readerFontSize={ readerFontSize }
          msr={ this.state.msr }
        />}
      </div>
      }
    </div>;
  }
}

Reader.propTypes = {
  isLive: PropTypes.bool.isRequired,
  chapter: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reader);
