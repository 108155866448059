import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Typography from '@material-ui/core/Typography';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import { handleWidgets } from '../../../components/widgets/HandleWidget';
import Divider from '@material-ui/core/Divider';
import { defaultReader, largeReader, smallReader, xLargeReader } from '../../../theme';

export function SimpleReader(props) {

  let theme;
  switch (props.readerFontSize) {
  case 'xlarge':
    theme = xLargeReader;
    break;
  case 'large':
    theme = largeReader;
    break;
  case 'small':
    theme = smallReader;
    break;
  default:
    theme = defaultReader;
    break;
  }

  const { chapter } = props;
  return <MuiThemeProvider theme={ theme }>
    <List>
      {(!chapter.paragraphs || chapter.paragraphs.length === 0) && <ListItem>Oops, there is no content</ListItem>}
      {chapter.paragraphs.length > 0 && chapter.paragraphs.map((res, index) => {
        if (res.isTitle === true && !res.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g)) {
          return <div key={ index }><Typography component="h4" variant="h4" gutterBottom>
            {res.content}
          </Typography><Divider variant="middle" style={ { margin: 15 } }/></div>;
        }
        else if(res.isTitle === true && res.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g)){
          const subHeaders = res.content.match(/<\s*h\d[^>]*>(.*?)<\s*\/\s*h\d>/g);
          let title = res.content;
          subHeaders.forEach(x => {title = title.replace(x, '');});
          return <div key={ index }>
            <Typography component="h4" variant="h4" gutterBottom>
              {title}
            </Typography>
            {subHeaders.map((x, indx) => {
              const str = x.replace(/<\s*h\d[^>]*>/g, '').replace(/<\s*\/\s*h\d>/g, '');
              return <Typography key={ indx } variant="h6">{str}</Typography>;
            })}
            <Divider variant="middle" style={ { margin: 15 } }/>
          </div>;

        }
        return <div key={ `widget_${ index }` }>{handleWidgets(res.content, index, 'na', 'imperial')}</div>;
      })}
    </List>
  </MuiThemeProvider>;
}
SimpleReader.propTypes = {
  chapter: PropTypes.object.isRequired,
  readerFontSize: PropTypes.string.isRequired,
};
export default SimpleReader;